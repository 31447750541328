import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnglishInputDirective } from './english-input.directive';
import { NumberInputDirective } from './number-input.directive';
import { KhmerInputDirective } from './khmer-input.directive';
import { PreventAutocompleteDirective } from './prevent-autocomplete.directive';
import { ErrorMessageDirective } from './error-message.directive';
import { TrimInputDirective } from './trim-input.directive';
import { DayMonthYearFormatDirective } from './day-month-year-format.directive';
import { YearFormatDirective } from './year-format.directive';
import { MonthYearFormatDirective } from './month-year-format.directive';
import { RequiredDirective } from './required.directive';



@NgModule({
  declarations: [
    KhmerInputDirective,
    EnglishInputDirective,
    NumberInputDirective,
    PreventAutocompleteDirective,
    ErrorMessageDirective,
    TrimInputDirective,
    MonthYearFormatDirective,
    DayMonthYearFormatDirective,
    YearFormatDirective,
    RequiredDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    KhmerInputDirective,
    EnglishInputDirective,
    NumberInputDirective,
    PreventAutocompleteDirective,
    ErrorMessageDirective,
    TrimInputDirective,
    MonthYearFormatDirective,
    DayMonthYearFormatDirective,
    YearFormatDirective,
    RequiredDirective
  ]
})
export class DirectivesModule { }

<div *ngIf="!(hideText && phone)" class="menu-left-container">
	<ng-template #menuList let-list>
		<ul [class.hide-text]="hideText" @subItem>
			<ng-container *ngFor="let item of list">
				<li *ngIf="!item.isNoPermission" (click)="!item.children ? hideNavBar() : ''">
					<a matTooltipClass="app-tooltip" 
						[matTooltipDisabled]="!hideText" 
						matTooltipPosition="right" 
						[matTooltip]="item.title" 
						matRippleColor="rgba(255, 255, 255, .2)"
						[routerLinkActive]="item.exact ? '' : 'menu-active'" 
						matRipple (click)="menuClick(item)" 
						[routerLink]="item.routerLink">
						<ng-container *ngIf="item.icon"><mat-icon>{{item.icon}}</mat-icon></ng-container>
						<ng-container *ngIf="item.svgIcon"><mat-icon class="menu-svg-icon" [svgIcon]="item.svgIcon"></mat-icon></ng-container>
						<span class="nav-title">{{item.title}}</span>
						<span *ngIf="item.children" style="float: right">
							<mat-icon [class.collapses]="!!item.expanded" class="app-angle">keyboard_arrow_down</mat-icon>
						</span>
					</a>
					<ng-container *ngIf="item.children && item.expanded">
						<ng-container *ngTemplateOutlet="menuList; context: { $implicit: item.children }">
						</ng-container>
					</ng-container>
				</li>
			</ng-container>
		</ul>
	</ng-template>

	<ng-container *ngTemplateOutlet="menuList; context: { $implicit: displayMenuItems }">
	</ng-container>
</div>
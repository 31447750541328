import { ICommonResponse } from '../models/response/common-response';
import { IHomeType } from '../models/interface/int-home-type';
import { IResponseWithPagination } from '../models/response/response-with-pagination';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequestService } from './request.service';
import { SubmitFailService } from './submit-fail.service';
import { IReferenceHistory } from '../models/interface/int-reference-history';

@Injectable({
  providedIn: 'root'
})
export class HouseTypeService extends SubmitFailService {

  constructor(
    private requestService: RequestService
  ) { super(); }


  getAll(page: number, limit: number, searchQuery: string = '', home_category?: string, is_active?: boolean,): Observable<IResponseWithPagination<IHomeType>> {
    const url = environment.baseUrl + '/back-office/home-types';
    return this.requestService.getJSON<any>(url, Object.assign({page, limit, searchQuery, home_category, is_active})).pipe(
      map((res: IResponseWithPagination<IHomeType>) => {
        let filterData = res.data.data;
        if (is_active != null && is_active != undefined) {
          filterData = filterData.filter(item => item.is_active);
        }

        res.data.data = filterData;
        return res;
      })
    );
  }
  
  getByID(id: string): Observable<ICommonResponse<IHomeType>> {
    const url = environment.baseUrl + '/back-office/home-types/' + id;
    return this.requestService.getJSON<any>(url);
  }

  getHouseTypeHistory(_id: string, page: number, limit: number): Observable<IResponseWithPagination<IReferenceHistory>> {
		const url = environment.baseUrl + '/back-office/home-types/' + _id + '/histories';
		return this.requestService.getJSON<any>(url, Object.assign({ page, limit }));
	}

  create(data: any, files: any[]): Observable<ICommonResponse<IHomeType>> {
    const url = environment.baseUrl + '/back-office/home-types/create';
		const formData = new FormData();

		for (let item in data) {
			if (data[item]) formData.append(item, data[item]);
		}

		if (files.length > 0) {
			for (let file of files) {
				formData.append('files', file);
			}
		}

		return this.requestService.postJSON(url, formData);
  }

  update(data: any, _id: string, files: any[]): Observable<ICommonResponse<IHomeType>> {
    const url = environment.baseUrl + '/back-office/home-types/' + _id + '/update';
    const formData = new FormData();

		for (let item in data) {
			if (data[item] || data[item] == '') formData.append(item, data[item]);
		}

		if (files.length > 0) {
			for (let file of files) {
				formData.append('files', file);
			}
		}
    return this.requestService.putJSON<any>(url, formData);
  }

  updateStatus(data: any, id: string): Observable<any> {
    const url = environment.baseUrl + '/back-office/home-types/' + id + '/active';
    return this.requestService.putJSON(url, data);
  }

  delete(id: any) {
    const url = environment.baseUrl + '/back-office/home-types/' + id + '/delete';
    return this.requestService.delete<any>(url);
  }
}

import { AbstractControl, ValidatorFn, FormGroup, FormControl, ValidationErrors } from '@angular/forms';

export class CustomValidators {
    constructor() { }

    static onlyChar(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (control.value == '') return null;

            let re = new RegExp('^[a-zA-Z ]*$');
            if (re.test(control.value)) {
                return null;
            } else {
                return { onlyChar: true };
            }
        };
    }

    static mustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ notMatch: true });
            } else if (!matchingControl.value) {
                matchingControl.setErrors({ required: true });
            } else {
                matchingControl.setErrors(null);
            }
            return null;
        };
    }

    static paymentPercentage(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const mainControl = formGroup.controls[controlName] as FormControl;
            const matchingControl = formGroup.controls[matchingControlName] as FormControl;

            const mainValue = parseFloat(mainControl.value);
            const matchingValue = parseFloat(matchingControl.value);

            if (!mainValue && !matchingValue) {
                mainControl.setErrors({ required: true });
                matchingControl.setErrors({ required: true });

            } else if (!matchingValue && mainValue) {
                matchingControl.setErrors([{ required: true }]);

            } else if (matchingValue && !mainValue) {
                mainControl.setErrors([{ required: true }]);

            } else {

                if (mainValue > matchingValue) {
                    mainControl.setValue(matchingValue, { emitEvent: false })
                }


            }

            return null;


            // set error on matchingControl if validation fails
            // if (matchingControl.value && (control.value > matchingControl.value)) {
            //     control.setErrors([{ max: true }]);

            // } else if (!matchingControl.value && control.value) {
            //     matchingControl.setErrors([{ required: true }]);

            // } else if (matchingControl.value && !control.value) {
            //     control.setErrors([{ required: true }]);

            // } else {
            //     matchingControl.setErrors(null);
            //     control.setErrors(null);
            // }
            // return null;
        };
    }

    static minMax(min: number, max: number): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
        if (control.value === null || control.value === undefined || control.value === '') {
          return null; // Do not validate if the field is empty
        }

        const value = Number(control.value);

        if (isNaN(value)) {
          return { invalidNumber: { message: 'Value must be a number' } };
        }

        if (value < min || value > max) {
          return { minMax: { requiredMin: min, requiredMax: max } };
        }

        return null; // Validation passed
      };
    }
}

<div class="dialog-container">
	<!-- header -->
	<div mat-dialog-title class="title-dialog" *ngIf="!isChildComp">
		<h3> {{ data.title ? data.title : ('card.ref_file' | translate) }}</h3>
	</div>

	<!-- body -->
	<mat-dialog-content>
		<button mat-raised-button class="m-button primary" (click)="file_control.value = null; file_control.click();" style="margin-bottom: 10px;">
			<mat-icon class="svg-icon" svgIcon="upload_icon"></mat-icon> {{ 'button.upload' | translate }}
		</button>
		<input #file_control type="file" (change)="onChange($event)" [accept]="isChildComp ? validTypes : allowedFileTypes" [multiple]="multiple" hidden>

		<div class="file-row" *ngFor="let file of files_for_submit; let index = index" @fade>
			<ng-container *ngTemplateOutlet="fileObject; 
				    context: { $implicit: { 
							    file: file,
							    index: index,
							    type: file.type,
							    valid: true,
							    name: file.name || file.filename
				    } }">
			</ng-container>
		</div>

		<!-- action box -->
		<div mat-dialog-action class="dialog-action" *ngIf="!isChildComp">
			<div class="dialog-btn-box">
				<!-- <button mat-raised-button mat-dialog-close color="warn">{{'button.cancel' | translate}}</button>
				<button mat-raised-button [mat-dialog-close]="files_for_submit" [disabled]="files_for_submit.length == 0"
					[style.color]="files_for_submit.length == 0 ? '#43425d' : 'white'"
					[style.backgroundColor]="files_for_submit.length == 0 ? '#eaeaeaa1' : 'rgb(0, 117, 6)'">
					{{'button.submit' | translate}}
				</button> -->

				<button mat-raised-button class="m-button-normal" matDialogClose>
					<mat-icon class="svg-icon" svgIcon="close_icon"></mat-icon> {{'button.cancel' | translate}}
				</button>
				<button mat-raised-button class="m-button-primary approved" [ngClass]="{'m-button-disabled': isDisable}" [mat-dialog-close]="files_for_submit"
					[disabled]="isDisable">
					<mat-icon class="svg-icon" [svgIcon]="isDisable ? 'upload_no_color' : 'upload_icon'"></mat-icon>
					{{'button.submit' | translate}}
				</button>
			</div>
		</div>
	</mat-dialog-content>
</div>


<!-- template file -->
<ng-template #fileObject let-data>
	<div class="file-container" [ngClass]="{'file-error': !data.valid}">
		<p class="file-name">{{data.name}}</p>

		<div class="file-action">
			<button mat-icon-button class="preview" matTooltip="{{'button.view' | translate}}" 
				(click)="onViewFiles(data.file); $event.stopPropagation();" *ngIf="data.valid && canViewFile">
				<mat-icon>visibility</mat-icon>
			</button>
			<button mat-icon-button class="remove" matTooltip="{{'button.delete' | translate}}" (click)="onRemoveFile(data.file); $event.stopPropagation();">
				<mat-icon>remove_circle_outline</mat-icon>
			</button>
		</div>
	</div>
</ng-template>
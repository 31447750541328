import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizeSnackbarComponent } from './localize-snackbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [LocalizeSnackbarComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ],
  exports: [LocalizeSnackbarComponent]
})
export class LocalizeSnackbarModule { }

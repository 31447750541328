<!--------------------- LOADING TYPE: PROGRESS BAR ---------------------->
<!----------------------------------------------------------------------->
<mat-dialog-content>
    <div class="bar-content" @fade>
        <img src="../../../../assets/image/illustrate/export_file.svg" alt="export file svg">
        <mat-progress-bar *ngIf="isSuccess === null" mode="indeterminate" @fade></mat-progress-bar>
        <div class="desc-container">
            <p class="desc" @fade>{{'message.total_printed' | translate}}: {{pages}}</p>
            <p class="desc" *ngIf="isSuccess === true" @fade>{{'message.print_success' | translate}}</p>
            <p class="desc error" *ngIf="isSuccess === false" @fade>{{errorMessage}}</p>
        </div>
    </div>
    <div *ngIf="isSuccess === false">
        <mat-form-field appearance="outline">
            <mat-label>{{'table.start_from' | translate}}</mat-label>
            <input matInput [formControl]="startPageControl" />
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="center" >
    <button *ngIf="isSuccess === null" mat-raised-button class="m-button-normal" (click)="onForceClose()">
        <mat-icon class="svg-icon" svgIcon="close_icon"></mat-icon> {{('button.cancel' | translate)}}
    </button>
    <ng-container *ngIf="isSuccess !== null">
        <button mat-raised-button class="m-button-normal" mat-dialog-close>
            <mat-icon class="svg-icon" svgIcon="close_icon"></mat-icon> {{('button.close' | translate)}}
        </button>
        <button *ngIf="isSuccess === false" mat-raised-button class="m-button-normal" (click)="print()">
            <mat-icon>{{isFirstPrint?'print':'cached'}}</mat-icon> {{(isFirstPrint?'button.print':'button.retry') | translate}}
        </button>
    </ng-container>
</mat-dialog-actions>
<!------------------------ LOADING TYPE: SPINNER ------------------------>
<!----------------------------------------------------------------------->
<mat-dialog-content *ngIf="loadingType == 'spinner'">
    <mat-spinner diameter="100" strokeWidth="4" [mode]="(!isInprogress && progress == 0) ? 'indeterminate' : 'determinate'" [value]="progress">
    </mat-spinner>
    <mat-icon class="download-icon">file_download</mat-icon>
    <p class="desc" *ngIf="!isInprogress && progress == 0">{{'message.preparing' | translate}}. . .</p>
    <p class="desc" *ngIf="isInprogress && progress > 0">{{'message.downloading' | translate}}: {{progress}}%</p>
    <p class="desc" *ngIf="progress == 100">{{'message.download_success' | translate}}</p>
</mat-dialog-content>


<!--------------------- LOADING TYPE: PROGRESS BAR ---------------------->
<!----------------------------------------------------------------------->
<mat-dialog-content *ngIf="loadingType == 'bar'">
    <div class="bar-content" @fade>
        <img src="../../../../assets/image/illustrate/export_file.svg" alt="export file svg">
        <!-- [mode]="(!isInprogress && progress == 0) ? 'indeterminate' : 'determinate'" -->
        <!-- [value]="progress" -->
        <mat-progress-bar *ngIf="!isSuccess" mode="indeterminate" @fade></mat-progress-bar>
        <div class="desc-container">
            <p class="desc" *ngIf="!isInprogress && !isSuccess" @fade>{{'message.preparing' | translate}}. . .</p>
            <p class="desc" *ngIf="isInprogress && !isSuccess" @fade>{{'message.downloading' | translate}}: {{loaded}}MB</p>
            <p class="desc" *ngIf="isSuccess" @fade>{{'message.download_success' | translate}}</p>
            <!-- <p class="desc" *ngIf="progress == 100">{{'message.download_success' | translate}}</p> -->
        </div>
    </div>
</mat-dialog-content>



<mat-dialog-actions align="center" *ngIf="isShowAction">
    <button mat-raised-button class="m-button-normal" (click)="onCloseDialog()">
        <mat-icon class="svg-icon" svgIcon="close_icon"></mat-icon> {{('button.close' | translate)}}
    </button>
</mat-dialog-actions>
import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from 'src/app/models/interface/int-user';

@Pipe({
  name: 'userName'
})
export class UserNamePipe implements PipeTransform {

  transform(value: IUser, ...args: unknown[]): string | undefined | null{
    if (!value) return null;

    if (value.profile) return value.profile.last_name + ' ' + value.profile.first_name;
    else return value.last_name + ' ' + value.first_name;
  }

}

<ng-container *ngIf="data.is_loading; else defaultTemplate">
    <div class="loading-snackbar">
        <mat-spinner diameter="25" strokeWidth="3" [mode]="'indeterminate'" *ngIf="data.is_loading">
        </mat-spinner>
        <span class="text">
            {{ (data.msg || data.message) | translate }}
        </span>
    </div>
</ng-container>

<ng-template #defaultTemplate>
    <div class="normal-snackbar">
        {{ (data.msg || data.message) | translate }}
    </div>
</ng-template>
<div class="dialog-container">
	<mat-dialog-content>
		<h3><strong class='title'>{{'message.are_you_sure' | translate}} <mat-icon class="warn">error_outline</mat-icon></strong></h3>
		<p>{{'message.you_will_change' | translate}}</p>

		<p *ngIf="data.name == 'pending'">
			<strong>" {{'root.status.pending' | translate}} "</strong>
		</p>
		<p *ngIf="data.name == 'in_progress'">
			<strong>" {{'root.status.inprogress' | translate}} "</strong>
		</p>
		<p *ngIf="data.name == 'done'">
			<strong>" {{'root.status.done' | translate}} "</strong>
		</p>
		<!-- <p *ngIf="data.name == 'spam' && tabName !== 'check_house'">
			<strong>" {{'root.status.spam' | translate}} "</strong>
		</p> -->

		<!-- action box -->
		<div mat-dialog-action class="main">
			<div class="confirm-dialog-btn-box">
				<button mat-raised-button class="m-button-normal" matDialogClose>
					<mat-icon class="svg-icon" svgIcon="close_icon"></mat-icon> {{'button.cancel' | translate}}
				</button>
				<button mat-raised-button class="m-button-primary" [matDialogClose]="true">
					<mat-icon class="svg-icon" svgIcon="done_icon"></mat-icon>
					{{'button.confirm' | translate}}
				</button>
			</div>
		</div>
	</mat-dialog-content>
</div>
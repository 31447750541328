<div class="carousel" *ngIf="slides.length > 0">
            <ng-container *ngFor="let slide of slides; let i = index">
                        <div class="preview" *ngIf="i === currentSlide">
                                    <mat-icon (click)="onPreview(slide)">crop_free</mat-icon>
                        </div>
                        <img *ngIf="i === currentSlide" [src]="baseUrl + slide" class="slide" @carouselAnimation/>
            </ng-container>

            <!-- controls -->
            <button class="control prev" (click)="onPreviousClick()">
                        <span class="arrow left"></span>
            </button>
            <button class="control next" (click)="onNextClick()">
                        <span class="arrow right"></span>
            </button>
</div>
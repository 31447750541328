import { IImageURL } from '../../../models/interface/int-image-url';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  baseUrl: string = environment.baseUrl;
  imgSrc: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IImageURL,
  ) { }

  ngOnInit(): void {
    this.imgSrc = this.data.image;
  }

}

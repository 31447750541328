import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'monthLocale'
})
export class MonthLocalePipe implements PipeTransform {

  transform(value: number, lang: 'km' | 'en' | string): string {
    return moment().month(value).locale(lang).format('MMMM');
  }

}

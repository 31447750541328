export enum PERMISSION_ENUM {
  STAFF_CREATE = "STAFF_CREATE",
  STAFF_UPDATE = "STAFF_UPDATE",
  STAFF_DELETE = "STAFF_DELETE",
  STAFF_READ = "STAFF_READ",
  USER_CREATE = "USER_CREATE",
  USER_UPDATE = "USER_UPDATE",
  USER_DELETE = "USER_DELETE",
  USER_READ = "USER_READ",
  ROLE_CREATE = "ROLE_CREATE",
  ROLE_UPDATE = "ROLE_UPDATE",
  ROLE_DELETE = "ROLE_DELETE",
  ROLE_READ = "ROLE_READ",
  PERMISSION_CREATE = "PERMISSION_CREATE",
  PERMISSION_UPDATE = "PERMISSION_UPDATE",
  PERMISSION_DELETE = "PERMISSION_DELETE",
  PERMISSION_READ = "PERMISSION_READ",
  POST_SOLID_WASTE_CREATE = "POST_SOLID_WASTE_CREATE",
  POST_SOLID_WASTE_UPDATE = "POST_SOLID_WASTE_UPDATE",
  POST_SOLID_WASTE_DELETE = "POST_SOLID_WASTE_DELETE",
  POST_SOLID_WASTE_READ = "POST_SOLID_WASTE_READ",
  POST_SOLID_WASTE_APPROVE = "POST_SOLID_WASTE_APPROVE",
  WAST_COLLECTION_HISTORY_CREATE = "WAST_COLLECTION_HISTORY_CREATE",
  WAST_COLLECTION_HISTORY_UPDATE = "WAST_COLLECTION_HISTORY_UPDATE",
  WAST_COLLECTION_HISTORY_DELETE = "WAST_COLLECTION_HISTORY_DELETE",
  WAST_COLLECTION_HISTORY_READ = "WAST_COLLECTION_HISTORY_READ",
  CHECKING_HOUSE_CREATE = "CHECKING_HOUSE_CREATE",
  CHECKING_HOUSE_UPDATE = "CHECKING_HOUSE_UPDATE",
  CHECKING_HOUSE_DELETE = "CHECKING_HOUSE_DELETE",
  CHECKING_HOUSE_READ = "CHECKING_HOUSE_READ",
  CHECKING_HOUSE_APPROVE = "CHECKING_HOUSE_APPROVE",
  CHECKING_HOUSE_VERIFYI = "CHECKING_HOUSE_VERIFYI",
  CHECKING_HOUSE_VERIFYII = "CHECKING_HOUSE_VERIFYII",
  CHECKING_HOUSE_CREATE_AUTO_APPROVED = "CHECKING_HOUSE_CREATE_AUTO_APPROVED",
  CHECKING_HOUSE_CREATE_BY_EXCEL = "CHECKING_HOUSE_CREATE_BY_EXCEL",
  RECYCLE_CREATE = "RECYCLE_CREATE",
  RECYCLE_UPDATE = "RECYCLE_UPDATE",
  RECYCLE_DELETE = "RECYCLE_DELETE",
  RECYCLE_READ = "RECYCLE_READ",
  RECYCLE_APPROVE = "RECYCLE_APPROVE",

  // INVOICE
  // ----------------
  INVOICE_SOLID_WASTE_CREATE = "INVOICE_SOLID_WASTE_CREATE",
  INVOICE_SOLID_WASTE_UPDATE = "INVOICE_SOLID_WASTE_UPDATE",
  INVOICE_SOLID_WASTE_DELETE = "INVOICE_SOLID_WASTE_DELETE",
  INVOICE_SOLID_WASTE_READ = "INVOICE_SOLID_WASTE_READ",

  READ_INVOICE_BY_MONTH = "READ_INVOICE_BY_MONTH",
  READ_DEBT_BY_MONTH = "READ_DEBT_BY_MONTH",
  READ_AGING_INVOICE = "READ_AGING_INVOICE",
  READ_PAYMENT_SUMMARY = "READ_PAYMENT_SUMMARY",

  HOME_CREATE = "HOME_CREATE",
  HOME_CREATE_AS_BATCH = "HOME_DELETE",
  HOME_UPDATE = "HOME_UPDATE",
  HOME_DELETE = "HOME_DELETE",
  HOME_READ = "HOME_READ",
  RECEIVE_CASH = "RECEIVE_CASH",
  INVOICE_ADJUST_BY_EXCEL = "INVOICE_ADJUST_BY_EXCEL",
  HOME_VERIFY_BY_EXCEL = "HOME_VERIFY_BY_EXCEL",
  LOCATION_CREATE = "LOCATION_CREATE",
  LOCATION_UPDATE = "LOCATION_UPDATE",
  LOCATION_DELETE = "LOCATION_DELETE",
  LOCATION_READ = "LOCATION_READ",
  STREET_CREATE = "STREET_CREATE",
  STREET_UPDATE = "STREET_UPDATE",
  STREET_DELETE = "STREET_DELETE",
  STREET_READ = "STREET_READ",
  HOME_CATEGORY_CREATE = "HOME_CATEGORY_CREATE",
  HOME_CATEGORY_UPDATE = "HOME_CATEGORY_UPDATE",
  HOME_CATEGORY_DELETE = "HOME_CATEGORY_DELETE",
  HOME_CATEGORY_READ = "HOME_CATEGORY_READ",
  HOME_TYPE_CREATE = "HOME_TYPE_CREATE",
  HOME_TYPE_UPDATE = "HOME_TYPE_UPDATE",
  HOME_TYPE_DELETE = "HOME_TYPE_DELETE",
  HOME_TYPE_READ = "HOME_TYPE_READ",
  SERVICE_TYPE_CREATE = "SERVICE_TYPE_CREATE",
  SERVICE_TYPE_UPDATE = "SERVICE_TYPE_UPDATE",
  SERVICE_TYPE_DELETE = "SERVICE_TYPE_DELETE",
  SERVICE_TYPE_READ = "SERVICE_TYPE_READ",
  REQUEST_CREATE = "REQUEST_CREATE",
  REQUEST_UPDATE = "REQUEST_UPDATE",
  REQUEST_DELETE = "REQUEST_DELETE",
  REQUEST_READ = "REQUEST_READ",
  REQUEST_APPROVE = "REQUEST_APPROVE",
  INFORMATION_CREATE = "INFORMATION_CREATE",
  INFORMATION_UPDATE = "INFORMATION_UPDATE",
  INFORMATION_DELETE = "INFORMATION_DELETE",
  INFORMATION_READ = "INFORMATION_READ",
  VIDEO_INFO_CREATE = "VIDEO_INFO_CREATE",
  VIDEO_INFO_UPDATE = "VIDEO_INFO_UPDATE",
  VIDEO_INFO_DELETE = "VIDEO_INFO_DELETE",
  VIDEO_INFO_READ = "VIDEO_INFO_READ",
  OPERATOR_CREATE = "OPERATOR_CREATE",
  OPERATOR_UPDATE = "OPERATOR_UPDATE",
  OPERATOR_DELETE = "OPERATOR_DELETE",
  OPERATOR_READ = "OPERATOR_READ",
  ITEM_RECYCLE_CREATE = "ITEM_RECYCLE_CREATE",
  ITEM_RECYCLE_UPDATE = "ITEM_RECYCLE_UPDATE",
  ITEM_RECYCLE_DELETE = "ITEM_RECYCLE_DELETE",
  ITEM_RECYCLE_READ = "ITEM_RECYCLE_READ",
  CONTACT_US_CREATE = "CONTACT_US_CREATE",
  CONTACT_US_UPDATE = "CONTACT_US_UPDATE",
  CONTACT_US_DELETE = "CONTACT_US_DELETE",
  CONTACT_US_READ = "CONTACT_US_READ",
  SUMMARY_READ = "SUMMARY_READ",
  // REPORT_READ = "REPORT_READ",

  REPORT_CHECKING_HOUSE_READ = "REPORT_CHECKING_HOUSE_READ",
  REPORT_RECYCLE_READ = "REPORT_RECYCLE_READ",
  REPORT_BANK_PAYMENT_READ = "REPORT_BANK_PAYMENT_READ",
  REPORT_DAILY_SETTLEMENT_READ = "REPORT_DAILY_SETTLEMENT_READ",
  REPORT_NEW_INVOICE_READ = "REPORT_NEW_INVOICE_READ",
  REPORT_ADJUSTMENT_READ = "REPORT_ADJUSTMENT_READ",
  REPORT_REFUND_AND_TRANSFER_READ = "REPORT_REFUND_AND_TRANSFER_READ",
  REPORT_DELETED_INVOICE_READ = "REPORT_DELETED_INVOICE_READ",
  REPORT_DEBT_INVOICE_READ = "REPORT_DEBT_INVOICE_READ",
  REPORT_NEW_HOME_READ = "REPORT_NEW_HOME_READ",

  // Report Payment
  READ_CASHIER_PAYMENT = "READ_CASHIER_PAYMENT",
  READ_CASHIERS_PAYMENT_SUMMARY = "READ_CASHIERS_PAYMENT_SUMMARY",
  CASH_TRANSACTION_READ = "CASH_TRANSACTION_READ",
  CASH_TRANSACTION_CREATE = "CASH_TRANSACTION_CREATE",
  CASH_TRANSACTION_DELETE = "CASH_TRANSACTION_DELETE",


  // Setting
  PAYMENT_READ = "PAYMENT_READ",
  PAYMENT_UPDATE = "PAYMENT_UPDATE",
  PAYMENT_CREATE = "PAYMENT_CREATE",
  PAYMENT_DELETE = "PAYMENT_DELETE",

  WASTE_CREATE = "WASTE_CREATE",
  WASTE_UPDATE = "WASTE_UPDATE",
  WASTE_DELETE = "WASTE_DELETE",
  WASTE_READ = "WASTE_READ",
}

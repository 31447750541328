import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: number, args: string): string {
    if (typeof(value) == 'number' && (value != null || value != undefined)) {
      let minIntDigit = parseInt(args.split('.')[0]); // eg: 1.0-2 : result = 1;
      let minFracDigit: number;
      let maxFracDigit: number;

      // if string contain dash '-'
      if (/[\-]/.test(args.split('.')[1])) {
        minFracDigit = parseInt(args.split('.')[1].split('-')[0]); // eg: 1.0-2 : result = 0;
        maxFracDigit = parseInt(args.split('.')[1].split('-')[1]); // eg: 1.0-2 : result = 2;

      } else {
        minFracDigit = parseInt(args.split('.')[1]); // eg: 1.2 : result = 2;
        maxFracDigit = parseInt(args.split('.')[1]); // eg: 1.2 : result = 2;
      }

      const formatter2 = new Intl.NumberFormat('en-US', {
        minimumIntegerDigits: minIntDigit,
        minimumFractionDigits: (value % 1 == 0) ? minFracDigit : 2,
        maximumFractionDigits: maxFracDigit
      });

      return formatter2.format(value);

    } else return '-';
  }
}

export enum SESSION_ENUM {
            LANG = "lang",
            TOKEN = "token",
            REFRESH_TOKEN = "refresh_token",
            EXPIRED_AT = "expiredAt",
            USERNAME = "username",
            // ROLE = "role",
            ROLE_KH = "ROLE_KH",
            ROLE_EN = "ROLE_EN",
            USERID = "userID",
            PERMISSION = "PERMISSION",
            FILTER = "FILTER",


            KHAN_ID = "KHAN_ID",
            SANGKAT_ID = "SANGKAT_ID",
            LIST_KHANS = "LIST_KHANS",
            LIST_SANGKATS = "LIST_SANGKATS",
            LIST_USER_LOCATION = "LIST_USER_LOCATION",
            KHAN_NAME = "KHAN_NAME",
            SANGKAT_NAME = "SANGKAT_NAME",
            PARENT_ID = "PARENT_ID",
            PARENT_NAME = "PARENT_NAME",
            OPERATOR = "OPERATOR",
            OPERATOR_ID = "operator_id",
            OPERATOR_NAME = "OPERATOR_NAME",

            IS_DISABLE = "IS_DISABLE",  // Disable operaator form when view info

            PREV_ROUTE_STATE = "PREV_ROUTE_STATE", // STORE PREVIOUS ROUTE STATE
            CURR_ROUTE_STATE = "CURR_ROUTE_STATE", // STORE CURRENT ROUTE STATE
}
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-file-viewer-drag-drop',
	templateUrl: './file-viewer-drag-drop.component.html',
	styleUrls: ['./file-viewer-drag-drop.component.scss']
})
export class FileViewerDragDropComponent {

	isImage: boolean;
	url: any;

	constructor(
		private dialogRef: MatDialogRef<FileViewerDragDropComponent>,
		private sanitizer: DomSanitizer,
		@Inject(MAT_DIALOG_DATA) public data?: {
			name: string,
			isImage: boolean;
			url: string;
		}
	) {
		this.isImage = data.isImage ? data.isImage : false;
		this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
	}

}

import { IResponseWithPagination } from '../models/response/response-with-pagination';
import { ICommonResponse } from '../models/response/common-response';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, finalize, from, map, mergeMap, toArray } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { RequestService } from './request.service';
import { ISummaryRequest } from '../models/interface/summary-request';
import { IRequestRegister } from '../models/interface/int-request-register';
import { IReferenceHistory } from '../models/interface/int-reference-history';
import { SubmitFailService } from './submit-fail.service';
import { IFileRef } from '../models/interface/int-file-reference';
import { LoadingService } from './loading.service';
import { FILE_TYPE_ENUM } from '../models/enum/file-type.enum';

@Injectable({
	providedIn: 'root'
})
export class RequestUpdateHouseService extends SubmitFailService {

	constructor(
		private requestService: RequestService,
		private localStorageService: LocalStorageService,
		private loadingService: LoadingService,
		private http: HttpClient
	) { super(); }


	/**
	 *
	 * @param data
	 * @require page
	 * @require limit
	 * @require status
	 * @require type (VERIFY,CREATE,UPDATE,DISABLE)
	 */
	getAll(data: any): Observable<IResponseWithPagination<IRequestRegister>> {
		const url = environment.baseUrl + '/back-office/requests';
		return this.requestService.getJSON(url, data);
	}

	getByID(_id: string): Observable<ICommonResponse<IRequestRegister>> {
		const url = environment.baseUrl + '/back-office/requests/' + _id;
		return this.requestService.getJSON<any>(url, {});
	}

	/**
	 *
	 * @param data
	 * @returns
	 */
	createRequest(data: any, files?: any): Observable<ICommonResponse<IRequestRegister>> {
		const url = environment.baseUrl + '/back-office/requests/create';
		const formData = new FormData();

		for (let item in data) {
			if (data[item]) formData.append(item, data[item]);
		}

		if (files) {
			for (let [index, file] of files.entries()) {
				formData.append(`files[${index}][file]`, file.files);
				formData.append(`files[${index}][type]`, file.type);
			}
		}

		return this.requestService.postJSON(url, formData);
	}

	/**
	 *
	 * reference id can be:
	 *@enum id of post solid waste
	 *@enum id of post recycle
	 *@enum id of pos checking house
	 *@enum id of invoice solid waste
	 *
	 * @returns
	 */
	getRequestHistory(_id: string, page: number, limit: number): Observable<IResponseWithPagination<IReferenceHistory>> {
		const url = environment.baseUrl + '/back-office/reference/' + _id + '/histories';
		return this.requestService.getJSON<IResponseWithPagination<IReferenceHistory>>(url, Object.assign({ page, limit }));
	}

	// uploadFile(_id: string, data: any): Observable<any> {
	// 	const formData = new FormData();
	// 	for (let [index, file] of data.entries()) {
	// 		formData.append(`files[${index}]`, file);
	// 	}
	// 	const url = environment.baseUrl + '/back-office/requests/' + _id + '/files';
	// 	return this.requestService.postJSON<any>(url, formData);
	// }

	setRequestToInProgress(_id: string): Observable<ICommonResponse<IRequestRegister>> {
		const url = environment.baseUrl + '/back-office/requests/' + _id + '/in-progress';
		return this.requestService.putJSON<any>(url, {});
	}

	allocateRequest(data: any, req_id: string): Observable<ICommonResponse<IRequestRegister>> {
		const url = environment.baseUrl + '/back-office/requests/' + req_id + '/allocate';
		return this.requestService.putJSON<any>(url, data);
	}

	assignRequest(data: any, req_id: string, files: any[]): Observable<ICommonResponse<IRequestRegister>> {
		const url = environment.baseUrl + '/back-office/requests/' + req_id + '/assign';
		const formData = new FormData();
		for (let item in data) {
			formData.append(item, data[item]);
		}

		if (files.length > 0) {
			for (let [index, file] of files.entries()) {
				formData.append(`files[${index}]`, file);
			}
		}
		return this.requestService.putJSON<any>(url, formData);
	}

	/**
	 * UPDATE BY SECTION
	 */
	updateRequest(_id: string, data: any): Observable<ICommonResponse<IRequestRegister>> {
		const url = environment.baseUrl + '/back-office/requests/' + _id + '/update-reference';
		const formdata = new FormData();
		for (let item in data) {
			if (data[item]) formdata.append(item, data[item]);
		}

		return this.requestService.patchJSON<any>(url, formdata);
	}

	rejectRequest(_id: string, description: string, files: any[]): Observable<ICommonResponse<IRequestRegister>> {
		const url = environment.baseUrl + '/back-office/requests/' + _id + '/reject';
		const formData = new FormData();
		formData.append('description', description);

		if (files.length > 0) {
			for (let [index, file] of files.entries()) {
				formData.append(`files[${index}]`, file);
			}
		}

		return this.requestService.putJSON<any>(url, formData);
	}

	returnRequest(_id: string, description: string): Observable<ICommonResponse<IRequestRegister>> {
		const url = environment.baseUrl + '/back-office/requests/' + _id + '/return';
		return this.requestService.putJSON<any>(url, { description });
	}

	approveRequest(_id: string, files: any[]): Observable<ICommonResponse<IRequestRegister>> {
		const url = environment.baseUrl + '/back-office/requests/' + _id + '/approve';
		const formData = new FormData();

		if (files.length > 0) {
			for (let [index, file] of files.entries()) {
				formData.append(`files[${index}]`, file);
			}
		}

		return this.requestService.putJSON<any>(url, formData);
	}

	deleteFileOfRequest(_id: string, file_id: string): Observable<any> {
		const url = environment.baseUrl + '/back-office/requests/' + _id + '/files/' + file_id;
		return this.requestService.putJSON<any>(url, {});
	}

	getFileMetaData(file_url: string): Observable<any> {
		const url = environment.baseUrl + `${file_url}` + '/metadata';
		return this.requestService.getJSON<any>(url, {});
	}

	getMultipleFileMetaData(files: any[]): Observable<any> {
		return from(files).pipe(
			mergeMap(file => {
				const file_type = file.type;
				const file_path = file.path || file;

				const url = environment.baseUrl + `${file_path}` + '/metadata';
				return this.requestService.getJSON<any>(url, {}).pipe(
					map(response => {
						const newFile = { data: {...response.data, path: file_path, type: file_type}};
						const fileExt = newFile.data.filename.split('.')[1];
						const typeOctetStream = newFile.data.contentType.includes('octet-stream');

						if (typeOctetStream && (!fileExt || fileExt == 'pdf')) {
							newFile.data.contentType = "application/pdf"; // reset to pdf
						};

						if (typeOctetStream && (['png', 'jpg', 'jpeg'].includes(fileExt))) {
							newFile.data.contentType = `image/${fileExt}`; // reset to image extension
						}

						return newFile;
					})
				);
			})
		)
	}

	getDocument(file_url: string) {
		const path = `${environment.baseUrl}${file_url}`;
		let request = this.http.get(path, { headers: this.getHeader(), responseType: 'arraybuffer' });
		request = request.pipe(finalize(() => {
			this.loadingService.setLoading(false);
		}))

		return request;
	}

	getImageDoc(file_url: string) {
		const path = `${environment.baseUrl}${file_url}`;
		let request = this.http.get(path, { headers: this.getHeader(), responseType: 'blob' });
		request = request.pipe(finalize(() => {
			this.loadingService.setLoading(false);
		}))

		return request;
	}

	public getHeader() {
		const token = this.localStorageService.get('token');
		return new HttpHeaders({
			'Content-Type': 'application/json',
			'authorization': `Bearer ${token} `
		});
	}


	// get summary

	getSummaryRequest(data: any): Observable<ICommonResponse<ISummaryRequest[]>> {
		const url = environment.baseUrl + '/back-office/summary/request';
		return this.requestService.getJSON<any>(url, data);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateObject'
})
export class TranslateObjectPipe implements PipeTransform {

  transform(value: any, ...args: any[]): string {
    let lang = args[0];
    let spacing = args[1] != undefined ? args[1] : true;

    // Define default spacing
    const space = spacing ? ' ' : '';

    if ((value?.name_en || value?.name_kh) && (value?.type_en || value?.type_kh)) {
      if (lang == 'en') return value?.type_en + space + value?.name_en;
      else return value?.type_kh + space + value?.name_kh;

    } else if ((value?.name_en || value?.name_kh) && !(value?.type_en || value?.type_kh)) {
      if (lang = 'en') return value?.name_en;
      else return value?.name_kh;

    } else return '-';
  }

}

import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[englishInput]'
})
export class EnglishInputDirective {
	@Input() isLastName: boolean = false;
	@Input() is_company_name: boolean = false;
	@Input() capitalize: boolean = true;
	@Input() is_house_type: boolean = false;
	@Input() is_role: boolean = false;
	@Input() is_street: boolean = false;

	allowed_sign_codes: Array<number> = [
		46, // .
		44, // ,
		40, // (
		41, // )
		64, // @
		38, // &
		45, // -
		95, // _
		58, // :
		59, // ;
		39, // '
		34, // "
		32, // space (in both khmer and english)
	];

	roman_number_chars: Array<number> = [
		73, // I
		86, // V
		88, // X
		76, // L
		67, // C
	];

	allowed_name_sign_codes: Array<number> = [
		44, // ,
		45, // -
		47, // /
		40, // (
		41, // )
		60, // >
		62, // <
		46, // .
		32, // space (in both khmer and english)
	];

	constructor(private control: NgControl) { }

	@HostListener('change', ['$event'])
	onInputChange(event): void {
		let name = event.target.value;

		if (name != '') {
			name = this.cleanKhmerChar(name);
			this.control.valueAccessor.writeValue(name);
		}

		name = name.replace(/\s{2,}/g, ' '); // correct space format in string
		if (name.charAt(0) == ' ' && !this.isLastName) name = name.substr(1);
		if (name.charAt(name.length - 1) == ' ' && !this.isLastName) name = name.substring(0, name.length - 1);

		var output = "";
		for (var i = 0; i < name.length; i++) {

			if (this.is_house_type || this.is_company_name || this.is_street) {
				// 8203 : zero length space in khmer
				if (
					this.allowed_name_sign_codes.includes(name.charCodeAt(i)) ||
					(name.charCodeAt(i) != 8203 && (65 <= name.charCodeAt(i) && name.charCodeAt(i) <= 90) || (97 <= name.charCodeAt(i) && name.charCodeAt(i) <= 122)) ||
					(48 <= name.charCodeAt(i) && name.charCodeAt(i) <= 57)
				) {
					output += name.charAt(i);
				}

			} else if (this.is_role) {

				// 8203 : zero length space in khmer
				// 95: '_'
				if (name.charCodeAt(i) == 95 || (name.charCodeAt(i) != 8203 && (65 <= name.charCodeAt(i) && name.charCodeAt(i) <= 90) || (97 <= name.charCodeAt(i) && name.charCodeAt(i) <= 122))) {
					output += name.charAt(i);
				}

			} else {

				// 8203 : zero length space in khmer
				if (name.charCodeAt(i) == 32 || (name.charCodeAt(i) != 8203 && (65 <= name.charCodeAt(i) && name.charCodeAt(i) <= 90) || (97 <= name.charCodeAt(i) && name.charCodeAt(i) <= 122))) {
					output += name.charAt(i);
				}
			}

		}
		if (!this.isLastName || name != ' ') name = output.trim();
		this.control.valueAccessor.writeValue(this.capitalize ? name.toUpperCase() : name);
	}

	@HostListener('keypress', ['$event'])
	onKeyPressEn(event) {
		var ew = event.which;
		var valid: boolean = false;
		if (ew == 32) valid = true;
		else if (!this.is_company_name && this.allowed_name_sign_codes.includes(ew)) valid = true;
		else if (this.is_company_name && this.allowed_sign_codes.includes(ew)) valid = true;
		else if ((this.is_company_name || this.is_house_type || this.is_street) && 48 <= ew && ew <= 57) valid = true;
		else if (48 <= ew && ew <= 57) valid = false; // number in english (company name)
		else if (65 <= ew && ew <= 90) valid = true; // number in english (human name)
		else if (97 <= ew && ew <= 122) valid = true;
		else if (this.is_role && ew == 95) valid = true; // if input role and val == '_'
		else valid = false;
		return valid;
	}

	cleanKhmerChar(input: string, is_company_name: boolean = false) {
		var output = '';
		for (var i = 0; i < input.length; i++) {
			let cond = is_company_name
				? false
				: this.allowed_name_sign_codes.includes(input.charCodeAt(i));
			if (
				cond ||
				(48 <= input.charCodeAt(i) && input.charCodeAt(i) <= 57) ||
				(65 <= input.charCodeAt(i) && input.charCodeAt(i) <= 90) ||
				(97 <= input.charCodeAt(i) && input.charCodeAt(i) <= 122) ||
				this.allowed_sign_codes.includes(input.charCodeAt(i))
			) {
				output += input.charAt(i);
			}
		}
		return output;
	}

	onRemoveUselessChar(value: string) {
		return ['គ្មាន', 'ទទេ'].includes(value) ? '' : value;
	}

}

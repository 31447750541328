import { environment } from '../../../../environments/environment';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-pdf-viewer',
	templateUrl: './pdf-viewer.component.html',
	styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent {
	isImage: boolean = false;
	url: any;
	zoom = 'page-width';

	constructor(
		private dialogRef: MatDialogRef<PdfViewerComponent>,
		private sanitizer: DomSanitizer,
		@Inject(MAT_DIALOG_DATA) public data?: {
			file: any;
			url: string;
			isDataUpload: Boolean
		}
	) {
		if (!data.isDataUpload) {
			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.baseUrl + this.data.file.url);

		} else {
			this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
		}
	}
}

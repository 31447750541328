import { DatePipe } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';

export const DAY_MONTH_YEAR_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY'
    }
};

export const MONTH_YEAR_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    }
}

export const YEAR_FORMATS = {
    parse: {
        dateInput: 'YYYY',
    },
    display: {
        dateInput: 'YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    }
}

export const MyDateFormats: any[] = [
    {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DAY_MONTH_YEAR_FORMATS },
    DatePipe
]

export const getDateFormats = (formats: 'DEFUALT' | 'MONTH_YEAR' | 'YEAR' = 'DEFUALT') => {
    let value = formats == 'DEFUALT' ? DAY_MONTH_YEAR_FORMATS
                : formats == 'MONTH_YEAR' ? MONTH_YEAR_FORMATS
                : formats == 'YEAR' ? YEAR_FORMATS
                : DAY_MONTH_YEAR_FORMATS;
    
    return [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: value },
        DatePipe
    ]
}
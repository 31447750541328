import { DecimalPipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { fadeAnimation } from 'src/app/animations/fade-animation';
import { FILE_TYPE_ENUM } from 'src/app/models/enum/file-type.enum';
import { LOCAL_STORAGE_ENUM } from 'src/app/models/enum/local-storage.enum';
import { IFileType } from 'src/app/models/interface/int-file-types';
import { IHome } from 'src/app/models/interface/int-home';
import { IHomeType } from 'src/app/models/interface/int-home-type';
import { FileViewerDragDropComponent } from 'src/app/modules/shared/file-viewer-drag-drop/file-viewer-drag-drop.component';
import { CONFIRM_TYPE, GlobalConfirmDialogComponent } from 'src/app/modules/shared/global-confirm-dialog/global-confirm-dialog.component';
import { ImageViewerComponent } from 'src/app/modules/shared/image-viewer/image-viewer.component';
import { PdfViewerComponent } from 'src/app/modules/shared/pdf-viewer/pdf-viewer.component';
import { HouseCategoryService } from 'src/app/services/house-category.service';
import { HouseTypeService } from 'src/app/services/house-type.service';
import { HouseVerificationService } from 'src/app/services/house-verification.service';
import { KhanServiceService } from 'src/app/services/khan-service.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { RequestUpdateHouseService } from 'src/app/services/request-update-house.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { StreetService } from 'src/app/services/street.service';
import { UtilService } from 'src/app/services/util.service';
import { IHomeCategory } from '../../../../models/interface/int-home-category';
import { IStreet } from '../../../../models/interface/int-street';
import { IKhan } from '../../../../models/interface/loc-khan';
import { ILocLocation } from '../../../../models/interface/loc-location';

@Component({
	selector: 'app-house-form-dialog',
	templateUrl: './house-form-dialog.component.html',
	styleUrls: ['./house-form-dialog.component.scss'],
	animations: [fadeAnimation],
	providers: [DecimalPipe]
})
export class HouseFormDialogComponent implements OnInit {

	lang: string;

	// houseCategoryId: string;
	houseCategory: IHomeCategory[] = [];
	selectedHouseCat: IHomeCategory;
	houseCatInputcontrol = new FormControl('');

	houseType: IHomeType[] = [];
	selectedHouseType: IHomeType;
	houseTypesInputControl = new FormControl('');

	homeData: IHome;
	location: ILocLocation;
	form: FormGroup;
	homeForm: FormGroup;
	addressForm: FormGroup;
	homeTypeForm: FormGroup;
	homeReference: FormGroup;
	mapForm: FormGroup;

	streets: IStreet[] = [];
	khans: IKhan[] = [];
	sangkats: ILocLocation[] = [];
	villages: ILocLocation[] = [];
	formType: string;


	is_set_number_of_rooms: boolean = false;
	U_TYPE: IUpdateType = UPDATE_TYPE;
	types: Array<any> = [
		{
			_id: this.U_TYPE.HOME_NAME,
			name: 'form_type.home_form'
		},
		{
			_id: this.U_TYPE.ADDRESS,
			name: 'form_type.address'
		},
		// {
		// 	_id: this.U_TYPE.HOME_TYPE,
		// 	name: 'form_type.home_type'
		// },
		{
			_id: this.U_TYPE.REFERENCE,
			name: 'form_type.reference'
		},
		// {
		// 	_id: this.U_TYPE.HOME_COORDINATE,
		// 	name: 'tracking.type.home_coordinate'
		// }
	];

	FILE_TYPE_ENUM: IFileType = FILE_TYPE_ENUM;

	validImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg', 'application/octet-stream'];
	validPdfTypes = ['application/pdf'];
	allowedFileTypes = 'image/*, application/pdf';
	images_for_preview: string[] = [];
	idFiles: any[] = [];
	invFiles: any[] = [];
	supFiles: any[] = [];
	filesSubmit: any[] = [];


	/**
	 * index
	 * 0 - home
	 * 1 - house category
	 * 2 - house type
	 */
	search: string[] = ['', '', ''];
	currentPageIndex: number[] = [1, 1, 1];
	lastScrollTop: number[] = [0, 0, 0];
	isScrollTop: boolean[] = [false, false, false];
	isScrollDown: boolean[] = [true, true, true];
	scrollEnable: boolean[] = [true, true, true];

	@ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
	@ViewChild(GoogleMap, { static: false }) map: GoogleMap;
	defauleLatLng: google.maps.LatLngLiteral = { lat: 11.576260014369531, lng: 104.92305119785212 };
	apiLoaded: Observable<boolean>;
	map_options: google.maps.MapOptions = {
		center: this.defauleLatLng,
		zoom: 15
	};
	markerOptions: google.maps.MarkerOptions = { draggable: false };
	markerPositions: google.maps.LatLngLiteral[] = [];
	private _destroyed = new Subject<void>();
	constructor(
		private fb: FormBuilder,
		private streetService: StreetService,
		private dialog: MatDialog,
		private translate: TranslateService,
		private houseTypeService: HouseTypeService,
		private houseVerService: HouseVerificationService,
		private khanService: KhanServiceService,
		private util: UtilService,
		private dialogRef: MatDialogRef<HouseFormDialogComponent>,
		private sessionStorageService: SessionStorageService,
		private houseCategoryService: HouseCategoryService,
		private RUHService: RequestUpdateHouseService,
		private loadingService: LoadingService,
		private localStorageService: LocalStorageService,
		private decimalPipe: DecimalPipe,
		@Inject(MAT_DIALOG_DATA) public data: {
			data: IHome,
			ref: any[]
		}
	) {
		this.translate.onLangChange.pipe(takeUntil(this._destroyed)).subscribe((event: LangChangeEvent) => {
			this.lang = event.lang;
		})

		this.homeData = this.data.data;
		this.location = this.homeData?.location;
		this.selectedHouseCat = this.homeData?.home_type?.home_category;
		this.is_set_number_of_rooms = this.homeData?.home_type?.is_set_number_of_rooms;
		this.formType = this.U_TYPE.ADDRESS;

		this.initControl();
		this.getVillagesBySangkatID(this.homeData?.sangkat?._id);
	}

	ngOnInit(): void {
		if (this.lang == '' || !this.lang) {
			this.lang = this.localStorageService.get(LOCAL_STORAGE_ENUM.lang);
		}

		this.getAllKhan();
		this.getHouseCategories();

		if (this.selectedHouseCat) this.getHouseTypes();
		if (this.location?.parent_id?._id) this.getSangatByKhanId(this.homeData.location.parent_id._id);
		if (this.location?._id) this.getStreetByLocation(this.location._id);
	}

	initControl(defaultType: boolean = true) {
		if (defaultType) {
			this.form = this.fb.group({
				type: new FormControl(this.U_TYPE.ADDRESS, Validators.required)
			});
		}

		let houseName = this.homeData.name_en ? this.homeData.name_en : this.homeData.name;
		let edcID = this.homeData.edc_id || this.homeData.edc_customer_number;
		this.homeForm = this.fb.group({
			name: new FormControl(houseName || '', Validators.required),
			edc_id: new FormControl(edcID || '', Validators.required),
			phones: this.fb.array([
				[null]
			])
		})

		if (this.homeData.phones.length > 0) {
			this.phonesFormArray.clear();

			for (let phone of this.homeData.phones) {
				if ([null, undefined, '', ' '].includes(phone)) {
					this.phonesFormArray.push(this.fb.control(null))

				} else {
					this.phonesFormArray.push(this.fb.control(phone))

				}
			}
		}

		this.addressForm = this.fb.group({
			khan: new FormControl(this.location?.parent_id?._id || '', Validators.required),
			location: new FormControl(this.location?._id || '', Validators.required),
			// street: new FormControl(this.homeData?.street?._id || ''),
			village: new FormControl(this.homeData?.village?._id || '', Validators.required),
			house_no: new FormControl(this.homeData?.house_no || '', Validators.required),
			address: new FormControl(this.homeData?.address || ''),
			street_number: new FormControl(this.homeData?.street_number || '', Validators.required)
		})


		let fee = this.decimalPipe.transform(this.homeData?.home_type?.amount, '1.0-2');
		this.homeTypeForm = this.fb.group({
			home_type: [this.homeData?.home_type?._id || '', Validators.required],
			home_category: [this.selectedHouseCat?._id || '', Validators.required],
			number_of_rooms: [this.homeData.number_of_rooms || 1, Validators.required],
			montly_fee: [fee, Validators.required]
		})
		this.homeTypeForm.get('montly_fee').disable();
		if (!this.is_set_number_of_rooms) this.homeTypeForm.get('number_of_rooms').disable();
		else this.homeTypeForm.get('number_of_rooms').enable();


		this.homeReference = this.fb.group({
			type: new FormControl('', Validators.required),
			id_card_files: this.fb.array([], [Validators.required]),
			invoice_edc_files: this.fb.array([], [Validators.required]),
			home_image_files: this.fb.array([], [Validators.required]),
			support_doc_files: this.fb.array([], [Validators.required])
		})
		this.patchFiles(this.data?.ref);

		this.mapForm = this.fb.group({
			latitude: [this.homeData.latitude ? this.homeData.latitude : this.defauleLatLng.lat, Validators.required],
			longitude: [this.homeData.longitude ? this.homeData.longitude : this.defauleLatLng.lng, Validators.required]
		})
	}

	patchFiles(files: any[]) {
		for (let f of files) {
			switch (f.metadata.type) {
				case this.FILE_TYPE_ENUM.ID_CARD:
					this.idCardFiles.push(this.createItem(f));
					break;


				case this.FILE_TYPE_ENUM.INVOICE_EDC:
					this.invoiceEdcFiles.push(this.createItem(f));
					break;


				case this.FILE_TYPE_ENUM.HOME_IMAGE:
					this.homeImageFiles.push(this.createItem(f));
					break;


				case this.FILE_TYPE_ENUM.SUPPORTING_DOCUMENT:
					this.supportDocFiles.push(this.createItem(f));
					break;
			}
		}
	}

	get idCardFiles(): FormArray {
		return this.homeReference.get('id_card_files') as FormArray;
	};

	get invoiceEdcFiles(): FormArray {
		return this.homeReference.get('invoice_edc_files') as FormArray;
	};

	get homeImageFiles(): FormArray {
		return this.homeReference.get('home_image_files') as FormArray;
	};

	get supportDocFiles(): FormArray {
		return this.homeReference.get('support_doc_files') as FormArray;
	};

	createItem(data: any): FormGroup {
		return this.fb.group(data);
	}

	onSubmit() {
		this.houseVerService.update(this.homeData?._id, this.dataJson, this.filesSubmit).subscribe(
			res => { if (res.status == 1) this.dialogRef.close(true) },
			err => {
				let val = this.form.value;

				if (val.type == UPDATE_TYPE.HOME_NAME) {
					this.houseVerService.onSubmitFail(err, this.homeForm);
				}
				else if (val.type == UPDATE_TYPE.ADDRESS) {
					this.houseVerService.onSubmitFail(err, this.addressForm);
				}
				else if (val.type == UPDATE_TYPE.HOME_TYPE) {
					this.houseVerService.onSubmitFail(err, this.homeTypeForm);
				}
				else if (val.type == this.U_TYPE.HOME_COORDINATE) {
					this.houseVerService.onSubmitFail(err, this.mapForm);
				}
			}
		)
	}

	onKhanChange() {
		this.getSangatByKhanId(this.addressForm.value?.khan);
		this.addressForm.get("location").setValue('');
		// this.addressForm.get("street").setValue('');
		this.streets = [];
		this.manipulateForm();
	}

	fileNotAlreadyExist(file: any, container: any[]): boolean {
		return !container.map(item => item.files ? item.files.name : item.filename).includes(file.name);
	}

	onAlertWrongFileType() {
		this.dialog.open(GlobalConfirmDialogComponent, {
			width: '500px',
			disableClose: true,
			autoFocus: false,
			data: {
				title: 'dialog.confirm.alert',
				confirm_desc: 'message.wrong_file_type'
			}
		})
	}

	onAlertDuplicatedFile() {
		this.dialog.open(GlobalConfirmDialogComponent, {
			width: '500px',
			disableClose: true,
			autoFocus: false,
			data: {
				title: 'dialog.confirm.alert',
				confirm_desc: 'message.file_already_exist'
			}
		})
	}

	onChange(event, type?: string) {
		if ((event.target.files && event.target.files[0])) {
			let filesAmount = event.target.files.length;
			let fileType = event.target.files[0].type;

			if (!this.validImageTypes.includes(fileType) && !this.validPdfTypes.includes(fileType)) {
				this.onAlertWrongFileType();
				return;
			}

			for (let i = 0; i < filesAmount; i++) {
				switch (type) {
					case this.FILE_TYPE_ENUM.ID_CARD:
						if (this.fileNotAlreadyExist(event.target.files[i], this.idCardFiles.value)) {
							this.idCardFiles.push(this.createItem({ files: event.target.files[i], type: type }));

						} else this.onAlertDuplicatedFile();
						break;


					case this.FILE_TYPE_ENUM.INVOICE_EDC:
						if (this.fileNotAlreadyExist(event.target.files[i], this.invoiceEdcFiles.value)) {
							this.invoiceEdcFiles.push(this.createItem({ files: event.target.files[i], type: type }));

						} else this.onAlertDuplicatedFile();
						break;


					case this.FILE_TYPE_ENUM.HOME_IMAGE:
						if (this.fileNotAlreadyExist(event.target.files[i], this.homeImageFiles.value)) {
							this.homeImageFiles.push(this.createItem({ files: event.target.files[i], type: type }));

						} else this.onAlertDuplicatedFile();
						break;


					case this.FILE_TYPE_ENUM.SUPPORTING_DOCUMENT:
						if (this.fileNotAlreadyExist(event.target.files[i], this.supportDocFiles.value)) {
							this.supportDocFiles.push(this.createItem({ files: event.target.files[i], type: type }));

						} else this.onAlertDuplicatedFile();
						break;
				}
			}
		}
	}

	onRemoveFile(file: any, type: string, index: number) {
		this.dialog.open(GlobalConfirmDialogComponent, {
			disableClose: true,
			autoFocus: false,
			width: '500px',
			data: {
				is_reject: true,
				confirm_type: CONFIRM_TYPE.NORMAL,
				title: this.translate.instant('dialog.confirm.alert'),
				confirm_desc: this.translate.instant('message.remove_data')
			},

		}).afterClosed().subscribe(d_res => {
			if (d_res) {
				if (file?.metadata?.type || file?.filename) {
					this.houseVerService.deleteFileHome(this.homeData._id, file._id).subscribe(res => {
						if (res.status == 1) this.dialogRef.close(true);
					})

				} else {
					switch (type) {
						case this.FILE_TYPE_ENUM.ID_CARD:
							const t_id_card = this.homeReference.get('id_card_files') as FormArray;
							t_id_card.removeAt(index);
							break;


						case this.FILE_TYPE_ENUM.INVOICE_EDC:
							const t_inv_edc = this.homeReference.get('invoice_edc_files') as FormArray;
							t_inv_edc.removeAt(index);
							break;


						case this.FILE_TYPE_ENUM.HOME_IMAGE:
							const t_home_img = this.homeReference.get('home_image_files') as FormArray;
							t_home_img.removeAt(index);
							break;


						case this.FILE_TYPE_ENUM.SUPPORTING_DOCUMENT:
							const t_spp_doc = this.homeReference.get('support_doc_files') as FormArray;
							t_spp_doc.removeAt(index);
							break;
					}
				}
			}
		})
	}

	getDocument(file: any) {
		this.loadingService.setLoading(true);

		if (file?.type) {
			this.onViewFiles(file);
			this.loadingService.setLoading(false)
		} else {
			// if file is img type
			if (this.validImageTypes.includes(file.contentType)) {
				this.RUHService.getImageDoc(file.request).subscribe((res: any) => {
					this.loadingService.setLoading(false);

					// if (isDownload) this.downloadFile(res, file.filename, file.contentType);
					this.onViewFile(res, this.getFileName(file), file.contentType);
				});


				// if file is pdf type
			} else if (this.validPdfTypes.includes(file.contentType)) {
				this.RUHService.getDocument(file.request).subscribe((res: any) => {
					this.loadingService.setLoading(false);

					// this.downloadFile(res, file.filename, file.contentType);
					this.onViewFile(res, this.getFileName(file), file.contentType);
				});
			}
		}

	}

	getFileName(file: any) {
		let name = '';

		switch (file.metadata.type) {
			// case FILE_TYPE_ENUM.ID_CARD:
			// 	name = this.translate.instant('file_type.id_card')
			// 	break;

			// case FILE_TYPE_ENUM.INVOICE_EDC:
			// 	name = this.translate.instant('file_type.invoice_edc')
			// 	break;

			// case FILE_TYPE_ENUM.SUPPORTING_DOCUMENT:
			// 	name = this.translate.instant('file_type.support_doc')
			// 	break;

			default: return file.filename;
		}

	}

	onViewFiles(file: any) {
		if (this.validImageTypes.includes(file.type)) {
			let url = '';
			const reader = new FileReader();
			reader.onload = (event: any) => {
				url = event.target.result;
				this.dialog.open(ImageViewerComponent, {
					role: 'dialog',
					width: '750px',
					data: {
						isUpload: true,
						image: url
					}
				});
			}
			reader.readAsDataURL(file);



		} else if (this.validPdfTypes.includes(file.type)) {
			let blob = new Blob([file], { type: 'application/pdf' });
			let url = window.URL.createObjectURL(blob);

			this.dialog.open(PdfViewerComponent, {
				width: '800px',
				data: {
					isDataUpload: true,
					url: url
				}
			})

		} else {
			this.dialog.open(GlobalConfirmDialogComponent, {
				width: '500px',
				disableClose: true,
				autoFocus: false,
				data: {
					title: 'dialog.confirm.alert',
					confirm_desc: 'message.wrong_file_type'
				}

			})

		}
	}

	onViewFile(data: any, name: string, type: string) {
		let blob = new Blob([data], { type: type });
		let url = window.URL.createObjectURL(blob);
		let isImage = !!this.validImageTypes.includes(type);

		this.dialog.open(FileViewerDragDropComponent, {
			height: "90vh",
			// width: "320px",
			maxWidth: "90vw",
			backdropClass: "file-viewer",
			panelClass: "custom-file-container",
			data: {
				name: name,
				isImage: isImage,
				url: url
			}
		})
	}

	onTypeChange(event) {
		this.formType = event.value;

		switch (this.formType) {
			case this.U_TYPE.HOME_COORDINATE:
				this.mapForm.get('latitude').reset();
				this.mapForm.get('longitude').reset();
				break;
		}

		if (this.data.data) {
			this.initControl(false);

			if (this.formType == this.U_TYPE.HOME_COORDINATE) {
				this.loadingService.setLoading(true);
				setTimeout(() => {
					this.loadingService.setLoading(false);
					this.onChangeCoordinate();
				}, 500);
			}
		}
	}

	// getHouseTypes() {
	// 	this.houseTypeService.getAll(this.currentPageIndex[2], 10, this.search[2], this.selectedHouseCat._id).subscribe(res => {
	// 		if (res.status == 1) this.houseType = res.data.data;
	// 	})
	// }

	getAllSangkat() {
		this.khanService.getAllSangKat().subscribe(res => {
			if (res.status == 1) this.sangkats = res?.data?.sangkats;
		})
	}

	getVillagesBySangkatID(sangkatID: string) {
		this.khanService.getVillagesBySangkatID(sangkatID).subscribe(res => {
			if (res.status == 1) this.villages = res?.data?.villages;
		})
	}

	getAllStreet() {
		this.streetService.getAll({ page: 1, limit: 10 }).subscribe(res => {
			if (res.status == 1) this.streets = res.data.data;
		})
	}

	getAllKhan() {
		this.khanService.getAllKhan().subscribe(
			(res) => (this.khans = res.data),
			(err) => console.error(err)
		);
	}

	getSangatByKhanId(id: string) {
		this.khanService.getSangKatByKhanID(id).subscribe(
			(res) => {
				this.sangkats = res.data.sangkats;
			},
			(err) => console.error(err)
		);
	}

	getStreetByLocation(id: string) {
		if (id) {
			this.streetService.getStreetsBySangkatID(id).subscribe(res => {
				this.streets = res.data;
			});

			this.getVillagesBySangkatID(id);
		} else {
			// this.addressForm.get("street").reset();
			this.streets = [];
		}
	}

	getAllSteet(sangkats: string[] | string, pageIndex: number, pageSize: number) {
		this.streetService.getAll({ page: pageIndex, limit: pageSize, locations: sangkats }).subscribe(res => {
			this.streets = res.data.data;
		});
	}

	/**
	 * DISABLE FORM CONTROL BASED ON CERTAIN CONDITIONS
	 */
	manipulateForm() {
		// manipulate location control
		if (!this.addressForm.get('khan').value) this.addressForm.get('location').disable();
		else this.addressForm.get('location').enable();
		this.addressForm.get('location').updateValueAndValidity();

		// manipulate street control
		// if (!this.addressForm.get('location').value) this.addressForm.get('street').disable();
		// else this.addressForm.get('street').enable();
		// this.addressForm.get('street').updateValueAndValidity();

		// manipulate home type control
		if (!this.homeTypeForm.get('home_category').value) this.homeTypeForm.get('home_type').disable();
		else this.homeTypeForm.get('home_type').enable();
		this.homeTypeForm.get('home_type').updateValueAndValidity();
	}

	get dataJson() {
		let val = this.form.value;
		let homeForm = this.homeForm.value;
		let address = this.addressForm.value;
		let home_type = this.homeTypeForm.value;
		let home_ref = this.homeReference.value;
		let mapVal = this.mapForm.value;
		let data

		if (val.type == UPDATE_TYPE.HOME_NAME) {
			data = {
				type: val.type,
				name: homeForm.name,
				edc_id: homeForm.edc_id,
				phones: homeForm.phones.filter(item => item),
			}

		} else if (val.type == UPDATE_TYPE.ADDRESS) {
			data = {
				type: val.type,
				location: address.location || '',
				// street: address.street || '',
				street_number: address.street_number || '',
				village: address.village || '',
				house_no: address.house_no || '',
				address: address?.address || '',
			}

		} else if (val.type == UPDATE_TYPE.HOME_TYPE) {
			data = {
				type: val.type,
				home_type: home_type?.home_type || '',
				number_of_rooms: home_type?.number_of_rooms ? home_type?.number_of_rooms : 1
			}

		} else if (val.type == UPDATE_TYPE.REFERENCE) {
			let id_card_files = home_ref.id_card_files.filter(item => !item.metadata);
			let invoice_edc_files = home_ref.invoice_edc_files.filter(item => !item.metadata);
			let home_image_files = home_ref.home_image_files.filter(item => !item.metadata);
			let support_doc_files = home_ref.support_doc_files.filter(item => !item.metadata);
			this.filesSubmit = [...id_card_files, ...invoice_edc_files, ...home_image_files, ...support_doc_files];

			data = {
				type: val.type,
				files: this.filesSubmit
			}

		} else if (val.type == this.U_TYPE.HOME_COORDINATE) {
			data = {
				type: val.type,
				latitude: mapVal.latitude,
				longitude: mapVal.longitude
			}

		}

		return data;
	}

	get isDisable() {
		switch (this.form.get('type').value) {
			case this.U_TYPE.HOME_NAME:
				if (this.homeForm.invalid) return true;
				else return false;

			case this.U_TYPE.ADDRESS:
				if (this.addressForm.invalid) return true;
				else return false;

			case this.U_TYPE.HOME_TYPE:
				if (this.homeTypeForm.invalid) return true;
				else return false;

			case this.U_TYPE.REFERENCE:
				let val = this.homeReference.value;

				if ((val.home_image_files.length == 0 &&
					val.id_card_files.length == 0 &&
					val.invoice_edc_files.length == 0 &&
					val.support_doc_files.length == 0)) return true;
				else return false;

			case this.U_TYPE.HOME_COORDINATE:
				if (this.mapForm.invalid) return true;
				else return false;
		}
	}

	onChangeCoordinate() {
		let val = this.mapForm.value;
		let lat = parseFloat(val.latitude);
		let lng = parseFloat(val.longitude);
		this.markerPositions = [{ lat, lng }];
		this.map.zoom = 15;
		this.map.panTo({ lat, lng });
	}

	onClickMap(e: any) {
		let lat = e.latLng.lat();
		let lng = e.latLng.lng();

		this.markerPositions = [{ lat, lng }];
		this.mapForm.setValue({
			latitude: lat,
			longitude: lng
		})

	}

	keypressCoordinate(event: any) {
		var ew = event.which;
		var valid: boolean = false;

		if (ew == 46 || (48 <= ew && ew <= 57)) valid = true; // number in english
		else valid = false;
		if (event.target.value.length > 18) return false;
		return valid;
	}

	onChangeNumber(formControl: AbstractControl | null | undefined, event: any) {
		let value = event.target.value;
		value = value.replace(/\s{2,}/g, ''); // correct space format in string
		var output = "";
		for (var i = 0; i < value.length; i++) {
			if (value.charCodeAt(i) == 46 || (48 <= value.charCodeAt(i) && value.charCodeAt(i) <= 57)) {
				output += value.charAt(i);
			}
		}

		value = output.trim();
		formControl?.patchValue(value.toUpperCase());
	}

	getHouseCategories() {
		this.houseCategoryService.getAll(this.currentPageIndex[1], 100, this.search[1], true).subscribe(res => {
			this.houseCategory.push(...res.data.data);
			this.scrollEnable[1] = res.data.data.length > 0;
		})
	}

	getHouseTypes() {
		this.houseTypeService.getAll(this.currentPageIndex[2], 100, this.search[2], this.selectedHouseCat._id, true).subscribe(res => {
			this.houseType.push(...res.data.data);
			this.scrollEnable[2] = res.data.data.length > 0;
		})
	}

	getHomeCatName(home: IHomeCategory) {
		if (this.lang == 'en') return home.code + ' - ' + home.name_en;
		else return home.code + ' - ' + home.name_kh;
	}

	getHomeTypeName(home: IHomeType) {
		if (this.lang == 'en') return home.code + ' - ' + home.amount + ' - ' + home.name_en;
		else return home.code + ' - ' + home.amount + ' - ' + home.name_kh;
	}

	onHouseCatSearchChange(txtSearch: string) {
		this.houseCategory = [];
		this.selectedHouseCat = null;
		this.homeTypeForm.get('home_category').setValue('');
		this.homeTypeForm.get('home_type').setValue('');

		this.search[1] = txtSearch;
		this.currentPageIndex[1] = 1;
		this.getHouseCategories();
	}

	onHouseTypeSearchChange(txtSearch: string) {
		this.houseType = [];
		this.selectedHouseType = null;
		this.homeTypeForm.get('home_type').setValue('');
		this.homeTypeForm.get('home_type').updateValueAndValidity();

		this.search[2] = txtSearch;
		this.currentPageIndex[2] = 1;
		this.getHouseTypes();
	}

	onChangeHouseCategory(house: IHomeCategory) {
		this.selectedHouseCat = house;
		this.homeTypeForm.get("home_type").setValue('');
		this.homeTypeForm.get("montly_fee").setValue('-');
		this.manipulateForm();

		this.search[2] = ''; // clear searchquery of house type
		this.houseType = []; // clear current data of house type
		this.currentPageIndex[2] = 1; // current page index of house type
		this.selectedHouseType = null; // clear current selected house type
		this.houseTypesInputControl.setValue(''); // clear house type input control
		this.getHouseTypes();
	}

	onChangeHouseType() {
		if (!this.selectedHouseType.is_set_number_of_rooms) this.homeTypeForm.get('number_of_rooms').disable();
		else this.homeTypeForm.get('number_of_rooms').enable();

		this.homeTypeForm.get('number_of_rooms').setValue(1);
		this.homeTypeForm.get("montly_fee").setValue(this.decimalPipe.transform(this.selectedHouseType.amount, '1.0-2'));
	}

	onScroll(event: any, type: string) {
		let offsetHeight = event.target.offsetHeight;  // visible div height.
		let scrollTop = event.target.scrollTop; // the length that count from bottom of the visible div height to the top.
		let scrollHeight = event.target.scrollHeight; // overall height of the div.

		if (type == 'house_cat') {
			this.isScrollTop[1] = scrollTop < this.lastScrollTop[1];
			this.isScrollDown[1] = scrollTop > this.lastScrollTop[1];
			this.lastScrollTop[1] = scrollTop;

			if (this.scrollEnable[1] && this.isScrollDown[1]) {
				if (scrollTop > 0 && (offsetHeight + scrollTop >= scrollHeight * 0.7)) {
					this.scrollEnable[1] = false;

					this.currentPageIndex[1]++;
					this.getHouseCategories();
				}
			}

		} else if (type == 'house_type') {
			this.isScrollTop[2] = scrollTop < this.lastScrollTop[2];
			this.isScrollDown[2] = scrollTop > this.lastScrollTop[2];
			this.lastScrollTop[2] = scrollTop;

			if (this.scrollEnable[2] && this.isScrollDown[2]) {
				if (scrollTop > 0 && (offsetHeight + scrollTop >= scrollHeight * 0.7)) {
					this.scrollEnable[2] = false;

					this.currentPageIndex[2]++;
					this.getHouseTypes();
				}
			}
		}
	}

	get phonesFormArray(): FormArray {
		return this.homeForm.get('phones') as FormArray;
	}

	handleRemovePhoneControl(i: number) {
		this.dialog.open(GlobalConfirmDialogComponent, {
			disableClose: true,
			autoFocus: false,
			width: '500px',
			data: {
				is_reject: true,
				confirm_type: CONFIRM_TYPE.NORMAL,
				title: this.translate.instant('dialog.confirm.alert'),
				confirm_desc: this.translate.instant('message.remove_data')
			}

		}).afterClosed().subscribe(res => {
			if (res) this.phonesFormArray.removeAt(i)
		})
	}

	handleAddPhoneControl() {
		this.phonesFormArray.push(this.fb.control(null));
	}
}

export enum UPDATE_TYPE {
	HOME_NAME = 'HOME_NAME',
	ADDRESS = "ADDRESS",
	HOME_TYPE = "HOME_TYPE",
	REFERENCE = "REFERENCE",
	HOME_COORDINATE = "HOME_COORDINATE"
}

interface IUpdateType {
	HOME_NAME: string;
	ADDRESS: string;
	HOME_TYPE: string;
	REFERENCE: string;
	HOME_COORDINATE: string;
}

<div class="tree-container" [ngClass]="{'tree-container': isHeightOverflow}">
    <div class="checkbox-holder mt-3">
        <mat-checkbox
            (change)="checkAll($event)"
            [disabled]="isDisable" 
            [checked]="selection.selected.length == treeControl.dataNodes.length"
            [indeterminate]="selection.selected.length > 0 && selection.selected.length < treeControl.dataNodes.length">
            {{ 'form-field.all' | translate }}
        </mat-checkbox>
    </div>
    
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding @fade>
            <button mat-icon-button disabled></button>
            <div class="checkbox-holder">
                <mat-checkbox [disabled]="isDisable" [checked]="selection.isSelected(node.item._id)" (change)="toggleSelection(node)">
                    {{ getLocationName(node.item) }}
                </mat-checkbox>
            </div>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding @fade>
            <button mat-icon-button matTreeNodeToggle>
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>

            <div class="checkbox-holder">
                <mat-checkbox 
                    [disabled]="isDisable"
                    [checked]="selection.isSelected(node) || descendantsAllSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)"
                    (change)="parentToggleSelection(node, $event)">
                    {{ getLocationName(node.item) }}
                </mat-checkbox>
            </div>
        </mat-tree-node>
    </mat-tree>
</div>
<router-outlet></router-outlet>
<!-- <ngx-spinner name="pageLoading" bdColor="rgba(0,0,0,0.3)" [fullScreen]="true" color="#" size="default" type="ball-pulse">

    <p>{{'root.loading' | translate}}</p>
</ngx-spinner> -->
<!-- <ngx-spinner name="pageLoading" bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->

<!-- <ngx-spinner name="pageLoading" bdColor="rgba(0,0,0,0)" [fullScreen]="true" size="medium" type="ball-spin-clockwise">
    <mat-spinner diameter="25" strokeWidth="3" [mode]="'indeterminate'"></mat-spinner>
    <p>{{'root.loading' | translate}}</p>
</ngx-spinner> -->


<ngx-spinner name="pageLoading" bdColor="rgba(0,0,0,0.3)" [fullScreen]="true" size="medium" type="ball-spin-clockwise">
    <!-- <p>{{'root.loading' | translate}}</p> -->
</ngx-spinner>


import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  get(key: string): string {
    return localStorage.getItem(key);
  }

  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  setArray(key: string, values: any[]) {
    const value = values.toString();
    this.setEncryption(key, value);
  }

  getArray(key: string): any[] {
    const value = this.getDecryption(key);
    return value ? value.split(',') : null;
  }

  setEncryption(key: string, value: string) {
    const prefix = btoa(key).replace(/=/g, '');
    const base64 = btoa(this.toBinary(value));
    localStorage.setItem(key, btoa(prefix + base64));
  }

  getDecryption(key: string) {
    const prefix = btoa(key).replace(/=/g, '');
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }
    try {
      const base64 = atob(item).replace(prefix, '');
      return this.fromBinary(atob(base64));
    } catch (error) {
      return null;
    }
  }

  toBinary(value: string) {
    const codeUnits = new Uint16Array(value.length);
    for (let i = 0; i < codeUnits.length; i++) {
      codeUnits[i] = value.charCodeAt(i);
    }
    return btoa(String.fromCharCode(...new Uint8Array(codeUnits.buffer)));
  }

  fromBinary(encoded_value: string) {
    let binary = atob(encoded_value);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return String.fromCharCode(...new Uint16Array(bytes.buffer));
  }
}

import { KhanServiceService } from 'src/app/services/khan-service.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ILocationTree } from 'src/app/models/interface/loc-location-tree';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE_ENUM } from 'src/app/models/enum/local-storage.enum';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { fadeAnimation } from 'src/app/animations/fade-animation';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { UtilService } from 'src/app/services/util.service';


/** File node data with possible child nodes. */

/**
 * Flattened tree node that has been created from a FileNode through the flattener. Flattened
 * nodes include level index and whether they can be expanded or not.
 */
export interface FlatTreeNode {
	item: ILocationTree;
	level: number;
	expandable: boolean;
}
@Component({
	selector: 'app-select-location-tree',
	templateUrl: './select-location-tree.component.html',
	styleUrls: ['./select-location-tree.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SelectLocationTreeComponent
		}
	],
	animations: [fadeAnimation]
})

export class SelectLocationTreeComponent implements OnInit, OnDestroy, ControlValueAccessor {

	@Input() isDisable: boolean = false;
	@Input() isHeightOverflow: boolean = false;
	// @Input() isCheckAll: Observable<any> = new Observable<any>();
	@Input()
	set offices(value: ILocationTree[]) {
		this.dataSource.data = value;
		for (let id of this.selection.selected) {
			if (!this.isAvailable(id, this.dataSource.data)) {
				this.selection.deselect(id);
			}
		}
		this.cdr.detectChanges();
	}

	lang: string;

	selection: SelectionModel<any> = new SelectionModel(true, []);

	/** The TreeControl controls the expand/collapse state of tree nodes.  */
	treeControl: FlatTreeControl<FlatTreeNode>;

	/** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
	treeFlattener: MatTreeFlattener<ILocationTree, FlatTreeNode>;

	/** The MatTreeFlatDataSource connects the control and flattener to provide data. */
	dataSource: MatTreeFlatDataSource<ILocationTree, FlatTreeNode>;

	onTouched: Function = () => { };
	touched: boolean = false;

	private eventsSubscription: Subscription
	private _destroyed = new Subject<void>();
	constructor(
		private cdr: ChangeDetectorRef,
		private translate: TranslateService,
		private util: UtilService,
		private localStorageService: LocalStorageService,
		private khanService: KhanServiceService
	) {
		this.cdr.detach();

		this.treeFlattener = new MatTreeFlattener(
			this.transformer,
			this.getLevel,
			this.isExpandable,
			this.getChildren
		);
		this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
		this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

		this.translate.onLangChange.pipe(takeUntil(this._destroyed)).subscribe((event: LangChangeEvent) => {
			this.lang = event.lang;
			this.cdr.detectChanges();
		});

		// clear all selected location when change role
		this.khanService.forceTriggerChangeEvent.subscribe(res => {
			if (res) {
				this.selection.clear();
				this.cdr.detectChanges();
			}
		})
	}

	ngOnInit(): void {
		if (this.lang == '' || !this.lang) {
			this.lang = this.localStorageService.get(LOCAL_STORAGE_ENUM.lang);
		}
	}

	// toggleSelection(node: FlatTreeNode): void {
	//   this.selection.toggle(node.item._id);
	//   this.checkAllParentsSelection(node);
	// }

	writeValue(obj: string[]): void {
		this.selection.select(...obj);
		// if (!this.isCheckAll) this.selection.clear();
	}

	registerOnChange(fn: any): void {
		this.selection.changed
			.pipe(takeUntil(this._destroyed))
			.subscribe((selected) => {
				fn(selected.source.selected);
				this.cdr.detectChanges();
			});
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	descendantsAllSelected(node: FlatTreeNode): boolean {
		const descendants = this.treeControl.getDescendants(node);
		const descAllSelected =
			descendants.length > 0 &&
			descendants.every(child => {
				return this.selection.isSelected(child.item._id);
			});
		return descAllSelected;
	}

	/** Whether part of the descendants are selected */
	descendantsPartiallySelected(node: FlatTreeNode): boolean {
		const descendants = this.treeControl.getDescendants(node);
		const result = descendants.some(child => this.selection.isSelected(child.item._id));
		return result && !this.descendantsAllSelected(node);
	}

	toggleSelection(node: FlatTreeNode): void {
		this.selection.toggle(node.item._id);
		this.checkAllParentsSelection(node);
	}

	/** Toggle item selection. Select/deselect all the descendants node */
	parentToggleSelection(node: FlatTreeNode, event: MatCheckboxChange): void {
		const descendants = this.treeControl.getDescendants(node).map(des => des.item._id);

		if (event.checked) {
			this.selection.select(...descendants);

		} else if (!event.checked) {
			this.selection.deselect(node.item._id, ...descendants)
		}
	}

	checkAll(e: MatCheckboxChange) {
		if (e.checked) {
			for (let parent of this.treeControl.dataNodes) {
				if (!this.selection.isSelected(parent.item._id))
					this.selection.toggle(parent.item._id);
			}

		} else {
			this.selection.clear();
		}

	}

	checkAllParentsSelection(node: FlatTreeNode): void {
		let parent: FlatTreeNode | null = this.getParentNode(node);
		while (parent) {
			this.checkRootNodeSelection(parent);
			parent = this.getParentNode(parent);
		}
	}

	checkRootNodeSelection(node: FlatTreeNode): void {
		const nodeSelected = this.selection.isSelected(node.item._id);
		const descendants = this.treeControl.getDescendants(node);
		const descAllSelected =
			descendants.length > 0 &&
			descendants.every(child => {
				return this.selection.isSelected(child.item._id);
			});
		if (nodeSelected && !descAllSelected) {
			this.selection.deselect(node.item._id);
		} else if (!nodeSelected && descAllSelected) {
			this.selection.select(node.item._id);
		}
	}

	/* Get the parent node of a node */
	getParentNode(node: FlatTreeNode): FlatTreeNode | null {
		const currentLevel = this.getLevel(node);

		if (currentLevel < 1) {
			return null;
		}

		const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

		for (let i = startIndex; i >= 0; i--) {
			const currentNode = this.treeControl.dataNodes[i];

			if (this.getLevel(currentNode) < currentLevel) {
				return currentNode;
			}
		}
		return null;
	}

	/** Transform the data to something the tree can read. */
	transformer(node: ILocationTree, level: number): FlatTreeNode {
		return {
			item: node,
			level,
			expandable: node.childs.length > 0
		};
	}

	/** Get the level of the node */
	getLevel(node: FlatTreeNode): number {
		return node.level;
	}

	/** Get whether the node is expanded or not. */
	isExpandable(node: FlatTreeNode): boolean {
		return node.expandable;
	}

	/** Get whether the node has children or not. */
	hasChild(index: number, node: FlatTreeNode): boolean {
		return node.expandable;
	}

	/** Get the children for the node. */
	getChildren(node: ILocationTree): ILocationTree[] | null | undefined {
		return node.childs;
	}

	isAvailable(id: string, source: ILocationTree[]): boolean {
		let check: boolean = false;
		for (let office of source) {
			if (office._id == id) {
				check = true;
			} else {
				if (office.childs && office.childs.length > 0) {
					if (this.isAvailable(id, office.childs)) {
						check = true;
					}
				}
			}
		}
		return check;
	}

	getLocationName(value: any) {
		if (this.lang == 'en') return value.type_en + " " + value.name_en;
		else return value.type_kh + " " + value.name_kh;
	}

	ngOnDestroy(): void {
		this._destroyed.next();
		this._destroyed.complete();
	}
}

<mat-form-field appearance="outline" class="m-form-select show-outline no-prefix-icon" showErrorMessage>
    <mat-label>{{'form-field.user' | translate}}</mat-label>

    <mat-select disableRipple disableOptionCentering [formControlName]="name">
        <div class="select-content" (click)="$event.stopPropagation()">
            <div class="search-field">
                <mat-option class="user-options" (click)="$event.stopPropagation()">
                    <mat-form-field appearance="legacy" class="m-form-input">
                        <mat-icon mat-prefix class="m-icon m-icon-normal" (click)="$event.stopPropagation()">phone</mat-icon>
                        <input matInput numberInput [formControl]="userInputControl" (keydown.enter)="$event.stopPropagation(); onUserSearchChange(userInputControl.value)" (keydown.space)="$event.stopPropagation()" (click)="$event.stopPropagation()" (focus)="isUserInputFocus = false" (blur)="isUserInputFocus = true" #userInput />
                    </mat-form-field>
                </mat-option>
                <button mat-raised-button class="search-button m-button-normal" (click)="onUserSearchChange(userInputControl.value)">
                    <mat-icon class="normal-icon">search</mat-icon> {{'form-field.search' | translate}}
                </button>
                <p class="placeholder" *ngIf="isUserInputFocus && !userInput.value">{{'table.phone_number' | translate}}</p>
            </div>

            <div class="select-list" (scroll)="onScroll($event, 'user')">
                <ng-container *ngIf="users.length > 0">
                    <mat-option class="option-list" *ngFor="let item of users" [value]="item._id" (click)="onClickUser(item)">
                        <p class="option-name">
                            <span>{{item.profile.last_name + ' ' + item.profile.first_name}} - {{item.profile.phone_number}}</span>
                        </p>
                    </mat-option>
                </ng-container>

                <ng-container *ngIf="users.length == 0">
                    <div class="no-data">
                        <img src="../../../../../assets/image/no-data.svg" alt="">
                        <p>{{'table.no-data' | translate}}</p>
                    </div>
                </ng-container>
            </div>
        </div>
    </mat-select>

    <p class="selected-option" *ngIf="selectedUser">
        <span>{{ selectedUser ? selectedUser.profile.last_name + ' ' + selectedUser.profile.first_name: '-' }} - {{selectedUser.profile.phone_number}}</span>
    </p>
</mat-form-field>
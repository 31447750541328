import { trigger, transition, style, animate, group, query } from "@angular/animations";

const duration = 200;
const distance = 20;

const EntranceTransition = [
    style({
        position: 'relative',
        overflow: 'hidden'
    }),

    query(':enter, :leave', [
        style({
            position: 'absolute',
            top: '{{ top }}',
            right: '{{ right }}',
            bottom: '{{ bottom }}',
            left: '{{ left }}',
            width: '{{ width }}',
            height: '{{ height }}',
        })
    ], { optional: true }),

    group([
        query(':leave', [
            animate(`${duration}ms ease-in`, style({
                opacity: 0,
                transform: `translateX(-${distance}px)`
            }))

        ], { optional: true }),

        query(':enter', [
            style({
                transform: `translateX(${distance}px)`,
                opacity: 0,
            }),
            animate(`100ms ${duration}ms ease-out`, style({
                opacity: 1,
                transform: 'translateX(0px)'
            }))

        ], { optional: true })
    ])
];

const ExitTransition = [
    style({
        position: 'relative',
        overflow: 'hidden'
    }),

    query(':enter, :leave', [
        style({
            position: 'absolute',
            top: '{{ top }}',
            right: '{{ right }}',
            bottom: '{{ bottom }}',
            left: '{{ left }}',
            width: '{{ width }}',
            height: '{{ height }}',
        })
    ], { optional: true }),

    group([
        query(':leave', [
            animate(`${duration}ms ease-in`, style({
                opacity: 0,
                transform: `translateX(${distance}px)`
            }))

        ], { optional: true }),

        query(':enter', [
            style({
                transform: `translateX(-${distance}px)`,
                opacity: 0,
            }),
            animate(`100ms ${duration}ms ease-out`, style({
                opacity: 1,
                transform: 'translateX(0px)'
            }))

        ], { optional: true })
    ])
];

export const routeAnimations = trigger('routeAnimations', [
    // ----------- HOUSE TRANSITION------------
    transition('houseDetail => *', ExitTransition),
    transition('createHouse => *', ExitTransition),
    transition('createHouseBatch => *', ExitTransition),
    transition('createReqHouse => *', ExitTransition),
    transition('uploadVerify => *', ExitTransition),
    transition('createInvoiceTypeAdjust => *', ExitTransition),
    transition('updateHouseTypeBatch => *', ExitTransition),


    // ----------- REQUEST TRANSITION------------
    transition('req-create => *', ExitTransition),
    transition('req-detail => *', ExitTransition),


    // ----------- CHECK HOUSE TRANSITION------------
    transition('checkingDetail => *', ExitTransition),


    // ----------- POST WASTE TRANSITION------------
    transition('postDetail => *', ExitTransition),
    transition('createPost => *', ExitTransition),


    // ----------- RECYCLE WASTE TRANSITION------------
    transition('recycleDetail => *', ExitTransition),
    transition('recycleForm => *', ExitTransition),


    // ----------- HOUSE TYPE TRANSITION------------
    transition('houseTypeDetail => *', ExitTransition),


    // ----------- OPERATOR TRANSITION------------
    transition('operatorCreate => *', ExitTransition),
    transition('operatorUpdate => *', ExitTransition),
    transition('operatorInfo => *', ExitTransition),


    // ----------- STREET TRANSITION------------
    transition('streetCreate => *', ExitTransition),
    transition('streetUpdate => *', ExitTransition),


    // ----------- STAFF TRANSITION------------
    transition('staffCreate => *', ExitTransition),
    transition('staffUpdate => *', ExitTransition),
    transition('staffInfo => *', ExitTransition),


    // ----------- STAFF TRANSITION------------
    transition('userDetail => *', ExitTransition),


    // ------------ GENERAL TRANSITION ------------
    transition('* <=> *', EntranceTransition)
]);
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterLocation'
})
export class KhanOnlyPipe implements PipeTransform {

  transform(value: any[], args: string): any {
    if (value) {
      switch (args) {
        case 'khan-only':
          return value.filter(item => item.type_en == 'Khan');

        case 'sangkat-only':
          return value.filter(item => item.parent_id).slice(0, 2);

        default:
          return 'input invalid argument';
      }
    }
  }

}

import { EventEmitter, Injectable } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SubmitFailService {
  eventSubmitFormFail = new EventEmitter();

  constructor() { }

  onSubmitFail(err_data: IErrorData, form: FormGroup | FormArray, type: string = FORM_TYPE.FORM_GROUP) {
    console.log('err_data', err_data);
    
    let er_data = err_data.error.errors || [];

    // ================ FORM TYPE: FORM GROUP =================== //
    if (type == FORM_TYPE.FORM_GROUP) {
      for (let item of er_data) {
        form.get(item.param).markAsTouched();

        if (item.msg.includes('required')) {
          form.get(item.param).setErrors({ required: true });

        } else if (item.msg.includes('exist') || item.msg.includes('already') || item.msg.includes('taken')) {
          form.get(item.param).setErrors({ duplicated: true });

        } else if (item.msg.includes('Invalid')) {
          form.get(item.param).setErrors({ invalid: true });

        } else form.get(item.param).setErrors({ error: item.msg });

      }
    }


    //============== FORM TYPE: FORM ARRAY ==================//
    if (type == FORM_TYPE.FORM_ARRAY) {
      for (let item of er_data) {
        let [prnt_ctrl, chl_ctrl] = item.param.split('.');
        let [prnt, ndx] = prnt_ctrl.split('[');
        let index = parseInt(ndx.replace(']', ''));
        
        form.controls[prnt].controls[index].get(chl_ctrl).markAsTouched();
        
        if (item.msg.includes('required')) {
          form.controls[prnt].controls[index].get(chl_ctrl).setErrors({ required: true });
          
        } else if (item.msg.includes('exist')) {
          form.controls[prnt].controls[index].get(chl_ctrl).setErrors({ duplicated: true });
          
        } else if (item.msg.includes('Invalid')) {
          form.controls[prnt].controls[index].get(chl_ctrl).setErrors({ invalid: true });
          
        } else form.get(item.param).setErrors({ invalid: true });

      }
    }


    this.eventSubmitFormFail.emit(true);
  }
}

export interface IErrorData {
  error: {
    data?: any;
    errors: IError[];
    message?: string;
    status?: number;
  }
}

interface IError {
  location: string;
  msg: string;
  param: string;
  value: string;
}

export enum FORM_TYPE {
  FORM_GROUP = 'FORM_GROUP',
  FORM_ARRAY = 'FORM_ARRAY'
}
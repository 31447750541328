<div class="dialog-container">
	<div mat-dialog-title class="title-dialog">
		<h3>{{'table.allocate' | translate}}</h3>

		<div class="msg-container">
			<p>{{'form-field.sign' | translate}} '{{'form-field.required_sign' | translate}}' {{'form-field.required_msg' | translate}}</p>
		</div>
	</div>

	<mat-dialog-content>
		<div class="form-row">
			<div class="col-12">
				<mat-form-field appearance="outline" class="m-form-select show-outline no-prefix-icon">
					<mat-label>{{'form-field.khan' | translate}}</mat-label>
					<mat-select [formControl]="khanControl" (selectionChange)='onChangeKhan(khanControl.value);'>
						<mat-option *ngFor="let khan of khans" [value]="khan._id">
							{{ lang == 'en' ? khan.name_en : khan.name_kh }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
	
			<div class="col-12">
				<mat-form-field appearance="outline" class="m-form-select show-outline no-prefix-icon">
					<mat-label>{{'form-field.sangkat' | translate}}</mat-label>
					<mat-select [formControl]="sangkatControl" (selectionChange)="onChangeLocation()">
						<mat-option *ngFor="let sk of sangkats" [value]="sk._id">
							{{ lang == 'en' ? sk.name_en : sk.name_kh }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
	
			<div class="col-12">
				<mat-form-field appearance="outline" class="m-form-select show-outline no-prefix-icon" showErrorMessage>
					<mat-label>{{'root.menu.role.staff' | translate}}</mat-label>
					<mat-select [formControl]="staffControl">
						<mat-option *ngFor="let staff of staffs" [value]="staff._id">{{ staff.last_name + ' ' + staff.first_name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="col-12">
				<mat-form-field appearance="outline" class="m-form-input show-outline no-prefix-icon" showErrorMessage>
					<mat-label>{{'dialog.confirm.reason' | translate}}</mat-label>
					<textarea class="text-area" cdkTextareaAutosize #autosize="cdkTextareaAutosize" matInput 
						cdkAutosizeMinRows="4" 
						cdkAutosizeMaxRows="9" 
						placeholder="{{'dialog.confirm.reason' | translate}}" 
						[formControl]="descriptionControl">
					</textarea>
				</mat-form-field>
			</div>
		</div>

		<div class="form-row mt-3">
			<div class="col-12">
				<app-upload-multiple-files
					[isChildComp]="true"
					[multiple]="true"
					[allowTypes]="allowFileType" 
					[validTypes]="validFileType" 
					(onUpload)="onChangeFile($event)">
				</app-upload-multiple-files>
			</div>
		</div>
	</mat-dialog-content>

	<mat-dialog-actions align="end">
		<button mat-raised-button class="m-button-normal" matDialogClose>
			<mat-icon class="svg-icon" svgIcon="close_icon"></mat-icon> {{'button.cancel' | translate}}
		</button>
		<button mat-raised-button class="m-button-primary" (click)="onSubmit()">
			<mat-icon class="svg-icon" svgIcon="save_icon"></mat-icon>
			{{'button.approve' | translate}}
		</button>
	</mat-dialog-actions>
</div>
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UtilService } from '../services/util.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private router: Router, private util: UtilService) { }

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const validToken = !!this.util.getToken();
		const routePermissions = next.data.permissions as Array<string>;
		const permissions = this.util.getPermissions();

		if (validToken) {
			if (!routePermissions || routePermissions.length === 0 || routePermissions.filter(p => permissions.includes(p)).length > 0) {
				return true;

			} else {
				this.router.navigateByUrl('login');
				return false;
			}

		} else {
			this.router.navigateByUrl('login');
			return false;
		}

		// if (this.util.getToken() != null) {
		// 	return true;
		// } else {
		// 	this.router.navigateByUrl('login');
		// 	return false;
		// }
	}
}

export enum FILE_TYPE_ENUM {
    ID_CARD = "ID_CARD",
    INVOICE_EDC = "INVOICE_EDC",
    HOME_IMAGE = "HOME_IMAGE",
    SUPPORTING_DOCUMENT = "SUPPORTING_DOCUMENT",

    
    DOC_REQUEST= "DOC_REQUEST",
    DOC_PROCESSING= "DOC_PROCESSING",
    DOC_APPROVE= "DOC_APPROVE",

    RESIDENT_RECORD = "RESIDENT_RECORD"
}
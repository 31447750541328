<div class="dialog-container">
  <div class="dialog-header" *ngIf="data?.title">
    <h3 mat-dialog-title>
      {{ data?.title | translate }} <mat-icon>error_outline</mat-icon>
    </h3>
  </div>

  <div class="dialog-icon" *ngIf="data?.show_icon">
    <ng-container [ngSwitch]="data?.icon_type">
      <!-- ------------ SUCCESS ICON ----------- -->
      <ng-container
        *ngSwitchCase="ICON_TYPE.Success"
        [ngTemplateOutlet]="iconApproveTemplate"
      ></ng-container>
      <!-- ------------ INFO ICON ----------- -->
      <ng-container
        *ngSwitchCase="ICON_TYPE.Info"
        [ngTemplateOutlet]="iconInfoTemplate"
      ></ng-container>
      <!-- ------------ QUESTION ICON ----------- -->
      <ng-container
        *ngSwitchCase="ICON_TYPE.Question"
        [ngTemplateOutlet]="iconQuestionTemplate"
      ></ng-container>
      <!-- ------------ WARN ICON ----------- -->
      <ng-container
        *ngSwitchCase="ICON_TYPE.Warning"
        [ngTemplateOutlet]="iconWarningTemplate"
      ></ng-container>
      <!-- ------------ ERROR ICON ----------- -->
      <ng-container
        *ngSwitchCase="ICON_TYPE.Error"
        [ngTemplateOutlet]="iconErrorTemplate"
      ></ng-container>
    </ng-container>
  </div>

  <!-- ================== normal confirm dialog content ================== -->
  <mat-dialog-content *ngIf="data?.confirm_type == CONFIRM_TYPE_NORMAL">
    <p class="desc">{{ data?.confirm_desc | translate }}</p>

    <ng-container *ngIf="data?.is_file">
      <ng-container *ngTemplateOutlet="uploadFileTemplate"></ng-container>
    </ng-container>
  </mat-dialog-content>

  <!-- ================== cofirm type check house dialog content ================== -->
  <mat-dialog-content
    *ngIf="data?.confirm_type == CONFIRM_TYPE_APRV_CHECK_HOUSE"
  >
    <p class="desc">{{ data?.confirm_desc | translate }}</p>

    <form [formGroup]="checkHouseForm">
      <div class="form-row">
        <div class="col-md-12 space-5">
          <mat-form-field
            appearance="outline"
            class="m-form-select show-outline no-prefix-icon"
            showErrorMessage
          >
            <mat-label>{{ "table.house_category" | translate }}</mat-label>

            <mat-select
              disableRipple
              disableOptionCentering
              formControlName="home_category"
            >
              <div class="select-content" (click)="$event.stopPropagation()">
                <div class="search-field">
                  <mat-option
                    class="user-options"
                    (click)="$event.stopPropagation()"
                  >
                    <mat-form-field appearance="legacy" class="m-form-input">
                      <mat-icon
                        mat-prefix
                        class="m-icon"
                        (click)="$event.stopPropagation()"
                        >search</mat-icon
                      >
                      <input
                        matInput
                        [formControl]="houseCatInputcontrol"
                        (keydown.enter)="
                          $event.stopPropagation();
                          onHouseCatSearchChange(houseCatInputcontrol.value)
                        "
                        (keydown.space)="$event.stopPropagation()"
                        (click)="$event.stopPropagation()"
                      />
                    </mat-form-field>
                  </mat-option>
                  <button
                    mat-raised-button
                    class="search-button m-button-normal"
                    (click)="onHouseCatSearchChange(houseCatInputcontrol.value)"
                  >
                    <mat-icon class="normal-icon">search</mat-icon>
                    {{ "form-field.search" | translate }}
                  </button>
                </div>

                <div
                  class="select-list"
                  (scroll)="onScroll($event, 'house_cat')"
                >
                  <ng-container *ngIf="houseCategory.length > 0">
                    <mat-option
                      class="option-list"
                      *ngFor="let house of houseCategory"
                      [value]="house._id"
                      (click)="onChangeHouseCategory(house)"
                    >
                      <p class="option-name">
                        <span>{{ getHomeCatName(house) }}</span>
                      </p>
                    </mat-option>
                  </ng-container>

                  <ng-container *ngIf="houseCategory.length == 0">
                    <div class="no-data">
                      <img
                        src="../../../../../assets/image/no-data.svg"
                        alt=""
                      />
                      <p>{{ "table.no-data" | translate }}</p>
                    </div>
                  </ng-container>
                </div>
              </div>
            </mat-select>

            <p
              class="selected-option"
              *ngIf="
                selectedHouseCat && checkHouseForm.get('home_category').value
              "
            >
              <span>{{ getHomeCatName(selectedHouseCat) }}</span>
            </p>
          </mat-form-field>
        </div>

        <div class="col-md-12 space-5">
          <mat-form-field
            appearance="outline"
            class="m-form-select show-outline no-prefix-icon"
            showErrorMessage
          >
            <mat-label>{{ "table.house_type" | translate }}</mat-label>

            <mat-select
              disableRipple
              disableOptionCentering
              formControlName="home_type"
            >
              <div class="select-content" (click)="$event.stopPropagation()">
                <div class="search-field">
                  <mat-option
                    class="user-options"
                    (click)="$event.stopPropagation()"
                  >
                    <mat-form-field appearance="legacy" class="m-form-input">
                      <mat-icon
                        mat-prefix
                        class="m-icon"
                        (click)="$event.stopPropagation()"
                        >search</mat-icon
                      >
                      <input
                        matInput
                        [formControl]="houseTypesInputControl"
                        (keydown.enter)="
                          $event.stopPropagation();
                          onHouseTypeSearchChange(houseTypesInputControl.value)
                        "
                        (keydown.space)="$event.stopPropagation()"
                        (click)="$event.stopPropagation()"
                      />
                    </mat-form-field>
                  </mat-option>
                  <button
                    mat-raised-button
                    class="search-button m-button-normal"
                    (click)="
                      onHouseTypeSearchChange(houseTypesInputControl.value)
                    "
                  >
                    <mat-icon class="normal-icon">search</mat-icon>
                    {{ "form-field.search" | translate }}
                  </button>
                </div>

                <div
                  class="select-list"
                  (scroll)="onScroll($event, 'house_type')"
                >
                  <ng-container *ngIf="houseType.length > 0">
                    <mat-option
                      class="option-list"
                      *ngFor="let house of houseType"
                      [value]="house._id"
                      (click)="
                        selectedHouseCat = house.home_category;
                        selectedHouseType = house;
                        onSelectHomeType()
                      "
                    >
                      <p class="option-name">
                        <span>{{ getHomeTypeName(house) }}</span>
                      </p>
                    </mat-option>
                  </ng-container>

                  <ng-container *ngIf="houseType.length == 0">
                    <div class="no-data">
                      <img
                        src="../../../../../assets/image/no-data.svg"
                        alt=""
                      />
                      <p>{{ "table.no-data" | translate }}</p>
                    </div>
                  </ng-container>
                </div>
              </div>
            </mat-select>

            <p
              class="selected-option"
              *ngIf="selectedHouseType && checkHouseForm.get('home_type').value"
            >
              <span>{{ getHomeTypeName(selectedHouseType) }}</span>
            </p>
          </mat-form-field>
        </div>

        <div class="col-md-12 space-5">
          <mat-form-field
            appearance="outline"
            class="m-form-select show-outline no-prefix-icon"
            showErrorMessage
          >
            <mat-label>{{ "table.number_of_room" | translate }}</mat-label>
            <input
              matInput
              numberInput
              [cashValidate]="true"
              formControlName="number_of_rooms"
            />
          </mat-form-field>
        </div>

        <div class="col-md-12 space-5">
          <mat-form-field
            appearance="outline"
            class="m-form-select show-outline no-prefix-icon"
          >
            <mat-label
              >{{ "form-field.total_amount" | translate }} ({{
                "currency.dollar" | translate
              }})</mat-label
            >
            <input matInput type="text" formControlName="total_amount" />
          </mat-form-field>
        </div>

        <div class="col-md-12 space-5">
          <mat-form-field
            appearance="outline"
            class="m-form-date show-outline no-prefix-icon"
            showErrorMessage
          >
            <mat-label>{{ "table.effective_date" | translate }}</mat-label>
            <input
              matInput
              (click)="effective_date.open()"
              formControlName="effective_date"
              [matDatepicker]="effective_date"
              monthYearFormat
            />
            <mat-datepicker
              #effective_date
              panelClass="month-year-picker"
              startView="multi-year"
              (monthSelected)="setMonthAndYear($event, effective_date)"
            ></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </form>

    <!-- <ng-container *ngIf="data?.is_file">
			<ng-container *ngTemplateOutlet="uploadFileTemplate;"></ng-container>
		</ng-container> -->
  </mat-dialog-content>

  <!-- ================== normal reject with just field description ================== -->
  <mat-dialog-content *ngIf="!data?.confirm_type">
    <p class="desc" *ngIf="!data?.map_alert">
      {{ data?.confirm_desc | translate }}
    </p>
    <form [formGroup]="formDataGroup" #form="ngForm" *ngIf="data?.is_reject">
      <div class="form-row">
        <div class="col-md-12" *ngIf="data?.hasDate">
          <mat-form-field
            appearance="outline"
            class="m-form-date show-outline no-prefix-icon"
            showErrorMessage
          >
            <mat-label>{{ "table.date" | translate }}</mat-label>
            <input
              matInput
              (click)="start_date_picker.open()"
              [matDatepicker]="start_date_picker"
              [max]="maxDate"
              formControlName="date"
            />
            <mat-datepicker #start_date_picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-12">
          <mat-form-field
            appearance="outline"
            class="m-form-input show-outline no-prefix-icon"
            showErrorMessage
          >
            <mat-label>{{ "dialog.confirm.reason" | translate }}</mat-label>
            <textarea
              class="text-area"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="3"
              cdkAutosizeMaxRows="9"
              matInput
              [ngClass]="{
                'is-invalid':
                  (formDataGroup.get('description')?.invalid &&
                    formDataGroup.get('description')?.touched) ||
                  (formDataGroup.get('description')?.invalid && form.submitted)
              }"
              placeholder="{{ 'dialog.confirm.reason' | translate }}"
              required
              formControlName="description"
            >
            </textarea>
          </mat-form-field>
        </div>

        <div *ngIf="data?.is_file" class="col-md-12 mb-12">
          <ng-container *ngTemplateOutlet="uploadFileTemplate"></ng-container>
        </div>
      </div>
    </form>

    <div class="map-msg" *ngIf="data?.map_alert">
      <p>{{ "message.drag_drop_map_pt1" | translate }}</p>
      <img
        src="../../../../../assets/image/main/map-marker.svg"
        alt="map_marker"
      />
      <p>{{ "message.drag_drop_map_pt2" | translate }}</p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="center" *ngIf="!data?.is_no_action">
    <!-- Close Button -->
    <button mat-raised-button class="m-button-normal" matDialogClose>
      <mat-icon class="svg-icon" svgIcon="close_icon"></mat-icon>
      {{
        data?.is_logout
          ? ("button.close" | translate)
          : ("button.cancel" | translate)
      }}
    </button>

    <!-- Button(s) for normal confirm -->
    <ng-container
      *ngIf="
        [CONFIRM_TYPE_NORMAL, CONFIRM_TYPE_APRV_CHECK_HOUSE].includes(
          data?.confirm_type
        )
      "
    >
      <button
        mat-raised-button
        class="m-button-primary"
        [ngClass]="{ rejected: data?.is_reject, approved: data?.is_approve }"
        (click)="onCloseDialog()"
      >
        {{ "button.confirm" | translate }}
        <mat-icon *ngIf="data?.is_logout" class="ml-1">arrow_forward</mat-icon>
        <mat-icon
          *ngIf="!data?.is_logout"
          class="svg-icon"
          svgIcon="done_icon"
        ></mat-icon>
      </button>
    </ng-container>

    <!-- Button(s) for when confirm_type is undefined -->
    <ng-container *ngIf="!data?.confirm_type">
      <button
        mat-raised-button
        color="warn"
        *ngIf="data?.is_reject"
        (click)="onCloseDialog()"
        [disabled]="formDataGroup.get('description')?.invalid"
        class="m-button-primary rejected"
        [ngClass]="{
          reject: formDataGroup.get('description')?.valid,
          'btn-disabled': formDataGroup.get('description')?.invalid
        }"
      >
        {{ "button.confirm" | translate }}
        <mat-icon class="svg-icon" svgIcon="save_icon"></mat-icon>
      </button>
    </ng-container>

    <!-- <button *ngIf="data?.is_print_invoice" mat-raised-button class="m-button-primary approved" (click)="onPrintInvoice()">
      {{ "root.menu_left.print_invoice" | translate }}
      <mat-icon class="normal-icon">print</mat-icon>
    </button> -->

  </mat-dialog-actions>
</div>

<ng-template #uploadFileTemplate>
  <fieldset>
    <legend>{{ "file_type.support_doc" | translate }}</legend>
    <app-upload-multiple-files
      [isChildComp]="true"
      [multiple]="true"
      [allowTypes]="allowFileType"
      [validTypes]="validFileType"
      (onUpload)="onChangeFile($event)"
    >
    </app-upload-multiple-files>
  </fieldset>
</ng-template>

<!-- ========================== ICON TEMPLATE ========================= -->
<!-- ================================================================== -->

<ng-template #iconApproveTemplate>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 416 416"
  >
    <g transform="translate(-48 -48)">
      <path
        d="M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z"
        fill="none"
        stroke="#e4f4da"
        stroke-miterlimit="10"
        stroke-width="32"
        class="svg-success-1"
      ></path>
      <path
        d="M352,176,217.6,336,160,272"
        fill="none"
        stroke="#a5dc86"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        class="svg-success-2"
      ></path>
    </g>
  </svg>
</ng-template>

<ng-template #iconWarningTemplate>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 416 416"
  >
    <g transform="translate(14 142.341)">
      <path
        d="M12,7v93.66"
        transform="translate(182 -29.648)"
        fill="none"
        stroke="#f8bb86"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        class="svg-warning-1"
      ></path>
      <path
        d="M12,17.172,12.156,17"
        transform="translate(182.018 144.669)"
        fill="none"
        stroke="#f8bb86"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        class="svg-warning-2"
      ></path>
      <path
        d="M194,386c106.038,0,192-85.962,192-192S300.038,2,194,2,2,87.961,2,194,87.961,386,194,386Z"
        transform="translate(0 -128.341)"
        fill="none"
        stroke="#facea8"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        class="svg-warning-3"
      ></path>
    </g>
  </svg>
</ng-template>

<ng-template #iconInfoTemplate>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 416 416"
  >
    <g transform="translate(11 136.341)">
      <path
        d="M194,386c106.038,0,192-85.962,192-192S300.038,2,194,2,2,87.961,2,194,87.961,386,194,386Z"
        transform="translate(3 -122.341)"
        fill="none"
        stroke="#c9dae1"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        class="svg-info-1"
      ></path>
    </g>
    <path
      d="M64.819,77.612,9.859,84.5,7.891,93.62l10.8,1.992c7.056,1.68,8.448,4.224,6.912,11.256L7.891,190.1c-4.656,21.528,2.52,31.656,19.392,31.656,13.08,0,28.272-6.048,35.16-14.352l2.112-9.984c-4.8,4.224-11.808,5.9-16.464,5.9-6.6,0-9-4.632-7.3-12.792ZM66.5,27.5a24,24,0,1,1-24-24A24,24,0,0,1,66.5,27.5Z"
      transform="translate(168.5 89.372)"
      fill="#87adbd"
      class="svg-info-2"
    ></path>
  </svg>
</ng-template>

<ng-template #iconErrorTemplate>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 416 416"
  >
    <g
      id="warning-circled-outline-svgrepo-com"
      transform="translate(14 142.341)"
    >
      <path
        d="M12,7V215.532"
        transform="translate(106.838 -4.533) rotate(-45)"
        fill="none"
        stroke="#f27474"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        class="svg-error-1"
      ></path>
      <path
        d="M12,7V213.109"
        transform="translate(264.192 -21.503) rotate(45)"
        fill="none"
        stroke="#f27474"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        class="svg-error-2"
      ></path>
      <path
        d="M194,386c106.038,0,192-85.962,192-192S300.038,2,194,2,2,87.961,2,194,87.961,386,194,386Z"
        transform="translate(0 -128.341)"
        fill="none"
        stroke="#f27474"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="32"
        class="svg-error-3"
      ></path>
    </g>
  </svg>
</ng-template>

<ng-template #iconQuestionTemplate>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 17 17"
  >
    <path
      d="M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z"
      transform="translate(0.5 0.5)"
      fill="none"
      stroke="#463d97"
      stroke-linecap="round"
      stroke-width="1"
      class="svg-question-1"
    ></path>
    <path
      d="M8.5,9.5v-1L9.914,7.086A2,2,0,0,0,10.5,5.672V5.5a1.618,1.618,0,0,0-.894-1.447l-.211-.106a2,2,0,0,0-1.789,0L7.5,4a1.809,1.809,0,0,0-1,1.618V6.5"
      fill="none"
      stroke="rgba(70,61,151,0.8)"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
      fill-rule="evenodd"
      class="svg-question-2"
    ></path>
    <path
      d="M1,0A1,1,0,1,1,0,1,1,1,0,0,1,1,0Z"
      transform="translate(7.5 11.5)"
      fill="rgba(70,61,151,0.8)"
      class="svg-question-3"
    ></path>
  </svg>
</ng-template>

import { EventEmitter, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { LocalizeSnackbarComponent } from '../modules/shared/localize-snackbar/localize-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isLoading = false;
  loadingEventEmitter: EventEmitter<boolean> = new EventEmitter();
  counter = 0;

  constructor(
    private snackbar: MatSnackBar
  ) { }

  setLoading(isLoading: boolean) {
    if (isLoading) {
      this.counter++;
    } else {
      if (this.counter > 0) {
        this.counter--;
      }
    }
    isLoading = this.counter > 0;
    this.loadingEventEmitter.emit(isLoading);
  }

  openLoadingSnackbar() {
    this.snackbar.openFromComponent(LocalizeSnackbarComponent, {
      panelClass: 'panelClass-loading',
      data: {
        msg: 'root.loading',
        is_loading: true
      }
    })
  }

  closeLoadingSnackbar() {
    this.snackbar.dismiss();
  }

  forceStop() {
    this.counter = 0;
    this.isLoading = false;
    this.loadingEventEmitter.emit(this.isLoading);
  }
}

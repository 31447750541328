import { ICommonResponse } from '../models/response/common-response';
import { IStreet } from '../models/interface/int-street';
import { IResponseWithPagination } from '../models/response/response-with-pagination';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequestService } from './request.service';
import { Injectable } from '@angular/core';
import { IStreetType } from '../models/interface/int-street-type';
import { IStreetTurn } from '../models/interface/int-street-turn';
import { SubmitFailService } from './submit-fail.service';


interface StreetArg {
	page: number;
	limit: number;
	locations?: string | string[];
	searchQuery?: string;
}

@Injectable({
	providedIn: 'root'
})

export class StreetService extends SubmitFailService {

	constructor(
		private requestService: RequestService
	) { super(); }

	getAll(data: StreetArg): Observable<IResponseWithPagination<IStreet>> {
		const url = environment.baseUrl + '/back-office/streets';

		if (data.locations?.length < 0 || !data.locations) {
			data.locations = undefined;
		}

		return this.requestService.getJSON(url, data);
	}

	getById(id: string): Observable<ICommonResponse<IStreet>> {
		const url = environment.baseUrl + '/back-office/streets/' + id;
		return this.requestService.getJSON(url);
	}

	getStreetsBySangkatID(id: string): Observable<ICommonResponse<IStreet[]>> {
		const url = environment.baseUrl + '/back-office/streets/locations/' + id;
		return this.requestService.getJSON(url);
	}

	getByName(name: string): Observable<ICommonResponse<IStreet>> {
		const url = environment.baseUrl + '/back-office/streets/by-name/' + name;
		return this.requestService.getJSON(url);
	}

	create(data: any): Observable<ICommonResponse<IStreet>> {
		const url = environment.baseUrl + '/back-office/streets/create';
		return this.requestService.postJSON(url, data);
	}

	update(id: string, data: any): Observable<ICommonResponse<IStreet>> {
		const url = environment.baseUrl + '/back-office/streets/' + id + '/update';
		return this.requestService.putJSON(url, data);
	}

	operatorUpdateStreet(id: string, data: any): Observable<ICommonResponse<IStreet>> {
		const url = environment.baseUrl + '/back-office/streets/' + id + '/update';
		return this.requestService.putJSON(url, data);
	}

	addLocationToStreet(id: string, locations: string[]): Observable<ICommonResponse<IStreet>> {
		const url = environment.baseUrl + '/back-office/streets/' + id + '/add-locations';
		return this.requestService.putJSON(url, { locations });
	}

	getStreetTypes(page: number, limit: number): Observable<IResponseWithPagination<IStreetType>> {
		const url = environment.baseUrl + '/back-office/street-types';
		return this.requestService.getJSON(url, { page, limit });
	}

	getStreetTypesByID(id: string): Observable<ICommonResponse<IStreetType>> {
		const url = environment.baseUrl + `/back-office/street-types/${id}`;
		return this.requestService.getJSON(url);
	}

	getStreetTurns(page: number, limit: number): Observable<IResponseWithPagination<IStreetTurn>> {
		const url = environment.baseUrl + '/back-office/turns';
		return this.requestService.getJSON(url, { page, limit });
	}

	getStreetTurnsByID(id: string): Observable<ICommonResponse<IStreetTurn>> {
		const url = environment.baseUrl + `/back-office/turns/${id}`;
		return this.requestService.getJSON(url);
	}
}

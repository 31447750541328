import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { RequestService } from './request.service';

@Injectable({
	providedIn: 'root'
})
export class DocumentService {

	constructor(
		private requestService: RequestService,
		private localStorageService: LocalStorageService,
		private http: HttpClient
	) { }

	getFileMetaData(file_url: string): Observable<any> {
		const url = environment.baseUrl + `${file_url}` + '/metadata';
		return this.requestService.getJSON<any>(url, {});
	}

	getDocument(file_url: string) {
		const path = `${environment.baseUrl}${file_url}`;
		return this.http.get(path, { headers: this.getHeader(), responseType: 'arraybuffer' });
	}

	getImageDoc(file_url: string) {
		const path = `${environment.baseUrl}${file_url}`;
		return this.http.get(path, { headers: this.getHeader(), responseType: 'blob' });
	}

	public getHeader() {
		const token = this.localStorageService.get('token');
		return new HttpHeaders({
			'Content-Type': 'application/json',
			'authorization': `Bearer ${token} `
		});
	}
}

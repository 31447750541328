import { ICommonResponse } from '../models/response/common-response';
import { IStaff } from '../models/interface/int-staff';
import { IResponseWithPagination } from '../models/response/response-with-pagination';
import { RequestService } from './request.service';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ILogin } from '../models/interface/int-login';
import { SubmitFailService } from './submit-fail.service';
import { SessionStorageService } from './session-storage.service';
import { SESSION_ENUM } from '../models/enum/session-storage.enum';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { LOCAL_STORAGE_ENUM } from '../models/enum/local-storage.enum';

@Injectable({
	providedIn: 'root'
})
export class StaffService extends SubmitFailService {
	private _logout = new BehaviorSubject<boolean>(false);
	private _login = new BehaviorSubject<boolean>(false);

	public isLogout = this._logout.asObservable();
	public isLogin = this._login.asObservable();

	constructor(
		private http: HttpClient,
		private ss: SessionStorageService,
		private localStorageService: LocalStorageService,
		private requestService: RequestService
	) {
		super();
	}

	onLogOut(val: boolean = true) {
		this._logout.next(val);
	}

	onLogIn(val: boolean = true) {
		this._login.next(val);
	}


	// get all user to display on table
	getAllStaff(searchQuery: string, limit: number, page: number, location?: string, role?: string): Observable<IResponseWithPagination<IStaff>> {
		const url = environment.baseUrl + '/back-office/staffs';
		return this.requestService.getJSON(url, Object.assign({ searchQuery, limit, page, location, role }));
	}

	getStaffByID(id: string): Observable<ICommonResponse<IStaff>> {
		const url = environment.baseUrl + '/back-office/staffs/' + id;
		return this.requestService.getJSON(url);
	}

	// get all role
	// getAllRole() {
	//   const url = environment.baseUrl + '/roles';
	//   return this.requestService.getJSON(url).pipe(map((res: any) => res.data));
	// }

	// get user by location
	// getUserByLocation(lid: string, searchQuery: string = '') {
	//   const url = environment.baseUrl + '/back-office/staffs/get-by/' + lid;
	//   return this.requestService.getJSON(url, {searchQuery}).pipe(map((res: any) => res.data));
	// }

	// get all user with pagination
	// getAllUserTMP(limit: number, page: number) {
	//   const url = environment.baseUrl + '/back-office/staffs';
	//   return this.requestService.getJSON(url, Object.assign(limit, page)).pipe(map((res: any) => res.data));
	// }

	// change staff status
	// changeStaffStatus(status: boolean, sid: string) {
	//   const url = environment.baseUrl + '/back-office/staffs/' + sid + '/change-status';
	//   return this.requestService.putJSON(url, {status}).pipe(map((res: any) => res.data));
	// }

	// get user by id
	// getUserbyID(id: string): Observable<updateUserForm> {
	//   const url = environment.baseUrl + '/back-office/staffs/' + id;
	//   return this.requestService.getJSON<updateUserForm>(url).pipe(map((res: any) => res.data));
	// }

	// update user infomation
	updateUserInfo(data: any, id: string): Observable<ICommonResponse<IStaff>> {
		const url = environment.baseUrl + '/back-office/staffs/' + id + '/update';
		return this.requestService.putJSON(url, data);
	}


	// update user infomation
	// updateUserInfo(user: User) {
	//   const url = environment.baseUrl + '/back-office/staffs/' + user._id + '/update';
	//   if ( user.username.length === 0 ) {
	//     user.username = undefined;
	//   }

	//   if (user.phone_number.length === 0 ) {
	//     user.phone_number = undefined;
	//   }

	//   user._id = undefined;

	//   return this.requestService.putJSON<BaseResponse>(url, user);
	// }


	// create user
	createStaff(inputData: any): Observable<ICommonResponse<IStaff>> {
		const url = environment.baseUrl + '/back-office/staffs/create/';
		if (inputData.locations.length === 0) {
			inputData.locations = undefined;
		}

		if (!inputData.company) {
			inputData.company = undefined;
		}
		return this.requestService.postJSON(url, inputData);
	}

	// display image
	getImage(imageUrl): Observable<Blob> {
		return this.http.get(imageUrl, { responseType: 'blob' });
	}

	//  login service
	login(inputData: any): Observable<ICommonResponse<ILogin>> {
		const url = environment.baseUrl + '/back-office/staffs/login';
		return this.requestService.postJSON(url, inputData);
	}

	// change user status
	changeStaffStatus(UiD: string, status: number): Observable<ICommonResponse<IStaff>> {
		const url = environment.baseUrl + '/back-office/staffs/' + UiD + '/change-status';
		return this.requestService.putJSON(url, { status });
	}

	// change staff password
	changePass(staffID: string, inputData: any): Observable<ICommonResponse<IStaff>> {
		const url = environment.baseUrl + '/back-office/staffs/' + staffID + '/change-password';
		return this.requestService.postJSON(url, inputData);
	}

	resetPassword(id: string, password: string, confirmedPassword: string): Observable<ICommonResponse<IStaff>> {
		const url = environment.baseUrl + '/back-office/staffs/' + id + '/reset-password';
		let data = {
			password: password,
			confirmedPassword: confirmedPassword
		}
		return this.requestService.postJSON(url, data);
	}

	getNewToken() {
		const url = environment.baseUrl + '/back-office/staffs/reset-token';
		return this.requestService.postJSON(url, { refresh_token: this.requestService.getRefreshToken() })
			.pipe(map((res: any) => {
				if (res.status === 1) {
					this.ss.set(SESSION_ENUM.LANG, this.requestService.getDefaultLang());
					// this.ss.set(SESSION_ENUM.TOKEN, res.data.access_token);
					// this.ss.set(SESSION_ENUM.REFRESH_TOKEN, res.data.refresh_token);
					// this.ss.set(SESSION_ENUM.EXPIRED_AT, res.data.expiredAt.toString());

					this.localStorageService.setEncryption(LOCAL_STORAGE_ENUM.TOKEN, res.data.access_token);
					this.localStorageService.setEncryption(LOCAL_STORAGE_ENUM.REFRESH_TOKEN, res.data.refresh_token);
					this.localStorageService.setEncryption(LOCAL_STORAGE_ENUM.EXPIRED_AT, res.data.expiredAt.toString());
				}
				return res;
			}));
	}
}

import { SESSION_ENUM } from 'src/app/models/enum/session-storage.enum';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { StaffService } from 'src/app/services/staff.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { CustomValidators } from 'src/app/helpers/validators/validators';
import { CustomerService } from 'src/app/services/customer.service';
import { strongPassword } from 'src/app/helpers/validators/strong-validators';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

	changePassForm: FormGroup;
	staffID: string;
	loading = false;
	hide: boolean[] = [true, true, true];

	constructor(
		private router: Router,
		private staffService: StaffService,
		private dialogRef: MatDialogRef<ChangePasswordComponent>,
		private sessionStorageService: SessionStorageService,
		private customerService: CustomerService,
		@Inject(MAT_DIALOG_DATA) private data: {
			is_user: boolean,
			u_id: string
		}
	) {
		this.changePassForm = new FormGroup({
			old_password: new FormControl('', Validators.required),
			new_password: new FormControl('', [Validators.required, Validators.pattern(strongPassword)]),
			confirm_new_password: new FormControl('', [Validators.required, Validators.pattern(strongPassword)])
		}, CustomValidators.mustMatch('new_password', 'confirm_new_password'));
	}

	ngOnInit(): void {
		this.staffID = this.sessionStorageService.get(SESSION_ENUM.USERID);
	}

	onSubmit() {
		if (this.changePassForm.valid) {
			if (!this.data?.is_user) {
				this.staffService.changePass(this.staffID, this.changePassForm.value).subscribe(
					res => {
						if (res.status == 1) {
							this.dialogRef.close(true);
							sessionStorage.removeItem('token');
							this.router.navigate(['login']);
						}

					}, err => this.staffService.onSubmitFail(err, this.changePassForm)
				);

				
			} else {
				this.customerService.changePassword(this.data.u_id, this.changePassForm.value).subscribe(
					res => {
						if (res.status == 1) this.dialogRef.close(true);

					}, err => this.customerService.onSubmitFail(err, this.changePassForm)
				)
			}


		} else {
			return -1;
		}

	}
}

import { IStaff } from '../../../models/interface/int-staff';
import { IKhan } from '../../../models/interface/loc-khan';
import { RequestUpdateHouseService } from 'src/app/services/request-update-house.service';
import { StaffService } from 'src/app/services/staff.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KhanServiceService } from 'src/app/services/khan-service.service';
import { ILocLocation } from 'src/app/models/interface/loc-location';
import { InvoiceService } from 'src/app/services/invoice.service';
import { PostRecycleService } from 'src/app/services/post-recycle.service';
import { PostsService } from 'src/app/services/posts.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { SESSION_ENUM } from 'src/app/models/enum/session-storage.enum';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { GlobalConfirmDialogComponent } from '../global-confirm-dialog/global-confirm-dialog.component';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LOCAL_STORAGE_ENUM } from 'src/app/models/enum/local-storage.enum';
import { Subject, takeUntil } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';

@Component({
	selector: 'app-dialog-assign-allocate',
	templateUrl: './dialog-assign-allocate.component.html',
	styleUrls: ['./dialog-assign-allocate.component.scss']
})
export class DialogAssignAllocateComponent implements OnInit {

	lang: string;
	tabName: string;
	khans: IKhan[] = [];
	sangkats: ILocLocation[] = [];
	staffs: IStaff[] = [];
	current_login_user_id: string;
	filesSubmit: any[] = [];
	validFileType = 'image/*, application/pdf';
	allowFileType = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

	public khanControl = new FormControl('', Validators.required);
	public sangkatControl = new FormControl('', Validators.required);
	public staffControl = new FormControl('', Validators.required);
	public descriptionControl = new FormControl('', Validators.required);

	private _destroyed = new Subject<void>();
	constructor(
		private staffService: StaffService,
		private khanService: KhanServiceService,
		private RUHService: RequestUpdateHouseService,
		private invoiceService: InvoiceService,
		private recycleService: PostRecycleService,
		private postService: PostsService,
		private translate: TranslateService,
		private dialog: MatDialog,
		private util: UtilService,
		private sessionStorageService: SessionStorageService,
		private localStorageService: LocalStorageService,
		private dialogRef: MatDialogRef<DialogAssignAllocateComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			location: ILocLocation,
			isRequest: boolean,
			isCheckingHouse: boolean,
			isPostSolidWaste: boolean,
			isRecycle: boolean,
			_id: string
		}
	) {
		this.translate.onLangChange.pipe(takeUntil(this._destroyed)).subscribe((event: LangChangeEvent) => {
			this.lang = event.lang;
		})

		this.current_login_user_id = this.sessionStorageService.get(SESSION_ENUM.USERID);

	}

	ngOnInit(): void {
		if (this.lang == '' || !this.lang) {
			this.lang = this.localStorageService.get(LOCAL_STORAGE_ENUM.lang);
		}

		this.getAllKhan();

		if (this.data.location) {
			if (this.data.location.parent_id) {
				this.khanControl.setValue(this.data.location.parent_id._id);
				this.getSangkatByKhanID(this.khanControl.value);
				// this.khanControl.disable();

				this.sangkatControl.setValue(this.data.location._id);
				this.getStaffByLocation(this.sangkatControl.value);
				// this.sangkatControl.disable();

			} else {
				this.khanControl.setValue(this.data.location._id);
				this.getSangkatByKhanID(this.khanControl.value);
				// this.khanControl.disable();
			}
		}
	}

	get isDisable() {
		if (this.sangkatControl.value && this.staffControl.valid && this.descriptionControl.valid) return false;
		else return true;
	}

	getStaffByLocation(lid: string) {
		this.staffService.getAllStaff('', 100, 1, lid).subscribe(res => {
			let data = res.data.data.filter(item => item._id != this.current_login_user_id);
			this.staffs = data;
		})
	}

	getSangkatByKhanID(kid: string) {
		this.khanService.getSangKatByKhanID(kid).subscribe((res) => {
			this.sangkats = res.data.sangkats;
		});
	}

	onChangeKhan(kid: string) {
		this.sangkatControl.reset();
		this.staffControl.reset();
		// this.sangkatControl.enable();
		// this.staffControl.enable();
		this.staffs = [];

		this.getSangkatByKhanID(kid);
		this.getStaffByLocation(kid);
	}

	onChangeLocation() {
		this.staffControl.reset();
		this.staffs = [];

		this.getStaffByLocation(this.sangkatControl.value);
	}

	getAllKhan() {
		this.khanService.getAllKhan().subscribe(
			(res) => this.khans = res.data,
			(err) => console.error(err)
		);
	}

	get dataJson() {
		let data;

		if (this.staffControl.value) {
			return data = {
				isAssign: true,
				data: {
					staff: this.staffControl.value,
					description: this.descriptionControl.value
				}
			}

		} else {
			return data = {
				isAssign: false,
				data: {
					location: this.sangkatControl.value
				}
			}
		}
	}

	onSubmit() {
		if (this.isDisable) this.alertCannotSubmit();
		else {
			if (this.data.isRequest) this.onSubmitRequest();

			if (this.data.isCheckingHouse) this.onSubmitCheckingHouse();

			if (this.data.isRecycle) this.onSubmitRecycle();

			if (this.data.isPostSolidWaste) this.onSubmitPostSolidWaste();
		}
	}

	alertCannotSubmit() {
		let msg = this.translate.instant('message.not_complete_yet');

		const dialogRef = this.dialog.open(GlobalConfirmDialogComponent, {
			disableClose: true,
			autoFocus: false,
			width: '500px',
			data: {
				title: this.translate.instant('dialog.confirm.alert'),
				confirm_desc: msg
			}
		});

		dialogRef.afterClosed().subscribe(res => {
			this.staffControl.markAsTouched();
			if (!this.staffControl.value) this.staffControl.setValue('');

			this.descriptionControl.markAllAsTouched();
			if (!this.descriptionControl.value) this.descriptionControl.setValue('');
		});
	}

	onSubmitRequest() {
		if (this.dataJson.isAssign) {
			this.RUHService.assignRequest(this.dataJson.data, this.data._id, this.filesSubmit).subscribe(res => {
				if (res.status == 1) this.dialogRef.close(res.data);
			})

		} else {
			this.RUHService.allocateRequest(this.dataJson.data, this.data._id).subscribe(res => {
				if (res.status == 1) this.dialogRef.close(res.data);
			})
		}
	}

	onSubmitCheckingHouse() {
		if (this.dataJson.isAssign) {
			this.invoiceService.assignCheckingHouse(this.dataJson.data, this.data._id, this.filesSubmit).subscribe(res => {
				if (res.status == 1) this.dialogRef.close(res.data);
			})
		}
	}

	onSubmitRecycle() {
		if (this.dataJson.isAssign) {
			this.recycleService.assignPostRecycle(this.dataJson.data, this.data._id, this.filesSubmit).subscribe(res => {
				if (res.status == 1) this.dialogRef.close(res.data);
			})
		}
	}

	onSubmitPostSolidWaste() {
		if (this.dataJson.isAssign) {
			this.postService.assignPostSolidWaste(this.dataJson.data, this.data._id, this.filesSubmit).subscribe(res => {
				if (res.status == 1) this.dialogRef.close(res.data);
			})
		}
	}

	onChangeFile(listFiles: any[]) {
		this.filesSubmit = listFiles;
	}
}



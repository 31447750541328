import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ICommonResponse } from "../models/response/common-response";
import { IResponseWithPagination } from "../models/response/response-with-pagination";
import { RequestService } from "./request.service";
// import { map, mergeMap, toArray } from 'rxjs/operators';
import { DatePipe } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, map } from "rxjs";
import { ISumInvByBank } from "../models/interface/int-bank-summary-invoice";
import { IComment } from "../models/interface/int-comment";
import { IInvoiceSolidWaste } from "../models/interface/int-invoice-solid-waste";
import { IPostCheckingHouse } from "../models/interface/post-checking-house";
import { ISummaryCheckingHouse } from "../models/interface/summary-checking-house";
import { LocalStorageService } from "./local-storage.service";
import { SubmitFailService } from "./submit-fail.service";

export enum INVOICE_ENTPOINT {
  EXPORT_INV_SLW = "/back-office/invoices/solid-wastes/as-excel",
  EXPORT_INV_SLW_AS_CSV = "/back-office/invoices/solid-wastes/as-csv",
  EXPORT_RPT_TOTAL_SALES = "/back-office/report/invoice-by-month",
  EXPORT_RPT_TOTAL_DEBT = "/back-office/report/debt-by-month",
}

@Injectable({
  providedIn: "root",
})
export class InvoiceService extends SubmitFailService {
  constructor(
    private requestService: RequestService,
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    super();
  }

  // get all invoice with checking house
  getCheckingHouse(
    data: any
  ): Observable<IResponseWithPagination<IPostCheckingHouse>> {
    const url = environment.baseUrl + "/back-office/invoices/checking-houses/";
    return this.requestService.getJSON(url, data);
  }

  getSummaryCheckingHouse(data: any): Observable<ICommonResponse<ISummaryCheckingHouse>> {
    const url = environment.baseUrl + "/back-office/summary/checking-houses";
    return this.requestService.getJSON(url, data);
  }

  // get one invoice of checking houser which is require and id of that invoice
  getCheckingHouseByID(
    id: string
  ): Observable<ICommonResponse<IPostCheckingHouse>> {
    const url =
      environment.baseUrl + "/back-office/invoices/checking-houses/" + id;
    return this.requestService.getJSON(url);
  }

  getFileMetaData(file_url: string): Observable<any> {
    const url = environment.baseUrl + `${file_url}` + "/metadata";
    return this.requestService.getJSON<any>(url, {});
  }

  assignCheckingHouse(
    data: any,
    _id: string,
    files: any[]
  ): Observable<ICommonResponse<IPostCheckingHouse>> {
    const url =
      environment.baseUrl +
      "/back-office/invoices/checking-houses/" +
      _id +
      "/assign";
    const formData = new FormData();
    for (let item in data) {
      formData.append(item, data[item]);
    }

    if (files.length > 0) {
      for (let [index, file] of files.entries()) {
        formData.append(`files[${index}]`, file);
      }
    }
    return this.requestService.putJSON<any>(url, formData);
  }

  rejectCheckingHouse(
    _id: string,
    description: string,
    files: any[]
  ): Observable<ICommonResponse<IPostCheckingHouse>> {
    const url =
      environment.baseUrl +
      "/back-office/invoices/checking-houses/" +
      _id +
      "/reject";
    const formData = new FormData();
    formData.append("description", description);

    if (files.length > 0) {
      for (let [index, file] of files.entries()) {
        formData.append(`files[${index}]`, file);
      }
    }

    return this.requestService.putJSON<any>(url, formData);
  }

  // change status of checking house post
  // changeStatus(checkID: string, status: string): Observable<CheckingHouseUpdateResponse> {
  //   const url = environment.baseUrl + '/back-office/invoices/checking-houses/' + checkID + '/change-status';
  //   return this.requestService.putJSON<CheckingHouseUpdateResponse>(url, { status });
  // }

  // set date for checking house
  setDate(
    id: string,
    date: Date
  ): Observable<ICommonResponse<IPostCheckingHouse>> {
    const url =
      environment.baseUrl +
      "/back-office/invoices/checking-houses/" +
      id +
      "/set-date-for-checking-house";
    const datePipe = new DatePipe("en-US");
    const checkedAt = datePipe.transform(date, "yyyy-MM-dd");

    return this.requestService.putJSON(url, { checkedAt });
  }

  // set effective date for checking house
  setEffectiveDate(
    id: string,
    effective_date: string
  ): Observable<ICommonResponse<IPostCheckingHouse>> {
    const url =
      environment.baseUrl +
      "/back-office/invoices/checking-houses/" +
      id +
      "/set-effective-date-for-checking-house";
    return this.requestService.putJSON(url, { effective_date });
  }

  // modified to_type of request update house type
  setHomeTypeOfRequestCheckHouse(
    id: string,
    data: any,
    files?: any[]
  ): Observable<ICommonResponse<IPostCheckingHouse>> {
    const url =
      environment.baseUrl +
      "/back-office/invoices/checking-houses/" +
      id +
      "/set-home-type-checking-house";
    const formData = new FormData();

    for (let item in data) {
      if (data[item]) formData.append(item, data[item]);
    }

    if (files?.length > 0) {
      for (const file of files) {
        formData.append("files", file);
      }
    }

    return this.requestService.putJSON(url, formData);
    // return this.requestService.putJSON(url, { home_type, number_of_rooms });
  }

  // upload reference file
  // when sending as formdata no need content-type as bcuz it auto set the content type already
  upLoadFile(files: any, checkID: string, type: string): Observable<any> {
    const url =
      environment.baseUrl +
      "/back-office/invoices/checking-houses/" +
      checkID +
      "/upload-references";
    const formData = new FormData();
    formData.append("type", type);

    for (const file of files) {
      formData.append("files", file);
    }

    return this.requestService.postJSON(url, formData);
  }

  createCheckingHouse(data: any, files: any[]): Observable<any> {
    const url =
      environment.baseUrl + "/back-office/invoices/checking-houses/create";
    const formData = new FormData();

    for (let item in data) {
      if (data[item]) formData.append(item, data[item]);
    }

    for (let [index, file] of files.entries()) {
      formData.append(`files[${index}][file]`, file.file);
      formData.append(`files[${index}][type]`, file.type);
    }

    // for (const file of files) {
    //   formData.append('files', file);
    // }

    return this.requestService.postJSON(url, formData);
  }

  sentComment(
    pid: string,
    text: string
  ): Observable<ICommonResponse<IComment>> {
    const url =
      environment.baseUrl +
      "/back-office/invoices/checking-houses/" +
      pid +
      "/comments";
    return this.requestService.postJSON(url, { text });
  }

  getComment(
    id: string,
    page: number,
    limit: number
  ): Observable<IResponseWithPagination<IComment>> {
    const url = environment.baseUrl + "/back-office/comments/" + id;
    return this.requestService.getJSON(url, { page, limit });
  }

  getReferences(ref_url: string) {
    const url = environment.baseUrl + ref_url + "/metadata";
    return this.requestService.getJSON(url).pipe(map((res: any) => res.data));
  }

  // =================== INVOICE SOLID WASTE ==================
  // =========================================================

  getAllSolidWasteInvoice(
    data: any
  ): Observable<IResponseWithPagination<IInvoiceSolidWaste>> {
    const url = environment.baseUrl + "/back-office/invoices/solid-wastes/";
    return this.requestService.getJSON(url, data);
  }

  getTotalAmountOfAllInvoice(data: any): Observable<any> {
    const url =
      environment.baseUrl + "/back-office/invoices/solid-wastes/total-amount";
    return this.requestService.getJSON(url, data);
  }

  getSumInvByBank(data: any): Observable<ICommonResponse<ISumInvByBank[]>> {
    const url =
      environment.baseUrl + "/back-office/report/solid-wastes/by-bank";
    return this.requestService.getJSON(url, data);
  }

  getSolidWasteInvoice(
    _id: string
  ): Observable<ICommonResponse<IInvoiceSolidWaste>> {
    const url =
      environment.baseUrl + "/back-office/invoices/solid-wastes/" + _id;
    return this.requestService.getJSON(url);
  }

  // get សមតុល្យចុងក្រោយ
  getRemainingAmount(
    data: any
  ): Observable<ICommonResponse<{ total_amount: number }>> {
    const url =
      environment.baseUrl +
      "/back-office/invoices/solid-wastes/calculate-amount";
    return this.requestService.postJSON(url, data, false);
  }

  previewRef(ref: string) {
    window.open(
      `${environment.baseUrl}/references/checking-houses/${ref}`,
      "_blank"
    );
  }

  markAsInprogress(
    id: string,
    data: { files: any[]; description: string }
  ): Observable<IResponseWithPagination<IInvoiceSolidWaste>> {
    const url =
      environment.baseUrl +
      "/back-office/invoices/checking-houses/" +
      id +
      "/return";
    const formData = new FormData();

    formData.append("description", data.description);

    if (data.files.length > 0) {
      for (let [index, file] of data.files.entries()) {
        formData.append(`files[${index}]`, file);
      }
    }
    return this.requestService.putJSON<any>(url, formData);
  }

  markAsVerify(
    id: string,
    data: {
      files: any[];
      description: string;
      home_type: string;
      number_of_rooms: number;
      effective_date: any;
    }
  ): Observable<IResponseWithPagination<IInvoiceSolidWaste>> {
    const url =
      environment.baseUrl +
      "/back-office/invoices/checking-houses/" +
      id +
      "/verify";
    const formData = new FormData();

    formData.append("description", data.description ? data.description : '');
    formData.append("home_type", data.home_type);
    formData.append("number_of_rooms", data.number_of_rooms.toString());
    formData.append("effective_date", data.effective_date);

    if (data.files.length > 0) {
      for (let [index, file] of data.files.entries()) {
        formData.append(`files[${index}]`, file);
      }
    }
    return this.requestService.putJSON<any>(url, formData);
  }

  markAsApprove(
    id: string,
    data: {
      home_type: string;
      number_of_rooms: number;
      effective_date: any;
      files: any[];
    }
  ): Observable<IResponseWithPagination<IInvoiceSolidWaste>> {
    const url =
      environment.baseUrl +
      "/back-office/invoices/checking-houses/" +
      id +
      "/approve";
    const formData = new FormData();

    formData.append("home_type", data.home_type);
    formData.append("number_of_rooms", data.number_of_rooms.toString());
    formData.append("effective_date", data.effective_date);

    if (data.files.length > 0) {
      for (let [index, file] of data.files.entries()) {
        formData.append(`files[${index}]`, file);
      }
    }

    return this.requestService.putJSON<any>(url, formData);
  }

  createInvSolidWaste(
    data: any
  ): Observable<ICommonResponse<IInvoiceSolidWaste>> {
    const url = environment.baseUrl + "/back-office/invoices/solid-wastes/";
    return this.requestService.postJSON(url, data);
  }

  createInvSolidWasteNew(
    data: any
  ): Observable<ICommonResponse<IInvoiceSolidWaste>> {
    const url =
      environment.baseUrl + "/back-office/invoices/solid-wastes/create";
    return this.requestService.postJSON(url, data);
  }

  deleteInvSolidWaste(
    id: string,
    reason: string
  ): Observable<ICommonResponse<IInvoiceSolidWaste>> {
    const url =
      environment.baseUrl + "/back-office/invoices/solid-wastes/" + id;
    return this.requestService.delete(url, { reason });
  }

  adjustInvoiceAmount(
    data: any
  ): Observable<ICommonResponse<IInvoiceSolidWaste>> {
    const url =
      environment.baseUrl + "/back-office/invoices/solid-wastes-adjust-amount";
    return this.requestService.postJSON(url, data);
  }

  validateInvoiceTypeAdjustFromExcel(file: any): Observable<any> {
    const url =
      environment.baseUrl +
      "/back-office/invoices/solid-wastes-adjust-amount/validate-adjust-invoice-from-excel";
    const formData = new FormData();
    formData.append("file", file);

    return this.requestService.postJSON<any>(url, formData, false);
  }

  submitInvoiceTypeAdjustFromExcel(file: any): Observable<any> {
    const url =
      environment.baseUrl +
      "/back-office/invoices/solid-wastes-adjust-amount/import-adjust-invoice-from-excel";
    const formData = new FormData();
    formData.append("file", file);

    return this.requestService.postJSON<any>(url, formData, false);
  }

  getDocument(file_url: string) {
    const path = `${environment.baseUrl}${file_url}`;
    return this.http.get(path, {
      headers: this.getHeader(),
      responseType: "arraybuffer",
    });
  }

  getImageDoc(file_url: string) {
    const path = `${environment.baseUrl}${file_url}`;
    return this.http.get(path, {
      headers: this.getHeader(),
      responseType: "blob",
    });
  }

  private getHeader() {
    const token = this.localStorageService.get("token");
    return new HttpHeaders({
      "Content-Type": "application/json",
      authorization: `Bearer ${token} `,
    });
  }

  getReportTotalSales(data: any): Observable<any> {
    const url = environment.baseUrl + "/back-office/report/invoice-by-month";
    return this.requestService.getJSON(url, data).pipe(
      map((response: any) => {
        return {
          ...response,
          data: response.data.map((item: any) => {
            return {
              total_amount: item.total_amount,
              ...item._id,
            };
          }),
        };
      })
    );
  }

  getReportTotalDebt(data: any): Observable<any> {
    const url = environment.baseUrl + "/back-office/report/debt-by-month";
    return this.requestService.getJSON(url, data).pipe(
      map((response: any) => {
        return {
          ...response,
          data: response.data.map((item: any) => {
            return {
              total_amount: item.total_amount,
              ...item._id,
            };
          }),
        };
      })
    );
  }

  getReportTotalAging(number_of_month: number): Observable<any> {
    const url = environment.baseUrl + "/back-office/report/aging-invoice";
    return this.requestService.getJSON(url, { number_of_month });
  }

  getReportPaymentSummary(data: any): Observable<any> {
    const url = environment.baseUrl + "/back-office/report/payment-summary";
    return this.requestService.getJSON(url, data).pipe(
      map((response: any) => {
        return {
          ...response,
          data: response.data.map((item: any) => {
            return {
              total_amount: item.total_amount,
              ...item._id,
            };
          }),
        };
      })
    );
  }

  printInvoiceSolidWaste(data: any) { }

  updateHouseInvoice(data: any): Observable<any> {
    const url = environment.baseUrl + '/back-office/invoices/solid-wastes/' + data._id;
    return this.requestService.patchJSON(url, data);
  }
}

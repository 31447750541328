import { Injectable } from "@angular/core";
import { UtilService } from "./util.service";
import { Observable, interval, mergeMap, takeUntil, takeWhile } from "rxjs";

export interface PrintResult {
    errorMessage?: string;
    url?: string,
    total_print?: number
}

@Injectable({
    providedIn: 'root'
})


export class ElectronService {
    electronPrint?: {
        print: (data: { url: string, limit: number,skip: number, token: string }) => Promise<{
            errorMessage?: string;
            url?: string,
            total_print: number
        }>,
        getPrintedPage: () => Promise<number>;
        abortPrint: () => void;
    } = (window as any).electronPrint
    isElectron = !!this.electronPrint;

    constructor(private util: UtilService) {

    }

    print(data: { url: string, limit: number, skip: number, id_list?: string[], inv_num_list?: string[] }) {
        return new Observable<number | PrintResult>(observer => {
            let isFinished = false;
            this.electronPrint.print({ ...data, token: this.util.getToken() }).then(v => {
                observer.next(v);
                observer.complete();
            }).finally(()=>{
                isFinished = true;
            })
            interval(1000).pipe(
                takeWhile(() => !isFinished && !observer.closed),
                mergeMap(() => this.electronPrint.getPrintedPage())
            ).subscribe(v => {
                observer.next(v);
            })
        })
    }

    abortPrint(){
        return this.electronPrint.abortPrint()
    }

    getPrintedPage() {
        return this.electronPrint.getPrintedPage()
    }

}

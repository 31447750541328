import { MatFormField } from '@angular/material/form-field';

/**
 * Form field Floating labels gaps not customizable 
 * and not taking into account the label actual size #16411
 */
export function patchMatFormField() {
    const patchedFormFieldClass = MatFormField.prototype as any;

    patchedFormFieldClass.updateOutlineGapOriginal = MatFormField.prototype.updateOutlineGap;
    MatFormField.prototype.updateOutlineGap = function () {
        this.updateOutlineGapOriginal();
        const container = this._connectionContainerRef.nativeElement;
        const gapEls = container.querySelectorAll('.mat-form-field-outline-gap');
        gapEls.forEach((gp) => {
            const calculatedGapWidth = +gp.style.width.replace('px', '');
            const gapWidth = calculatedGapWidth / 0.75;
            gp.style.width = `${gapWidth}px`;
        });
    };
}
import { IUser } from '../models/interface/int-user';
import { IResponseWithPagination } from '../models/response/response-with-pagination';
import { ICommonResponse } from '../models/response/common-response';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RequestService } from './request.service';
import { ISummaryWallet } from '../models/interface/summary-wallet';
import { ICustomer } from '../models/interface/int-customer';
import { SubmitFailService } from './submit-fail.service';

@Injectable({
	providedIn: 'root'
})
export class CustomerService extends SubmitFailService {

	constructor(
		private requestService: RequestService,
	) { super(); }

	getCustomerList(searchQuery: string, page: number, limit: number): Observable<IResponseWithPagination<ICustomer>> {
		const url = environment.baseUrl + '/back-office/customers/';
		// let searchQuery = search ? search.toString() : undefined;

		return this.requestService.getJSON(url, { searchQuery, page, limit });
	}

	getByID(id: string): Observable<ICommonResponse<ICustomer>> {
		const url = environment.baseUrl + '/back-office/customers/' + id;
		return this.requestService.getJSON(url);
	}

	create(data: any): Observable<ICommonResponse<ICustomer>> {
		const url = environment.baseUrl + '/back-office/customers/create';
		return this.requestService.postJSON(url, data);
	}



	/**
	 * get list of customer whose feedback on particular route
	 * @param id
	 * @see id: id of waste collection history
	 * @returns 
	 */
	getListOfCustomerFeedback(id: string): Observable<ICommonResponse<{users: ICustomer[], _id: string}>> {
		const url = environment.baseUrl + '/back-office/customers/feedback/' + id;
		return this.requestService.getJSON(url);
	}

	/**
	 * DISABLE USER AND THEIR REQUESTS
	 * @param id 
	 * @returns 
	 */
	disableUser(id: string): Observable<ICommonResponse<ICustomer>> {
		const url = environment.baseUrl + '/back-office/customers/' + id + '/disable';
		return this.requestService.postJSON(url);
	}

	getSummaryWallet():Observable<ICommonResponse<ISummaryWallet>> {
		const url = environment.baseUrl + '/back-office/summary/wallet';
		return this.requestService.getJSON(url);
	}

	changePassword(u_id: string, inputData: any): Observable<ICommonResponse<ICustomer>> {
		const url = environment.baseUrl + '/back-office/customers/' + u_id + '/change-password';
		return this.requestService.postJSON(url, inputData);
	}

	resetPassword(u_id: string, inputData: any): Observable<ICommonResponse<ICustomer>> {
		const url = environment.baseUrl + '/back-office/customers/' + u_id + '/reset-password';
		return this.requestService.postJSON(url, inputData);
	}
}

import { Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Injectable()
export abstract class CBreakPointObserver implements OnDestroy {

  isTablet: boolean = false;
  isWeb: boolean = false;
  isMobile: boolean = false;

  isLaptopLowDpi: boolean = false;
  isLaptopMediumDpi: boolean = false;
  isLaptopHighDpi: boolean = false;
  isLaptopFullHd: boolean = false;

  isMacBook12: boolean = false;
  isMacBook13: boolean = false;
  isMacBook15: boolean = false;

  screen1440px: boolean = false;
  screen1280px: boolean = false;
  screen414px: boolean = false;

  sub: Subscription;
  constructor(
    breakPointObserver: BreakpointObserver
  ) {
    // (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)
    this.sub = breakPointObserver.observe(Breakpoints.Tablet).subscribe(res => {
      this.isTablet = res.matches;
    });

    // (min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape)
    this.sub = breakPointObserver.observe(Breakpoints.Web).subscribe(res => {
      this.isWeb = res.matches;
    });

    // (max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape)
    this.sub = breakPointObserver.observe(Breakpoints.Handset).subscribe(res => {
      this.isMobile = res.matches;
    });

    this.sub = breakPointObserver.observe('(min-width: 1281px) and (max-width: 1440px)').subscribe(res => {
      this.screen1440px = res.matches;
    });

    this.sub = breakPointObserver.observe('(min-width: 415px) and (max-width: 1280px)').subscribe(res => {
      this.screen1280px = res.matches;
    });

    this.sub = breakPointObserver.observe('(max-width: 414px)').subscribe(res => {
      this.screen414px = res.matches;
    });

    this.sub = breakPointObserver.observe('(min-width: 1280px) and (max-width: 1335.98px) and (orientation: landscape)').subscribe(res => {
      this.isLaptopLowDpi = res.matches;
    });

    this.sub = breakPointObserver.observe('(min-width: 1336px) and (max-width: 1439.98px) and (orientation: landscape)').subscribe(res => {
      this.isLaptopMediumDpi = res.matches;
    });

    this.sub = breakPointObserver.observe('(min-width: 1440px) and (max-width: 1919.98px) and (orientation: landscape)').subscribe(res => {
      this.isLaptopHighDpi = res.matches;
    });

    this.sub = breakPointObserver.observe('(min-width: 1920px) and (orientation: landscape)').subscribe(res => {
      this.isLaptopFullHd = res.matches;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

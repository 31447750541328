import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { PhoneNumberValidator } from '../helpers/validators/phone-number-validator';

@Directive({
	selector: '[numberInput]'
})
export class NumberInputDirective {
	@Input() phoneValidate: boolean = false;
	@Input() cashValidate: boolean = false;
	allowed_sign_codes: Array<number> = [
		46, // . 
		// 44, // , 
		// 40, // ( 
		// 41, // ) 
		// 64, // @ 
		// 38, // & 
		// 45, // - 
		// 95, // _ 
		// 58, // : 
		// 59, // ; 
		// 39, // ' 
		// 34, // " 
		// 32, // space (in both khmer and english)
	  ];
	constructor(private control: NgControl) {}

	@HostListener('click', ['event'])
	onClickInput(event: any) {
		if (this.phoneValidate) {
			this.control.control.addValidators(PhoneNumberValidator('KH'));
			this.control.control.updateValueAndValidity();
		}
	}

	@HostListener('change', ['$event'])
	onInputChange(event): void {
		let value = event.target.value;
		value = value.replace(/\s{2,}/g, ''); // correct space format in string
		var output = "";
		for (var i = 0; i < value.length; i++) {
			if (48 <= value.charCodeAt(i) && value.charCodeAt(i) <= 57 || (this.cashValidate ? this.allowed_sign_codes.includes(value.charCodeAt(i)) : true)) {
				output += value.charAt(i);
			}
		}

		value = output.trim();
		this.control.valueAccessor.writeValue(value.toUpperCase());
	}

	@HostListener('keypress', ['$event'])
	onKeyPressEn(event) {
		var ew = event.which;
		var valid: boolean = false;
		if (48 <= ew && ew <= 57) valid = true; // number in english
		else if (this.cashValidate && this.allowed_sign_codes.includes(ew) && !event.target.value.includes('.')) valid = true;
		else valid = false;
		return valid;
	}

}

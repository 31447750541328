import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'localizeDate'
})
export class LocalizeDatePipe implements PipeTransform {

  transform(value: string, args: {inFormats: string, outFormats: string} = {inFormats: 'DD-MM-YYYY', outFormats: 'DD-MM-YYYY'}): string {
    if (!value) return '-';
    return moment(value, args.inFormats).locale('km').format(args.outFormats);
  }

}

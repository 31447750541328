<ng-container *ngIf="!isShowSearch">
    <div class="no-data" *ngIf="totalItem === 0 && !loading">
        <img src="../../../../../assets/image/no-data.svg" alt="">
        <p>{{'table.no-data' | translate}}</p>
        <p *ngIf="isUpload">{{'message.please_upload_file' | translate}}</p>
    </div>
</ng-container>


<ng-container *ngIf="isShowSearch">
    <div class="no-data" *ngIf="isSearch && totalItem === 0 && !loading">
        <img src="../../../../../assets/image/no-data.svg" alt="">
        <p>{{'table.no-data' | translate}}</p>
        <p *ngIf="isUpload">{{'message.please_upload_file' | translate}}</p>
    </div>

    <div class="search-data" *ngIf="!isSearch">
        <img src="../../../../../assets/image/illustrate/searching.svg" alt="">
        <p>{{'message.click_search_to_show_data' | translate}}</p>
    </div>
</ng-container>


<!-- <mat-paginator *ngIf="!(totalItem === 0 && pageIndex === 0 && !isNoDataOnly)"  -->
<mat-paginator *ngIf="!(totalItem === 0 && pageIndex === 0) && isShowPaginator" #paginator [showFirstLastButtons]="isShowFirstPageLastPage" [ngClass]="{'set-bg-pag-white': isInjected, 'hide-page-range': !isShowPageRange, 'show-page-range': isShowPageRange}" [length]="totalItem" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)">
</mat-paginator>

import { Directive, OnInit, OnDestroy, Input, ElementRef, Renderer2, Optional } from "@angular/core";
import { AbstractControl, NgControl, Validators } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";

@Directive({
  selector: '[labelRequire]'
})
export class RequiredDirective implements OnInit, OnDestroy {
  private _destroyed = new Subject<void>();

  @Input() control?: AbstractControl;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Optional() private ngControl: NgControl
  ) {}

  ngOnInit() {
    const control = this.control || this.ngControl?.control;
    const label = this.getLabelElement();

    if (!control || !label) return;

    this.toggleRequiredClass(label, control.hasValidator(Validators.required));

    control.statusChanges.pipe(takeUntil(this._destroyed)).subscribe(() => {
      const isRequired = control.hasValidator(Validators.required);
      this.toggleRequiredClass(label, isRequired);
    });
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  private getLabelElement(): HTMLElement {
    return this.el.nativeElement.querySelector('label') ||
           this.el.nativeElement.parentElement?.querySelector('label') ||
           this.el.nativeElement;
  }

  private toggleRequiredClass(label: HTMLElement, isRequired: boolean) {
    if (isRequired) {
      this.renderer.addClass(label, 'required-label');
    } else {
      this.renderer.removeClass(label, 'required-label');
    }
  }
}

import { ICommonResponse } from '../models/response/common-response';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { MatDialog } from '@angular/material/dialog';
import { UtilService } from './util.service';

@Injectable({
	providedIn: 'root'
})
export class RequestService {

	private readonly JWT_TOKEN = 'token';
	private readonly REFRESH_TOKEN = 'refresh_token';
	public availableLang: string[] = ['en', 'km'];
	public defaultLanguage: string;

	constructor(
		private http: HttpClient,
		private loadingService: LoadingService,
		private util: UtilService,
		private router: Router,
		private dialog: MatDialog,
	) { }

	getJSON<T>(url: string, data: any = {}, setLoading = true, setSnackBar = true) {
		this.clean(data);
		if (setLoading) {
			this.loadingService.setLoading(true);
		}

		const headers = this.getAuthHeader();
		headers.append('Content-Type', 'application/json');
		return this.http.get<T>(url, { params: data, headers })
			.pipe(
				map(res => {
					if (setLoading) {
						this.loadingService.setLoading(false);
					}

					// this.handleError(res, setSnackBar);
					return res;
				}),
				catchError((err) => this.handleHttpError(err, setSnackBar, url))
			);
	}

	putJSON<T>(url: string, data: any = {}) {
		this.clean(data);
		this.loadingService.setLoading(true);
		const headers = this.getAuthHeader();
		headers.append('Content-Type', 'application/json');
		return this.http.put<T>(url, data, { headers })
			.pipe(
				map(res => {
					const r = res as unknown as ICommonResponse<any>;
					this.loadingService.setLoading(false);
					this.util.openSuccessSnackbar('req_status.success');
					this.handleError(res);
					return res;
				}),
				catchError((err) => this.handleHttpError(err))
			);
	}

	patchJSON<T>(url: string, data: any = {}) {
		this.clean(data);
		this.loadingService.setLoading(true);
		const headers = this.getAuthHeader();
		headers.append('Content-Type', 'application/json');
		return this.http.patch<T>(url, data, { headers })
			.pipe(
				map(res => {
					this.loadingService.setLoading(false);
					this.handleError(res);
					return res;
				}),
				catchError((err) => this.handleHttpError(err))
			);
	}

	postJSON<T>(url: string, data: any = {}, setSnackBar = true) { // <T> is use to make IDE spot the type missmatch of input data
		this.clean(data); // make sure the data is not null or undefine
		this.loadingService.setLoading(true); // every time this request is load, the loading service is emitted
		const headers = this.getAuthHeader(); // get authentication headers, authorization, bearer, token ...
		headers.append('Content-Type', 'application/json'); // then, append the content type
		return this.http.post<T>(url, data, { headers }) // then, pipe all the respones to use in different need
			.pipe(
				map(res => {
					const r = res as unknown as ICommonResponse<any>;
					this.loadingService.setLoading(false);
					// if (setSnackBar) this.util.openSuccessSnackbar('req_status.success');
					this.handleError(res);
					return res;
				}),
				catchError((err) => this.handleHttpError(err, setSnackBar))
			);
	}

	get<T>(url: string, data: any = {}) {
		this.clean(data);
		this.loadingService.setLoading(true);
		const headers = this.getAuthHeader();
		return this.http.get<T>(url, { params: data, headers }).pipe(
			map(res => {
				this.loadingService.setLoading(false);
				return res;
			}),
			catchError((err) => this.handleHttpError(err))
		);
	}

	delete<T>(url: string, data: any = {}) {
		this.clean(data);
		this.loadingService.setLoading(true);
		const headers = this.getAuthHeader();
		return this.http.delete<T>(url, { params: data, headers }).pipe(
			map(res => {
				const r = res as unknown as ICommonResponse<any>;
				this.loadingService.setLoading(false);
				this.util.openSuccessSnackbar('req_status.success');
				this.handleError(res);
				return res;
			}),
			catchError((err) => this.handleHttpError(err))
		);
	}

	post<T>(url: string, data: any = {}) {
		this.clean(data);
		this.loadingService.setLoading(true);
		const headers = this.getAuthHeader();
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		data = this.toFormData(data);
		return this.http.post<T>(url, data, { headers }).pipe(
			map(res => {
				this.loadingService.setLoading(false);
				return res;
			}),
			catchError((err) => this.handleHttpError(err))
		);
	}


	private clean(obj: any) {
		// this.snackBar.dismiss();
		// window.onbeforeunload = () => {
		//   sessionStorage.clear();
		//   return '';
		// };

		for (const propName in obj) {
			if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
				delete obj[propName];
			}
		}
	}


	private getAuthHeader(): HttpHeaders {
		// const token = this.sessionStorageService.get(SESSION_ENUM.TOKEN);
		const token = this.util.getToken();
		return new HttpHeaders({
			Authorization: 'Bearer ' + token
		});
	}


	/**
	 * HANDLE RESPONSE ERROR
	 * @param res
	 * @param setSnackBar 
	 */
	private handleError(res: any, setSnackBar = true) {
		const r = res as unknown as ICommonResponse<any>;
		if (r.status === 401) {
			if (setSnackBar) this.util.openErrorSnackbar('message.token_expired');
			sessionStorage.removeItem('token');
			this.dialog.closeAll();
			this.router.navigateByUrl('login');
		} else if (r.status === -1 || r.status === 0) {
			if (res.errors) {
				const msg = res.errors[0].msg + `\n${res.errors[1]?.msg ? res.errors[1]?.msg : ''}`;
				if (setSnackBar) this.util.openErrorSnackbar(msg ? msg : 'error_code.0000');
			}

		} else if (r.status === 500) {
			console.log('something went wrong');
		}
	}

	/**
	 * HANDLE ROUTE ERROR
	 * @param error 
	 * @param setSnackBar 
	 * @returns 
	 */
	private handleHttpError(error: any, setSnackBar = true, url = '') {
		this.loadingService.setLoading(false);
		if (error.status === 401) {
			if (setSnackBar) this.util.openErrorSnackbar('message.token_expired');
			sessionStorage.removeItem('token');
			this.dialog.closeAll();
			this.router.navigateByUrl('login');

		} else if (error.status === 0 && error.message.includes('invalid signature')) {
			if (setSnackBar) this.util.openErrorSnackbar(error.message);
			sessionStorage.removeItem('token');
			this.dialog.closeAll();
			this.router.navigateByUrl('login');

		} else if (error.status === 0 && error.message.includes('Http failure')) {
			if (setSnackBar) this.util.openErrorSnackbar('error_code.0000');

		} else if (error.status === 403) {
			if (setSnackBar) this.util.openErrorSnackbar('error_code.403');

		} else if (error.status === 404) {
			if (error.error.message.includes('Username or Password are wrong')) {
				if (setSnackBar) this.util.openErrorSnackbar('message.wrong_user_psw');

			} else {
				if (setSnackBar) this.util.openErrorSnackbar('error_code.0000');
			}

		} else if (error.status === 400) {
			if (error.error.message.includes('Username or Password are wrong')) {
				if (setSnackBar) this.util.openErrorSnackbar('message.wrong_user_psw');

			} else if (error.error.message.includes('Bad Request')) {
				if (setSnackBar) this.util.openErrorSnackbar('message.submit_fail');

			} else {
				if (setSnackBar) this.util.openErrorSnackbar('error_code.0000');
			}

		} else {
			if (setSnackBar) this.util.openErrorSnackbar(`error_code.${error.error.code}`);
		}
		return throwError(error);
	}

	private toFormData<T>(formValue: T) {
		const formData = new FormData();

		for (const key of Object.keys(formValue)) {
			const value = formValue[key];
			formData.append(key, value);
		}
		return formData;
	}



	// ********* request token service **************

	// refreshToken() {
	// 	const url = environment.baseUrl + '/back-office/staffs/reset-token';
	// 	return this.http.post<any>(url, {
	// 		refresh_token: this.getRefreshToken()
	// 	}).pipe(tap((tokens: any) => {
	// 		this.storeJwtToken(tokens.data.access_token);
	// 		this.storeRefreshToken(tokens.data.refresh_token);
	// 	}));
	// }

	// getJwtToken() {
	// 	return this.sessionStorageService.get(this.JWT_TOKEN);
	// }

	getRefreshToken() {
		return this.util.getRefreshToken();
	}

	// private storeJwtToken(jwt: string) {
	// 	this.sessionStorageService.set(this.JWT_TOKEN, jwt);
	// }

	// private storeRefreshToken(refreshToken: string) {
	// 	this.sessionStorageService.set(this.REFRESH_TOKEN, refreshToken);
	// }



	// ********* language service ************

	// public saveDefaultLang(lang: string) {
	// 	this.localStorageService.set(LOCAL_STORAGE_ENUM.lang, lang);
	// }

	public getDefaultLang() {
		return this.util.getLang();

		// const lang = this.localStorageService.get(LOCAL_STORAGE_ENUM.lang);
		// return lang ? lang : 'km';
	}

	// ********* language service ************
	onSignOut() {
		sessionStorage.clear();
		localStorage.clear();
		this.dialog.closeAll();
		this.router.navigate(['/login']);
	}
}

export enum REQUEST_TYPE_ENUM {
            VERIFY = "VERIFY",
            UPDATE = "UPDATE",
            CREATE = "CREATE",
            DISABLE = "DISABLE",
            POST_SOLID_WASTE = "POST_SOLID_WASTE",
            CHECKING_HOUSE = "CHECKING_HOUSE",
            RECYCLE = "RECYCLE",
            INVOICE_SOLID_WASTE = "INVOICE_SOLID_WASTE",
            HOME_NAME = "HOME_NAME",
            ADDRESS = "ADDRESS",
            HOME_TYPE = "HOME_TYPE",
            REFERENCE = "REFERENCE",
            HOME_COORDINATE = "HOME_COORDINATE",
            REQUEST_REGISTER = "REQUEST_REGISTER",

            ENABLE_HOME = "ENABLE_HOME",
            DISABLE_HOME = "DISABLE_HOME"
}
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { IHome } from 'src/app/models/interface/int-home';
import { IStreet } from 'src/app/models/interface/int-street';
import { ILocLocation } from 'src/app/models/interface/loc-location';
import { UtilService } from 'src/app/services/util.service';

@Pipe({
  name: 'location'
})
export class LocationPipe implements PipeTransform {
  lang: string = '';

  constructor(private translate: TranslateService, private util: UtilService) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => this.lang = event.lang);
    if (this.lang == '' || !this.lang) this.lang = this.util.getLang();
  }

  transform(value: ILocationPipe, args: 'short' | 'long' = 'short'): string {
    if (!value) return '-';
    let result = '-';

    if (value && args == 'short') result = value.home.address;
    else if (value && args == 'long') {
      result = `${value.home.house_no} ${this.getStreet(value)} ${this.getLocation(value)}`;
      if (result == '  ') result = value.home.address;
    };

    return result;
  }

  getHouse(data: ILocationPipe): string {
    if (data.home.house_no) {
      return '#' + data.home.house_no
    } else return '';
  }

  getStreet(data: ILocationPipe): string {
    if (data.street) return this.lang == 'en' ? 'St.' + data.street.name_en : 'St.' + data.street.name;
    else if (data.home.street && typeof (data.home.street) != 'string') return this.lang == 'en' ? 'St.' + data.home.street.name_en : 'St.' + data.home.name;
    else return '';
  }

  getLocation(data: ILocationPipe): string {
    if (data.location) return `${data?.location?.type_kh} ${data?.location?.name_kh} ${data?.location?.parent_id?.type_kh} ${data?.location?.parent_id?.name_kh}`;
    else return '';
  }
}

interface ILocationPipe {
  home: IHome;
  street: IStreet;
  location: ILocLocation;
  [x: string]: any;
}

<div class="dialog-container">
	<div mat-dialog-title class="title-dialog">
		<h3>{{'button.change_location' | translate}}</h3>
	</div>

	<mat-dialog-content>
		<mat-form-field appearance="outline" class="m-form-select show-outline no-prefix-icon">
			<mat-label>{{'form-field.khan' | translate}}</mat-label>
			<mat-select [formControl]="khanControl" (selectionChange)='getSangkatByKhanID(khanControl.value); disable = false;'>
				<mat-option *ngFor="let khan of khans" [value]="khan._id">
					{{ lang == 'en' ? khan.name_en : khan.name_kh }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field appearance="outline" class="m-form-select show-outline no-prefix-icon">
			<mat-label>{{'form-field.sangkat' | translate}}</mat-label>
			<mat-select [formControl]="sangkatControl" (selectionChange)="disable = false">
				<mat-option [value]="">{{'form-field.all' | translate}}</mat-option>
				<mat-option *ngFor="let sk of sangkats" [value]="sk._id">
					{{ lang == 'en' ? sk.name_en : sk.name_kh }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<div mat-dialog-action class="main">
			<div class="dialog-btn-box">
				<button mat-raised-button class="m-button-normal" matDialogClose>
					<mat-icon class="svg-icon" svgIcon="close_icon"></mat-icon> {{'button.cancel' | translate}}
				</button>
				<button mat-raised-button class="m-button-primary" [ngClass]="{'m-button-disabled': disable}" [disabled]="disable" (click)="submit()">
					<mat-icon class="svg-icon" [svgIcon]="disable ? 'save_no_color_icon' : 'save_icon'"></mat-icon>
					{{'button.confirm' | translate}}
				</button>
			</div>
		</div>
	</mat-dialog-content>
</div>
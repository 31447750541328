<main>
	<mat-sidenav-container autosize>
		<mat-sidenav [autoFocus]="false" [opened]="screenWidth > 820" [mode]="(screenWidth > 820) ? 'side' : 'over'" [style.min-width]="hideText ? '100px' : '335px'" (opened)="hideText = false" fixedTopGap="56" #sidenav>
			<div class="system-desc" [ngClass]="{'menu-shadow': isScrolled}">
				<img src="../../../assets/image/new-logo.svg" alt="system logo">
				<p class="system-title" *ngIf="!hideText" tabindex="-1">
					{{'root.project.title' | translate}}
				</p>
			</div>

			<div class="side-menu-container" (scroll)="onMenuScroll($event)">
				<app-side-menu-left (valueChange)="$event && screenWidth <= 820 ? sidenav.toggle() : ''" [hideMenuBar]="hideMenuBar" [currentRole]="currentUserRole" [hideText]="hideText" [phone]="screenWidth <= 820">
				</app-side-menu-left>
			</div>
		</mat-sidenav>

		<mat-sidenav-content>
			<mat-toolbar class="toolbar" [style.width]="hideText ? 'calc(100% - 100px)' : 'calc(100% - 335px)'">
				<mat-icon (click)="screenWidth <= 820 ? sidenav.toggle() : toggleNav()" 
						[style.color]="openSideNav ? '#34344f' : '#ffd803'" class="open-sidenav-btn">notes
				</mat-icon>

				
				<!-- <p class="mini-title" *ngIf="houseData">{{houseData.edc_customer_number + '-' + lang == 'en' ? houseData.name_en : houseData.name}}</p> -->
				<p class="mini-title" *ngIf="houseData">{{houseData.edc_id ||houseData.edc_customer_number}} <span *ngIf="houseData.edc_id || houseData.edc_customer_number">-</span> {{lang == 'en' ? houseData.name_en : houseData.name}}</p>
				<p class="mini-title" *ngIf="isOperator && !houseData">{{companyName}}</p>

				<div class="acc-icon">
					<div class="lang-switch">
						<img src="../../../assets/image/khmer.png" alt="Khmer language" *ngIf="lang == 'en'" (click)="switchLangToKM()">
						<img src="../../../assets/image/english.png" alt="English language" *ngIf="lang == 'km' || !lang" (click)="switchLangToEN()">
						<span></span>
					</div>
					<button mat-button [matMenuTriggerFor]="authMenu">
						<mat-icon>account_circle</mat-icon>
						<span class="login-user-name" *ngIf="!isOperator">{{ username }}</span>
						<!-- <span class="login-user-name" *ngIf="isOperator">{{ companyName }}</span> -->
					</button>
				</div>

				<mat-menu #authMenu="matMenu" style="background: white;">
					<div class="menu-container">
						<mat-icon>account_circle</mat-icon><br />
						<p class="username" *ngIf="!isOperator">{{ username }}</p>
						<p class="username" *ngIf="isOperator">{{companyName}}</p>

						<p class="currentUserRole">
							{{'root.menu.role.title' | translate}}: {{lang == 'en' ? role_en : role_kh}}
						</p>

						<!-- <p class="currentUserRole" *ngIf="currentUserRole == 'admin'">
							{{'root.menu.role.title' | translate}}: {{'root.menu.role.admin' | translate}} {{ khanName }}
						</p>
						<p class="currentUserRole" *ngIf="currentUserRole == 'operator_admin'">
							{{'root.menu.role.title' | translate}}: {{'root.menu.role.operator_admin' | translate}} {{ khanName }}
						</p>
						<p class="currentUserRole" *ngIf="currentUserRole == 'operator_staff'">
							{{'root.menu.role.title' | translate}}: {{'root.menu.role.operator_staff' | translate}} {{ khanName }}
						</p> -->
						<!-- <p class="currentUserRole" *ngIf="currentUserRole == 'admin_khan' || currentUserRole == 'operator_admin_khan'">
							{{'root.menu.role.title' | translate}}: {{'root.menu.role.admin' | translate}} {{ khanName }}
						</p>
						<p class="currentUserRole" *ngIf="currentUserRole == 'admin_sangkat' || currentUserRole == 'operator_admin_sangkat'">
							{{'root.menu.role.title' | translate}}: {{'root.menu.role.admin' | translate}} {{ sankatName }}
						</p>
						<p class="currentUserRole" *ngIf="currentUserRole == 'staff_khan' || currentUserRole == 'operator_staff_khan'">
							{{'root.menu.role.title' | translate}}: {{'root.menu.role.staff' | translate}}
						</p>
						<p class="currentUserRole" *ngIf="currentUserRole == 'staff_sangkat' || currentUserRole == 'operator_staff_sangkat'">
							{{'root.menu.role.title' | translate}}: {{'root.menu.role.staff' | translate}} {{ khanName }} {{ sankatName }}
						</p> -->

						<br />

						<button mat-stroked-button class="action-btn" (click)="changePSW()">
							{{'root.menu.action.change_psw' | translate}}
						</button>

						<button mat-stroked-button class="action-btn" (click)="logout()">
							{{'root.menu.action.sign_out' | translate}}
						</button>
					</div>
				</mat-menu>
			</mat-toolbar>

			<div class="sub-container preview-content" 			
				[@routeAnimations]="{
					value: prepareRoute(outlet),
					params: {
						top: screen1440px ? '20px' : screen1280px ? '30px' : screen414px ? '12px' : '30px',
						right: screen1440px ? '30px' : screen1280px ? '30px' : screen414px ? '16px' : '40px',
						bottom: screen1440px ? '20px' : screen1280px ? '30px' : screen414px ? '12px' : '30px',
						left: screen1440px ? '30px' : screen1280px ? '30px' : screen414px ? '16px' : '40px',
						width: screen1440px ? 'calc(100% - 60px)' : screen1280px ? 'calc(100% - 60px)' : screen414px ? 'calc(100% - 32px)' : 'calc(100% - 80px)',
						height: screen1440px ? 'calc(100% - 40px)' : screen1280px ? 'calc(100% - 60px)' : screen414px ? 'calc(100% - 24px)' : 'calc(100% - 60px)'
					}
				}">
				<router-outlet #outlet="outlet"></router-outlet>
			</div>
		</mat-sidenav-content>
	</mat-sidenav-container>
</main>
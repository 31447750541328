<div class="dialog-container">
  <div mat-dialog-title class="title-dialog">
    <h3 class="component-header">{{ "button.update" | translate }}</h3>

    <div class="msg-container">
      <p>
        {{ "form-field.sign" | translate }} '{{
          "form-field.required_sign" | translate
        }}' {{ "form-field.required_msg" | translate }}
      </p>
    </div>
  </div>

  <mat-dialog-content>
    <form>
      <!-- ------------------ FORM SELECT CHANGE SECTION ------------------ -->
      <!-- --------------------------------------------------- -------------- -->
      <div class="form-row" [formGroup]="form">
        <div class="col-md-12">
          <mat-form-field
            appearance="outline"
            class="m-form-select show-outline no-prefix-icon"
            showErrorMessage
          >
            <mat-label>{{ "form-field.type" | translate }}</mat-label>
            <mat-select
              formControlName="type"
              [disableRipple]="true"
              (selectionChange)="onTypeChange($event)"
            >
              <mat-option *ngFor="let sk of types" [value]="sk._id">{{
                sk.name | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- ------------------------ HOME NAME FORM -------------------------- -->
      <!-- ------------------------------------------------------------------ -->
      <ng-container *ngIf="formType == 'HOME_NAME'">
        <ng-container *ngTemplateOutlet="homeNameFormTemplate"></ng-container>
      </ng-container>

      <!-- -------------------------- ADDRESS FORM ------------------------- -->
      <!-- ----------------------------------------------------------------- -->
      <ng-container *ngIf="formType == 'ADDRESS'">
        <ng-container *ngTemplateOutlet="addressFormTemplate"></ng-container>
      </ng-container>

      <!-- -------------------------- HOME TYPE FORM ------------------------- -->
      <!-- ------------------------------------------------------------------- -->
      <ng-container *ngIf="formType == 'HOME_TYPE'">
        <ng-container *ngTemplateOutlet="homeTypeFormTemplate"></ng-container>
      </ng-container>

      <!-- -------------------- UPLOAD DOCUMENT FORM --------------------- -->
      <!-- ----------------------------------------------------------------- -->
      <ng-container *ngIf="formType == 'REFERENCE'">
        <ng-container *ngTemplateOutlet="uploadDocFormTemplate"></ng-container>
      </ng-container>

      <!-- -------------------- UPDATE COORDINATE FORM --------------------- -->
      <!-- ---------------------------------------------------------------- -->
      <ng-container *ngIf="formType == 'HOME_COORDINATE'">
        <ng-container *ngTemplateOutlet="coordinateFormTemplate"></ng-container>
      </ng-container>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="mr-2">
    <button mat-raised-button class="m-button-normal" matDialogClose>
      <mat-icon class="svg-icon" svgIcon="close_icon"></mat-icon>
      {{ "button.cancel" | translate }}
    </button>
    <button
      mat-raised-button
      class="m-button-primary"
      (click)="onSubmit()"
      [ngClass]="{ 'm-button-disabled': isDisable }"
      [disabled]="isDisable"
    >
      <mat-icon
        class="svg-icon"
        [svgIcon]="form.invalid ? 'save_disabled_icon' : 'save_icon'"
      ></mat-icon>
      {{ "button.save" | translate }}
    </button>
  </mat-dialog-actions>
</div>

<ng-template #homeNameFormTemplate>
  <div [formGroup]="homeForm">
    <div class="form-row">
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="m-form-input show-outline no-prefix-icon"
          showErrorMessage
        >
          <mat-label>{{ "table.customer_no" | translate }}</mat-label>
          <input matInput formControlName="edc_id" />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="m-form-input show-outline no-prefix-icon"
          showErrorMessage
        >
          <mat-label>{{ "table.name_en" | translate }}</mat-label>
          <input matInput formControlName="name" />
          <div
            class="disabled-field"
            *ngIf="addressForm?.get('village')?.disabled"
          ></div>
        </mat-form-field>
      </div>
    </div>

    <ng-container formArrayName="phones">
      <div class="form-row" *ngFor="let control of phonesFormArray.controls; index as i;">
        <div class="col-md-5">
          <mat-form-field appearance="outline" class="m-form-input show-outline no-prefix-icon" showErrorMessage>
            <mat-label>{{'table.phone_number' | translate}} ({{i + 1}})</mat-label>
            <input matInput numberInput [phoneValidate]="true" type="tel" [formControlName]="i">
          </mat-form-field>
        </div>

        <div class="col-md-1 d-flex center">
          <button mat-icon-button class="remove"
            matTooltip="{{'button.remove' | translate}}"
            matTooltipPosition="after"
            [disabled]="phonesFormArray.controls.length <= 1"
            (click)="handleRemovePhoneControl(i)">
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
        </div>
      </div>

      <div class="form-row mt-3">
        <div class="col-md-6 d-flex center">
          <button mat-raised-button class="m-button-primary" [disabled]="phonesFormArray.invalid" (click)="handleAddPhoneControl()">
            <mat-icon class="svg-icon" [svgIcon]="phonesFormArray.invalid ? 'add_more_no_color_icon' : 'add_more_icon'"></mat-icon>
            {{'table.add_more' | translate}}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #addressFormTemplate>
  <div [formGroup]="addressForm">
    <div class="form-row">
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="m-form-select show-outline no-prefix-icon"
          showErrorMessage
        >
          <mat-label>{{ "form-field.khan" | translate }}</mat-label>
          <mat-select formControlName="khan" (selectionChange)="onKhanChange()">
            <mat-option *ngFor="let kh of khans" [value]="kh._id">
              {{ lang == "en" ? kh.name_en : kh.name_kh }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="m-form-select show-outline no-prefix-icon"
          showErrorMessage
        >
          <mat-label>{{ "form-field.sangkat" | translate }}</mat-label>
          <mat-select
            formControlName="location"
            (selectionChange)="
              getStreetByLocation(addressForm.value.location); manipulateForm()
            "
          >
            <mat-option *ngFor="let sk of sangkats" [value]="sk._id">
              {{ lang == "en" ? sk.name_en : sk.name_kh }}
            </mat-option>
          </mat-select>
          <div
            class="disabled-field"
            *ngIf="addressForm?.get('location')?.disabled"
          ></div>
        </mat-form-field>
      </div>

        <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="m-form-input show-outline no-prefix-icon"
          showErrorMessage>
          <mat-label>{{ "table.village" | translate }}</mat-label>
          <mat-select
            formControlName="village">
            <mat-option *ngFor="let v of villages" [value]="v._id">
              {{ lang == "en" ? v.name_en : v.name_kh }}
            </mat-option>
          </mat-select>
          <div
            class="disabled-field"
            *ngIf="addressForm?.get('village')?.disabled"
          ></div>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="m-form-select show-outline no-prefix-icon"
          showErrorMessage
        >
          <mat-label>{{ "table.street" | translate }}</mat-label>
          <!-- <mat-select formControlName="street">
            <mat-option *ngIf="streets?.length == 0" value="">{{
              "message.none" | translate
            }}</mat-option>
            <mat-option *ngFor="let st of streets" [value]="st._id">{{
              lang == "en" ? st.name_en : st.name
            }}</mat-option>
          </mat-select> -->
          <input matInput formControlName="street_number" />
          <div
            class="disabled-field"
            *ngIf="addressForm.get('street_number')?.disabled"
          ></div>
        </mat-form-field>
      </div>

    

      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="m-form-select show-outline no-prefix-icon"
          showErrorMessage
        >
          <mat-label>{{ "table.house" | translate }}</mat-label>
          <input matInput formControlName="house_no" />
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <mat-form-field
          appearance="outline"
          class="m-form-select show-outline no-prefix-icon"
          showErrorMessage
        >
          <mat-label>{{ "table.address" | translate }}</mat-label>
          <input matInput formControlName="address" />
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #homeTypeFormTemplate>
  <div [formGroup]="homeTypeForm">
    <div class="form-row">
      <div class="col-md-6">
        <!-- <mat-form-field appearance="outline" class="m-form-select show-outline no-prefix-icon" showErrorMessage>
					<mat-label>{{'table.house_category' | translate}}</mat-label>
					<mat-select formControlName="home_category" (selectionChange)="onChangeHouseCategory()">
						<mat-option *ngFor="let house of houseCategory" [value]="house._id">{{house.name_kh}}
						</mat-option>
					</mat-select>
				</mat-form-field> -->

        <mat-form-field
          appearance="outline"
          class="m-form-select show-outline no-prefix-icon"
          showErrorMessage
        >
          <mat-label>{{ "table.house_category" | translate }}</mat-label>

          <mat-select
            disableRipple
            disableOptionCentering
            formControlName="home_category"
          >
            <div class="select-content" (click)="$event.stopPropagation()">
              <div class="search-field">
                <mat-option
                  class="user-options"
                  (click)="$event.stopPropagation()"
                >
                  <mat-form-field appearance="legacy" class="m-form-input">
                    <mat-icon
                      mat-prefix
                      class="m-icon"
                      (click)="$event.stopPropagation()"
                      >search</mat-icon
                    >
                    <input
                      matInput
                      [formControl]="houseCatInputcontrol"
                      (keydown.enter)="
                        $event.stopPropagation();
                        onHouseCatSearchChange(houseCatInputcontrol.value)
                      "
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                    />
                  </mat-form-field>
                </mat-option>
                <button
                  mat-raised-button
                  class="search-button m-button-normal"
                  (click)="onHouseCatSearchChange(houseCatInputcontrol.value)"
                >
                  <mat-icon class="normal-icon">search</mat-icon>
                  {{ "form-field.search" | translate }}
                </button>
              </div>

              <div class="select-list" (scroll)="onScroll($event, 'house_cat')">
                <ng-container *ngIf="houseCategory.length > 0">
                  <mat-option
                    class="option-list"
                    *ngFor="let house of houseCategory"
                    [value]="house._id"
                    (click)="onChangeHouseCategory(house)"
                  >
                    <p class="option-name">
                      <span>{{ getHomeCatName(house) }}</span>
                    </p>
                  </mat-option>
                </ng-container>

                <ng-container *ngIf="houseCategory.length == 0">
                  <div class="no-data">
                    <img src="../../../../../assets/image/no-data.svg" alt="" />
                    <p>{{ "table.no-data" | translate }}</p>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-select>

          <p
            class="selected-option"
            *ngIf="selectedHouseCat && homeTypeForm.get('home_category').value"
          >
            <span>{{ getHomeCatName(selectedHouseCat) }}</span>
          </p>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <!-- <mat-form-field appearance="outline" class="m-form-select show-outline no-prefix-icon" showErrorMessage>
					<mat-label>{{'table.house_type' | translate}}</mat-label>
					<mat-select formControlName="home_type">
						<mat-option *ngFor="let house of houseType" [value]="house._id" (click)="onChangeHouseType(house)">
							{{house.code}} - {{house.amount}} - {{house.name_kh}}
						</mat-option>
					</mat-select>
				</mat-form-field> -->

        <mat-form-field
          appearance="outline"
          class="m-form-select show-outline no-prefix-icon"
          showErrorMessage
        >
          <mat-label>{{ "table.house_type" | translate }}</mat-label>

          <mat-select
            disableRipple
            disableOptionCentering
            formControlName="home_type"
          >
            <div class="select-content" (click)="$event.stopPropagation()">
              <div class="search-field">
                <mat-option
                  class="user-options"
                  (click)="$event.stopPropagation()"
                >
                  <mat-form-field appearance="legacy" class="m-form-input">
                    <mat-icon
                      mat-prefix
                      class="m-icon"
                      (click)="$event.stopPropagation()"
                      >search</mat-icon
                    >
                    <input
                      matInput
                      [formControl]="houseTypesInputControl"
                      (keydown.enter)="
                        $event.stopPropagation();
                        onHouseTypeSearchChange(houseTypesInputControl.value)
                      "
                      (keydown.space)="$event.stopPropagation()"
                      (click)="$event.stopPropagation()"
                    />
                  </mat-form-field>
                </mat-option>
                <button
                  mat-raised-button
                  class="search-button m-button-normal"
                  (click)="
                    onHouseTypeSearchChange(houseTypesInputControl.value)
                  "
                >
                  <mat-icon class="normal-icon">search</mat-icon>
                  {{ "form-field.search" | translate }}
                </button>
              </div>

              <div
                class="select-list"
                (scroll)="onScroll($event, 'house_type')"
              >
                <ng-container *ngIf="houseType.length > 0">
                  <mat-option
                    class="option-list"
                    *ngFor="let house of houseType"
                    [value]="house._id"
                    (click)="selectedHouseType = house; onChangeHouseType()"
                  >
                    <p class="option-name">
                      <span>{{ getHomeTypeName(house) }}</span>
                    </p>
                  </mat-option>
                </ng-container>

                <ng-container *ngIf="houseType.length == 0">
                  <div class="no-data">
                    <img src="../../../../../assets/image/no-data.svg" alt="" />
                    <p>{{ "table.no-data" | translate }}</p>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-select>

          <p
            class="selected-option"
            *ngIf="selectedHouseType && homeTypeForm.get('home_type').value"
          >
            <span>{{ getHomeTypeName(selectedHouseType) }}</span>
          </p>
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="m-form-select show-outline no-prefix-icon"
          showErrorMessage
        >
          <mat-label>{{ "table.number_of_room" | translate }}</mat-label>
          <input matInput type="text" formControlName="number_of_rooms" />
        </mat-form-field>
      </div>

      <div class="col-md-8">
        <mat-form-field
          appearance="outline"
          class="m-form-select show-outline no-prefix-icon"
          showErrorMessage
        >
          <mat-label
            >{{ "table.montly_fee" | translate }} ({{
              "currency.dollar" | translate
            }})</mat-label
          >
          <input matInput type="text" formControlName="montly_fee" />
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #uploadDocFormTemplate>
  <div [formGroup]="homeReference">
    <div class="stand-alone-msg-container mt-3">
      <p id="bot_error">
        * {{ "message.require_at_least_one_file" | translate }}
      </p>
    </div>

    <!--------------------------- ឯកសារអត្តសញ្ញាណប័ណ្ណ --------------------------->
    <div class="section mr-2">
      <div class="section-header">
        <p><mat-icon>person</mat-icon> {{ "file_type.id_card" | translate }}</p>
        <button
          mat-raised-button
          class="m-button primary"
          (click)="id_file_control.value = null; id_file_control.click()"
        >
          <mat-icon class="svg-icon" svgIcon="upload_icon"></mat-icon>
          {{ "button.upload" | translate }}
        </button>
      </div>

      <div class="normal">
        <div class="file mr-2 ml-2">
          <input
            #id_file_control
            type="file"
            (change)="onChange($event, FILE_TYPE_ENUM.ID_CARD)"
            [accept]="allowedFileTypes"
            multiple
            hidden
          />
          <div
            class="file-row"
            *ngFor="let file of idCardFiles.value; let index = index"
            @fade
          >
            <ng-container
              *ngIf="
                file?.metadata?.type == FILE_TYPE_ENUM.ID_CARD ||
                file?.type == FILE_TYPE_ENUM.ID_CARD
              "
            >
              <ng-container
                *ngTemplateOutlet="
                  fileObject;
                  context: {
                    $implicit: {
                      file: file?.files || file,
                      index: index,
                      type: file?.metadata?.type || file.type,
                      valid: true,
                      name: file?.filename || file?.files?.name
                    }
                  }
                "
              >
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!------------------------ ឯកសារវិក្កយបត្រ អគ្គិសនីកម្ពុជា ------------------------->
    <div class="section mr-2">
      <div class="section-header">
        <p>
          <mat-icon>person</mat-icon> {{ "file_type.invoice_edc" | translate }}
        </p>
        <button
          mat-raised-button
          class="m-button primary"
          (click)="inv_file_control.value = null; inv_file_control.click()"
        >
          <mat-icon class="svg-icon" svgIcon="upload_icon"></mat-icon>
          {{ "button.upload" | translate }}
        </button>
      </div>

      <div class="normal">
        <div class="file mr-2 ml-2">
          <input
            #inv_file_control
            type="file"
            (change)="onChange($event, FILE_TYPE_ENUM.INVOICE_EDC)"
            [accept]="allowedFileTypes"
            multiple
            hidden
          />

          <div
            class="file-row"
            *ngFor="let file of invoiceEdcFiles.value; let index = index"
            @fade
          >
            <ng-container
              *ngIf="
                file?.metadata?.type == FILE_TYPE_ENUM.INVOICE_EDC ||
                file?.type == FILE_TYPE_ENUM.INVOICE_EDC
              "
            >
              <ng-container
                *ngTemplateOutlet="
                  fileObject;
                  context: {
                    $implicit: {
                      file: file?.files || file,
                      index: index,
                      type: file?.metadata?.type || file.type,
                      valid: true,
                      name: file?.filename || file?.files?.name
                    }
                  }
                "
              >
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!----------------------------- រូបភាពទីតាំង ------------------------------->
    <div class="section mr-2">
      <div class="section-header">
        <p>
          <mat-icon>person</mat-icon> {{ "file_type.home_image" | translate }}
        </p>
        <button
          mat-raised-button
          class="m-button primary"
          (click)="home_image_file.value = null; home_image_file.click()"
        >
          <mat-icon class="svg-icon" svgIcon="upload_icon"></mat-icon>
          {{ "button.upload" | translate }}
        </button>
      </div>

      <div class="normal">
        <div class="file mr-2 ml-2">
          <input
            #home_image_file
            type="file"
            (change)="onChange($event, FILE_TYPE_ENUM.HOME_IMAGE)"
            [accept]="allowedFileTypes"
            multiple
            hidden
          />

          <div
            class="file-row"
            *ngFor="let file of homeImageFiles.value; let index = index"
            @fade
          >
            <ng-container
              *ngIf="
                file?.metadata?.type == FILE_TYPE_ENUM.HOME_IMAGE ||
                file?.type == FILE_TYPE_ENUM.HOME_IMAGE
              "
            >
              <ng-container
                *ngTemplateOutlet="
                  fileObject;
                  context: {
                    $implicit: {
                      file: file?.files || file,
                      index: index,
                      type: file?.metadata?.type || file.type,
                      valid: true,
                      name: file?.filename || file?.files?.name
                    }
                  }
                "
              >
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!----------------------------- ឯកសារយោង ------------------------------->
    <div class="section mr-2">
      <div class="section-header">
        <p>
          <mat-icon>person</mat-icon> {{ "file_type.support_doc" | translate }}
        </p>
        <button
          mat-raised-button
          class="m-button primary"
          (click)="sup_file_control.value = null; sup_file_control.click()"
        >
          <mat-icon class="svg-icon" svgIcon="upload_icon"></mat-icon>
          {{ "button.upload" | translate }}
        </button>
      </div>

      <div class="normal">
        <div class="file mr-2 ml-2">
          <input
            #sup_file_control
            type="file"
            (change)="onChange($event, FILE_TYPE_ENUM.SUPPORTING_DOCUMENT)"
            [accept]="allowedFileTypes"
            multiple
            hidden
          />

          <div
            class="file-row"
            *ngFor="let file of supportDocFiles.value; let index = index"
            @fade
          >
            <ng-container
              *ngIf="
                file?.metadata?.type == FILE_TYPE_ENUM.SUPPORTING_DOCUMENT ||
                file?.type == FILE_TYPE_ENUM.SUPPORTING_DOCUMENT
              "
            >
              <ng-container
                *ngTemplateOutlet="
                  fileObject;
                  context: {
                    $implicit: {
                      file: file?.files || file,
                      index: index,
                      type: file?.metadata?.type || file.type,
                      valid: true,
                      name: file?.filename || file?.files?.name
                    }
                  }
                "
              >
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!------------------------------- ផ្សេងៗ --------------------------------->
    <!-- <div class="section mr-2">
			<div class="section-header">
				<p>
					<mat-icon>person</mat-icon> {{ 'file_type.other' | translate }}
				</p>
			</div>

			<div class="normal">
				<div class="file  mr-2 ml-2">
					<div class="file-row" *ngFor="let file of allFiles; let index = index">
						<ng-container *ngIf="!file?.metadata?.type && !file?.type">
							<ng-container *ngTemplateOutlet="fileObject;
								context: { $implicit: {
									file: file?.files || file,
									index: index,
									type: file?.metadata?.type || file.type,
									valid: true,
									name: file?.filename || file?.files?.name
								} }">
							</ng-container>
						</ng-container>
					</div>
				</div>
			</div>
		</div> -->
  </div>
</ng-template>

<!-- template file -->
<ng-template #fileObject let-data>
  <div class="file-container" [ngClass]="{ 'file-error': !data.valid }">
    <p class="file-name">{{ data.name }}</p>

    <div class="file-action">
      <button
        mat-icon-button
        class="preview"
        matTooltip="{{ 'button.view' | translate }}"
        (click)="getDocument(data.file); $event.stopPropagation()"
        *ngIf="data.valid"
      >
        <mat-icon>visibility</mat-icon>
      </button>
      <button
        mat-icon-button
        class="remove"
        matTooltip="{{ 'button.delete' | translate }}"
        (click)="
          onRemoveFile(data.file, data.type, data.index);
          $event.stopPropagation()
        "
      >
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #coordinateFormTemplate>
  <div [formGroup]="mapForm">
    <div class="form-row">
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="m-form-select show-outline no-prefix-icon"
        >
          <mat-label
            >{{ "table.latitude" | translate }}
            {{ "form-field.required_sign" | translate }}</mat-label
          >
          <input
            matInput
            formControlName="latitude"
            (keypress)="keypressCoordinate($event)"
            (change)="
              onChangeNumber(mapForm.get('latitude'), $event);
              onChangeCoordinate()
            "
          />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="m-form-select show-outline no-prefix-icon"
        >
          <mat-label
            >{{ "table.longitude" | translate }}
            {{ "form-field.required_sign" | translate }}</mat-label
          >
          <input
            matInput
            formControlName="longitude"
            (keypress)="keypressCoordinate($event)"
            (change)="
              onChangeNumber(mapForm.get('longitude'), $event);
              onChangeCoordinate()
            "
          />
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="map-container" tabindex="-1">
          <google-map
            [options]="map_options"
            (mapClick)="onClickMap($event)"
            height="400px"
          >
            <map-marker
              #marker="mapMarker"
              *ngFor="let markerPosition of markerPositions"
              [position]="markerPosition"
              [options]="markerOptions"
            >
            </map-marker>
          </google-map>
        </div>
      </div>
    </div>
  </div>
</ng-template>

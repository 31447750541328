import { Directive, ElementRef, HostListener } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[trimInput]'
})
export class TrimInputDirective {
	constructor(
		private _control: NgControl,
		private _el: ElementRef<HTMLInputElement>,
	) { }

	@HostListener("paste", ["$event"])
	onPaste(e: ClipboardEvent) {
	  const data = e.clipboardData?.getData("text");
	  this.insertValue(data!.replace(/\s{2,}/g, ""));
	  return false;
	}
  
	@HostListener("drop", ["$event"])
	onDrop(e: DragEvent) {
	  const data = e.dataTransfer?.getData("text");
	  this.insertValue(data!.replace(/\s{2,}/g, ""));
	  return false;
	}

	@HostListener("change", ["$event"])
	onChange() {
		this.control.setValue(this._trim(this.value).trim());
	}

	@HostListener("click", ["$event"])
	onClickFocus() {
		this.control.setValue(this._trim(this.value).trim());
	}

	get control() {
		return this._control.control as AbstractControl;
	}

	get value() {
		return this.control.value || "";
	}

	private insertValue(value: string) {
		const selectionStart = this._el.nativeElement.selectionStart || 0;
		const selectionEnd = this._el.nativeElement.selectionEnd || 0;
		const text = this._trim(this.value.slice(0, selectionStart) + value + this.value.slice(selectionEnd));
		this.control.setValue(text);
		const selection = selectionStart + value.length;
		this._el.nativeElement.setSelectionRange(selection, selection, "forward");
	}

	private _trim(str: string): string {
		return str.replace(/\s+/g, " ").trim();
	}
}


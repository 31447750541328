import { trigger, style, transition, animate } from "@angular/animations";
import { Component, Input } from "@angular/core";
import { fadeAnimation } from "src/app/animations/fade-animation";
import { environment } from "src/environments/environment";

@Component({
	selector: 'custom-error',
	template: `
	<div *ngIf="message" [@fade]="increment" [ngClass]="{'login': isFormLogin, 'normal': !isFormLogin}">
		<mat-error>{{message}}</mat-error>
	</div>
	`,
	styleUrls: ['./mat-error-comp.scss'],
	animations: [
		fadeAnimation,
		trigger('animation', [
			transition(':increment', [
				style({ opacity: 0, position: 'relative' }),
				animate('200ms ease-in', style({ opacity: 1 })),
			]),
			transition(':enter', [
				style({ opacity: 0, transform: 'translateY(-1rem)' }),
				animate('200ms ease-in', style({ opacity: 1, transform: 'translateY(0)' })),
			]),
			transition(':leave', [
				animate('200ms ease-out', style({ opacity: 0, transform: 'translateY(-1rem)' }))
			])])
	]

})
export class MatErrorComponent {
	message: string;
	increment: number = 0;
	isFormLogin: boolean = false;
	environtment = environment

	@Input()
	set isLogin(value: boolean) {
		this.isFormLogin = value ? value : false;
	}

	@Input()
	set error(value: any) {
		if (value) {
			if (this.message != value) this.increment++;
		}
		this.message = value;
	}
}
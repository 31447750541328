import { PipeModule } from './../pipe/pipe.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel/carousel.component';
import { UploadMultipleFilesComponent } from './upload-multiple-files/upload-multiple-files.component';
import { DialogAssignAllocateComponent } from './dialog-assign-allocate/dialog-assign-allocate.component';
import { FileViewerDragDropComponent } from './file-viewer-drag-drop/file-viewer-drag-drop.component';
import { SelectLocationTreeComponent } from './select-location-tree/select-location-tree.component';
import { GlobalConfirmDialogComponent } from './global-confirm-dialog/global-confirm-dialog.component';
import { DialogStaffAssignmentComponent } from './dialog-staff-assignment/dialog-staff-assignment.component';
import { DialogSetPostStatusComponent } from './dialog-set-post-status/dialog-set-post-status.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NativeDateModule } from '@angular/material/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { CustomPaginatorComponent } from './custom-paginator/custom-paginator.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormSelectUserComponent } from './form-select-user/form-select-user.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { RefreshTokenDialogComponent } from './refresh-token-dialog/refresh-token-dialog.component';
import { DialogShowProgressComponent } from './dialog-show-progress/dialog-show-progress.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LocalizeSnackbarModule } from './localize-snackbar/localize-snackbar.module';
import { DialogPrintProgressComponent } from './dialog-print-progress/dialog-print-progress.component';


@NgModule({
	declarations: [
		CarouselComponent,
		UploadMultipleFilesComponent,
		DialogAssignAllocateComponent,
		FileViewerDragDropComponent,
		SelectLocationTreeComponent,
		GlobalConfirmDialogComponent,
		DialogStaffAssignmentComponent,
		DialogSetPostStatusComponent,
		ImageViewerComponent,
		PdfViewerComponent,
		DateTimePickerComponent,
		CustomPaginatorComponent,
		FormSelectUserComponent,
		RefreshTokenDialogComponent,
		DialogShowProgressComponent,
		DialogPrintProgressComponent
	],
	imports: [
		CommonModule,
		MatIconModule,
		MatTooltipModule,
		MatDialogModule,
		MatIconModule,
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		FormsModule,
		MatSelectModule,
		ReactiveFormsModule,
		DragDropModule,
		MatTreeModule,
		MatCheckboxModule,
		PipeModule,
		MatDatepickerModule,
		NativeDateModule,
		OverlayModule,
		A11yModule,
		MatPaginatorModule,
		DirectivesModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		LocalizeSnackbarModule,
		TranslateModule.forChild()
	],
	exports: [
		CarouselComponent,
		SelectLocationTreeComponent,
		DateTimePickerComponent,
		UploadMultipleFilesComponent,
		CustomPaginatorComponent,
		FormSelectUserComponent
	]
})
export class SharedModule { }

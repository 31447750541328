<div class="dialog-container">
	<div class="dialog-header">
		<h3 mat-dialog-title>{{ 'dialog.confirm.alert' | translate }} <mat-icon>error_outline</mat-icon>
		</h3>
	</div> 

    <mat-dialog-content>
        <p class="desc" *ngIf="data?.is_expired_token">
            {{ 'message.expires_in' | translate }}
            <span>{{ countdownTimer }}</span>
            {{ 'message.minute_left' | translate }}
        </p>
        <p class="desc">{{ 'message.alert_continue_work' | translate }}</p>
	</mat-dialog-content>

    <mat-dialog-actions align="center">
		<button mat-raised-button class="m-button-normal" [mat-dialog-close]="false">
			<mat-icon class="svg-icon" svgIcon="close_icon"></mat-icon> {{('button.cancel' | translate)}}
		</button>

        <button mat-raised-button class="m-button-primary approved" (click)="onConfirmClick()">
            {{ 'button.confirm' | translate }}
            <mat-icon class="svg-icon" svgIcon="done_icon"></mat-icon>
        </button>
	</mat-dialog-actions>
</div>
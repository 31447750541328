import { AfterContentChecked, AfterContentInit, AfterViewInit, Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';

@Directive({
	selector: '[preventAutoComplete]'
})
export class PreventAutocompleteDirective implements AfterViewInit {

	constructor(private control: NgControl) { }

	ngAfterViewInit() {
		setTimeout(() => {
			this.control.control.reset();
			this.control.control.markAsUntouched();
			this.control.control.markAsPristine();
		}, 1000);
	}

}

import { IStatus } from '../../../models/interface/int-status';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { Status } from 'src/app/models/test/interface/int-status';

@Component({
  selector: 'app-dialog-set-post-status',
  templateUrl: './dialog-set-post-status.component.html',
  styleUrls: ['./dialog-set-post-status.component.scss']
})
export class DialogSetPostStatusComponent {


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IStatus
  ) { }
}

import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_ENUM } from '../models/enum/local-storage.enum';
import { SESSION_ENUM } from '../models/enum/session-storage.enum';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { LocalizeSnackbarComponent } from '../modules/shared/localize-snackbar/localize-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private ss: SessionStorageService,
    private lc: LocalStorageService,
    private snackbar: MatSnackBar
  ) { }


  /**
   * --------------------------------------------------------------------
   * LOCALSTORAGE
   * --------------------------------------------------------------------
   */

  getToken() {
    return this.lc.getDecryption(LOCAL_STORAGE_ENUM.TOKEN);
  }

  getRefreshToken() {
    return this.lc.getDecryption(LOCAL_STORAGE_ENUM.REFRESH_TOKEN);
  }

  getExpiredAt() {
    return this.lc.getDecryption(LOCAL_STORAGE_ENUM.EXPIRED_AT);
  }

  getUserName() {
    return this.lc.getDecryption(LOCAL_STORAGE_ENUM.USERNAME);
  }

  getPermissions() {
    return this.lc.getArray(LOCAL_STORAGE_ENUM.PERMISSION) || [];
  }

  getLang() {
    const lang = this.lc.get(LOCAL_STORAGE_ENUM.lang);
    return lang ? lang : 'km';
  }

  setLang(lang: string) {
    this.lc.set(LOCAL_STORAGE_ENUM.lang, lang)
  }



  /**
   * --------------------------------------------------------------------
   * SESSIONSTORAGE
   * --------------------------------------------------------------------
   */

  getCurrentUserRole() {
    return this.ss.get(SESSION_ENUM.ROLE_EN);
  }

  getCurrentLoginUserID() {
    return this.ss.get(SESSION_ENUM.USERID);
  }

  getKhanName() {
    return this.ss.getSpecialCharacter(SESSION_ENUM.KHAN_NAME);
  }

  getSangkatName() {
    return this.ss.getSpecialCharacter(SESSION_ENUM.SANGKAT_NAME);
  }

  getCompany() {
    return this.ss.getSpecialCharacter(SESSION_ENUM.OPERATOR);
  }

  getCompanyName() {
    return this.ss.getSpecialCharacter(SESSION_ENUM.OPERATOR_NAME);
  }

  getCompanyID() {
    return this.ss.getSpecialCharacter(SESSION_ENUM.OPERATOR_ID);
  }

  getRoleKh() {
    return this.ss.getSpecialCharacter(SESSION_ENUM.ROLE_KH);
  }

  getRoleEn() {
    return this.ss.getSpecialCharacter(SESSION_ENUM.ROLE_EN);
  }

  getFilter() {
    return this.ss.getObject(SESSION_ENUM.FILTER);
  }

  saveFilter(filter: any) {
    this.ss.saveObject(SESSION_ENUM.FILTER, filter);
  }

  resetFilter() {
    this.ss.delete(SESSION_ENUM.FILTER);
  }

  isInWorkLocation(u_loc_id: string | any): boolean {
    let loc = this.ss.getArray(SESSION_ENUM.LIST_USER_LOCATION);
    return u_loc_id ? !!loc?.includes(u_loc_id) : false;
  }


  /**
   * --------------------------------------------------------------------
   * OTHERS
   * --------------------------------------------------------------------
   */


  /**
   * @description: Open loading snackbar with translation
   * @note: make sure 'msg' is in valid translate format
   * @=====================================================
   * 
   * @usage: this._utility.openLoadingSnackbar('example.msg')
   * @param msg
   * @param [duration=1500]
   */
  loadingSnackbar(msg: string, loading: boolean = true) {
    let snackbarRef: MatSnackBarRef<LocalizeSnackbarComponent>;

    if (loading) {
      snackbarRef = this.snackbar.openFromComponent(LocalizeSnackbarComponent, {
        panelClass: 'panelClass-loading',
        data: {
          msg: msg,
          is_loading: true
        }
      })

    } else snackbarRef.dismiss();
  }

  /**
   * @description: Open error snackbar with translation
   * @note: make sure 'msg' is in valid translate format
   * @=====================================================
   * 
   * @usage: this._utility.openErrorSnackbar('example.msg')
   * @param msg
   * @param [duration=1500]
   */
  openErrorSnackbar(msg: string, duration: number = 3000) {
    this.snackbar.openFromComponent(LocalizeSnackbarComponent, {
      duration: duration,
      horizontalPosition: 'center',
      panelClass: 'panelClass-error',
      data: {
        msg: msg,
      }
    })
  }


  /**
   * @description: Open success snackbar with translation
   * @note: make sure 'msg' is in valid translate format
   * @=====================================================
   * 
   * @usage: this._utility.openSuccessSnackbar('example.msg')
   * @param msg
   * @param [duration=1500]
   */
  openSuccessSnackbar(msg: string = 'req_status.success', duration: number = 3000) {
    this.snackbar.openFromComponent(LocalizeSnackbarComponent, {
      duration: duration,
      horizontalPosition: 'center',
      panelClass: 'panelClass-success',
      data: {
        msg: msg
      }
    })
  }
}

<div class="dialog-container">
	<div mat-dialog-title class="title-dialog">
		<div class="title-content">
			<h3>{{'root.menu.action.change_psw' | translate}}</h3>

			<div class="msg-container">
				<p>{{'form-field.sign' | translate}} '{{'form-field.required_sign' | translate}}' {{'form-field.required_msg' | translate}}</p>
			</div>
		</div>
	</div>

	<mat-dialog-content class="dialog-content">
		<form [formGroup]="changePassForm">
			<div class="form-row">
				<div class="col-12 mb-2">
					<mat-form-field appearance="outline" class="m-form-input show-outline no-prefix-icon w-100" showErrorMessage>
						<mat-label>{{'form-field.old_psw' | translate}}</mat-label>
						<input matInput placeholder="{{'form-field.old_psw' | translate}}" [type]="hide[0] ? 'password' : 'text'" formControlName="old_password"
							required />
						<mat-icon (click)="hide[0] = !hide[0]" matSuffix>{{ hide[0] ? "visibility_off" : "visibility" }}</mat-icon>
					</mat-form-field>
				</div>
			</div>

			<div class="form-row">
				<div class="col-12 mb-2">
					<mat-form-field appearance="outline" class="m-form-input show-outline no-prefix-icon w-100" showErrorMessage>
						<mat-label>{{'form-field.new_psw' | translate}}</mat-label>
						<input matInput placeholder="{{'form-field.new_psw' | translate}}" [type]="hide[1] ? 'password' : 'text'" formControlName="new_password"
							required />
						<mat-icon (click)="hide[1] = !hide[1]" matSuffix>{{ hide[1] ? "visibility_off" : "visibility" }}</mat-icon>
						<!-- <mat-error *ngIf="changePassForm.get('new_password').hasError('notMatch')">{{'message.psw_not_match' | translate}}</mat-error> -->
					</mat-form-field>
				</div>
			</div>

			<div class="form-row">
				<div class="col-12 mb-2">
					<mat-form-field appearance="outline" class="m-form-input show-outline no-prefix-icon w-100" showErrorMessage>
						<mat-label>{{'form-field.conf_new_psw' | translate}}</mat-label>
						<input matInput placeholder="{{'form-field.conf_new_psw' | translate}}" [type]="hide[2] ? 'password' : 'text'" formControlName="confirm_new_password"
							required />
						<mat-icon (click)="hide[2] = !hide[2]" matSuffix>{{ hide[2] ? "visibility_off" : "visibility" }}</mat-icon>
						<!-- <mat-error *ngIf="changePassForm.get('confirm_new_password').hasError('notMatch')">{{'message.psw_not_match' | translate}}</mat-error> -->
					</mat-form-field>
				</div>
			</div>
		</form>
	</mat-dialog-content>

	<mat-dialog-actions align="end" class="dialog-actions">
		<div class="action-buttons">
			<button mat-raised-button class="m-button-normal" matDialogClose>
				<mat-icon class="svg-icon" svgIcon="close_icon"></mat-icon> {{'button.cancel' | translate}}
			</button>
			<button mat-raised-button class="m-button-primary" [ngClass]="{'m-button-disabled': changePassForm.invalid}" [disabled]="!changePassForm.valid" (click)="onSubmit()">
				<mat-icon class="svg-icon" [svgIcon]="changePassForm.invalid ? 'save_no_color_icon' : 'save_icon'"></mat-icon>
				{{'button.save' | translate}}
			</button>
		</div>
	</mat-dialog-actions>
</div>
import { Pipe, PipeTransform } from "@angular/core";
import { PERMISSION_ENUM } from "src/app/models/enum/permissions.enum";

@Pipe({
  name: "roleTranslate",
})
export class RoleTranslatePipe implements PipeTransform {
  main_permission_keys = ["CREATE", "READ", "UPDATE", "DELETE", "APPROVE"];
  exceptions = [
    // 'CHECKING_HOUSE_CREATE_AUTO_APPROVED',
    PERMISSION_ENUM.REPORT_CHECKING_HOUSE_READ,
    PERMISSION_ENUM.REPORT_RECYCLE_READ,
    PERMISSION_ENUM.REPORT_BANK_PAYMENT_READ,
    PERMISSION_ENUM.REPORT_DAILY_SETTLEMENT_READ,
    PERMISSION_ENUM.REPORT_NEW_INVOICE_READ,
    PERMISSION_ENUM.REPORT_ADJUSTMENT_READ,
    PERMISSION_ENUM.REPORT_REFUND_AND_TRANSFER_READ,
    PERMISSION_ENUM.REPORT_DELETED_INVOICE_READ,
    PERMISSION_ENUM.REPORT_DEBT_INVOICE_READ,
    PERMISSION_ENUM.REPORT_NEW_HOME_READ,

    PERMISSION_ENUM.READ_INVOICE_BY_MONTH,
    PERMISSION_ENUM.READ_DEBT_BY_MONTH,
    PERMISSION_ENUM.READ_AGING_INVOICE,
    PERMISSION_ENUM.READ_PAYMENT_SUMMARY,
    PERMISSION_ENUM.RECEIVE_CASH,
    PERMISSION_ENUM.INVOICE_ADJUST_BY_EXCEL,
    PERMISSION_ENUM.HOME_VERIFY_BY_EXCEL,

    PERMISSION_ENUM.CHECKING_HOUSE_CREATE_BY_EXCEL,

    PERMISSION_ENUM.READ_CASHIER_PAYMENT,
    // PERMISSION_ENUM.READ_CASHIERS_PAYMENT_SUMMARY,

    PERMISSION_ENUM.CASH_TRANSACTION_CREATE,
    PERMISSION_ENUM.CASH_TRANSACTION_READ,
  ];

  transform(value: string): string {
    let result = this.main_permission_keys.find((key: string) => value.includes(key));
    let exception = this.exceptions.find((key: string) => value.includes(key));

    return `pms_child_code.${exception || result || value}`;
  }
}

import { ICommonResponse } from '../models/response/common-response';
import { IHomeCategory } from '../models/interface/int-home-category';
import { IResponseWithPagination } from '../models/response/response-with-pagination';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequestService } from './request.service';
import { SubmitFailService } from './submit-fail.service';

@Injectable({
  providedIn: 'root'
})
export class HouseCategoryService extends SubmitFailService {

  constructor(
    private requestService: RequestService
  ) { super(); }


  getAll(page: number = 1, limit: number = 100, searchQuery: string = '', is_active?: boolean): Observable<IResponseWithPagination<IHomeCategory>> {
    const url = environment.baseUrl + '/back-office/home-categories';
    return this.requestService.getJSON(url, Object.assign({page, limit, searchQuery, is_active})).pipe(
      map((res: IResponseWithPagination<IHomeCategory>) => {
        let filterData = res.data.data;
        if (is_active != null && is_active != undefined) {
          filterData = filterData.filter(item => item.is_active);
        }

        res.data.data = filterData;
        return res;
      })
    );
  }
  
  getByID(id: string): Observable<ICommonResponse<IHomeCategory>> {
    const url = environment.baseUrl + '/back-office/home-categories' + id;
    return this.requestService.getJSON<any>(url);
  }

  create(data: any): Observable<ICommonResponse<IHomeCategory>> {
    const url = environment.baseUrl + '/back-office/home-categories/create';
    return this.requestService.postJSON(url, data);
  }

  update(data: any, id: string): Observable<ICommonResponse<IHomeCategory>> {
    const url = environment.baseUrl + '/back-office/home-categories/' + id + '/update';
    return this.requestService.putJSON(url, data);
  }

  updateStatus(data: any, id: string): Observable<ICommonResponse<IHomeCategory>> {
    const url = environment.baseUrl + '/back-office/home-categories/' + id + '/active';
    return this.requestService.putJSON(url, data);
  }

  delete(id: any) {
    const url = environment.baseUrl + '/back-office/home-categories/' + id + '/delete';
    return this.requestService.delete<any>(url);
  }
}

import { CONFIRM_TYPE, GlobalConfirmDialogComponent } from 'src/app/modules/shared/global-confirm-dialog/global-confirm-dialog.component';
import { ChangePasswordComponent } from './../../modules/change-password/components/change-password/change-password.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Router, RouterOutlet } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CBreakPointObserver } from 'src/app/helpers/class/break-point-observer';
import { takeUntil } from 'rxjs/operators';
import { PERMISSION_ENUM } from 'src/app/models/enum/permissions.enum';
import { HouseVerificationService } from 'src/app/services/house-verification.service';
import { StaffService } from 'src/app/services/staff.service';
import { UtilService } from 'src/app/services/util.service';
import { Subject } from 'rxjs';
import { routeAnimations } from 'src/app/animations/route-animation';

@Component({
	selector: 'app-root',
	templateUrl: './root.component.html',
	styleUrls: ['./root.component.scss'],
	animations: [routeAnimations]
})
export class RootComponent extends CBreakPointObserver implements OnInit, OnDestroy {
	openSideNav = true;
	bottomShadow = false;
	isScrolled = false;
	houseData: any;

	username: string;
	currentUserRole: string;
	khanName: string;
	sankatName: string;
	khanNames = [];
	permissions = [];
	role_kh: string;
	role_en: string;
	companyName: string;
	companyID: string;

	/**
	 * New Variables
	 */
	hideText = false;
	phone = false;
	hideMenuBar = false;
	screenWidth: number;
	lang: string = 'km';

	private _destroyed = new Subject<void>();
	constructor(
		private dialog: MatDialog,
		private translate: TranslateService,
		private houseService: HouseVerificationService,
		private util: UtilService,
		private staffService: StaffService,
		private breakPointObserver: BreakpointObserver,
		private router: Router
	) {
		super(breakPointObserver);
		this.screenWidth = window.innerWidth;
		window.onresize = () => { this.screenWidth = window.innerWidth };
		if (this.screenWidth <= 820) this.hideText = true;

		translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.lang = event.lang;
		});

		this.houseService.eventEmitHouseInfo.pipe(takeUntil(this._destroyed)).subscribe(res => this.houseData = res);
	}

	ngOnInit(): void {
		this.username = this.util.getUserName();
		this.permissions = this.util.getPermissions();

		this.currentUserRole = this.util.getCurrentUserRole();
		this.khanName = this.util.getKhanName();
		this.sankatName = this.util.getSangkatName();
		this.companyName = this.util.getCompanyName();
		this.companyID = this.util.getCompanyID();
		this.role_kh = this.util.getRoleKh();
		this.role_en = this.util.getRoleEn();

		this.lang = this.util.getLang();
	}

	get dynamicWidth() {
		if (this.isMobile) return 'calc(100% - 32px)';
		else if (this.isTablet || this.isLaptopLowDpi || this.isLaptopMediumDpi || this.isLaptopHighDpi) return 'calc(100% - 60px)';
		else return 'calc(100% - 80px)';
	}

	prepareRoute(outlet: RouterOutlet) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
	}

	toggleNav() {
		this.hideText = !this.hideText;
	}

	logout() {
		this.dialog.open(GlobalConfirmDialogComponent, {
			panelClass: 'changeBackgroundColor',
			width: '500px',
			autoFocus: false,
			id: 'alert-dialog',
			data: {
				is_reject: true,
				is_logout: true,
				confirm_type: CONFIRM_TYPE.NORMAL,
				title: this.translate.instant('dialog.confirm.alert'),
				confirm_desc: this.translate.instant('message.sign_out')
			}

		}).afterClosed().subscribe(res => {
			if (res) {
				sessionStorage.clear();
				localStorage.clear();
				this.staffService.onLogOut();
				this.router.navigateByUrl('login');
			}
		})
	}

	changePSW() {
		this.dialog.open(ChangePasswordComponent, {
			width: '500px',
			autoFocus: false,
			disableClose: true,
			data: {
				is_user: false
			}
		});
	}

	onMenuScroll(e: any): void {
		this.isScrolled = e.target.scrollTop > 0;
	}

	switchLangToKM() {
		if (this.getLang() === 'en') {
			this.setLang('km');
			this.translate.use(this.getLang());
			this.lang = 'km';
		}
	}

	switchLangToEN() {
		if (this.getLang() === 'km') {
			this.setLang('en');
			this.translate.use(this.getLang());
			this.lang = 'en';
		}
	}

	getLang(): string {
		return this.util.getLang() as string;

		// let lang = this.localStorageService.get(LOCAL_STORAGE_ENUM.lang);
		// if (!lang) lang = 'km';
		// return lang;
	}

	setLang(lang: string) {
		this.util.setLang(lang);
		this.translate.use(this.getLang());
	}

	get isOperator() {
		return this.currentUserRole != 'admin' && this.companyID && this.permissions.includes(PERMISSION_ENUM.STAFF_CREATE)
	}

	ngOnDestroy(): void {
		this._destroyed.next();
		this._destroyed.complete();
	}
}

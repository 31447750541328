<div class="container-xl mb-4">
    <h1 class="text-center mt-3">
        <b>លក្ខខណ្ឌនៃការប្រើប្រាស់កម្មវិធី</b>
    </h1>

    <section>
        <h3>លក្ខខណ្ឌនៃការប្រើប្រាស់កម្មវិធី គឺជាកិច្ចសន្យា ឬកិច្ចព្រមព្រៀងរវាងសហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ និង អតិថិជនក្នុងការ ប្រើប្រាស់សេវាកម្មនេះ ។</h3>

        <h3>សូមអានកិច្ចសន្យានេះ ឲ្យបានយល់ច្បាស់ មុនចុច “យល់ព្រម” ចុះឈ្មោះប្រើប្រាស់សេវានេះ ។ នៅពេលលោក-លោកស្រី បានចុច “យល់ព្រម” មានន័យថា លោក-លោកស្រី បានយល់ព្រមគ្រប់លក្ខ ខណ្ឌដែលបានចែងនៅក្នុងកិច្ចសន្យានេះ និងទទួលខុសត្រូវ លើការប្រើប្រាស់កម្មវិធី EWP APP របស់សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ ។ សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ អាចកែប្រែលក្ខខណ្ឌ គ្រប់ពេលដោយអនុលោម តាមច្បាប់នៃព្រះរាជាណាចក្រកម្ពុជា ។ សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ នឹងជូន ដំណឹងទៅលោក-លោកស្រី ក្នុងករណីដែលការកែប្រែនេះ ធ្វើឲ្យប៉ះ ពាល់ដល់សិទ្ធ និងកាតព្វកិច្ចរបស់ លោក-លោកស្រី ។</h3>

        <h3>នៅក្នុងលក្ខខណ្ឌនៃការប្រើប្រាស់នេះ ពាក្យថា “លោក-លោកស្រី" “របស់លោក-លោកស្រី” សំដៅដល់អតិថិជន ឬម្ចាស់គណនីដែល ប្រើប្រាស់សេវាកម្មផ្សេងៗ តាមរយៈអនឡាញ របស់សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ ។</h3>

        <h3>ពាក្យថា “យើង” “របស់យើង” សំដៅដល់សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ ។</h3>
    </section>


    <section>
        <h2 class="section-title"><b>1. ការពិពណ៌នាពីសេវាកម្ម</b></h2>
        <h3 class="sub-title"><b>1.1. កម្មវិធី EWP APP ប្រើបណ្តាញទូរគមនាគមន៍របស់ប្រតិបត្តិ ការទូរស័ព្ទចល័ត ដែលអាចឲ្យលោក-លោកស្រី ប្រើប្រាស់ងាយស្រួលពិនិត្យព័ត៌មាន និងស្នើសុំសេវាកម្មផ្សេងៗរបស់សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ។</b></h3>
        <h3 class="sub-title"><b>1.2. មុខងារសំខាន់ៗ របស់កម្មវិធី EWP APP ៖</b></h3>
        <ul>
            <li><h3>ពិនិត្យព័ត៌មានលម្អិតពីវិក្កយបត្រ និងការទូទាត់</h3></li>
            <li><h3>ព័ត៌មានទូទៅ</h3></li>
            <li><h3>តារាងទូរស័ព្ទទំនាក់ទំនង</h3></li>
        </ul>
    </section>


    <section>
        <h2 class="section-title"><b>2. ការទទួលស្គាល់របស់អ្នកប្រើប្រាស់</b></h2>
        <h3 class="sub-title"><b>2.1. កាតព្វកិច្ចអ្នកប្រើប្រាស់</b></h3>
        <ul>
            <li><h3>អ្នកជួលផ្ទះ, បន្ទប់ជួល, ម្ចាស់ផ្ទះដែលលក់អចលនៈទ្រព្យ ឲ្យម្ចាស់ថ្មី ត្រូវលុបលេខសំគាល់អតិថិជន(ID) ហើយត្រូវជូនដំណឹងទៅម្ចាស់ទីតាំងថ្មីបន្ត ។</h3></li>
            <li><h3>ក្នុងករណីម្ចាស់ទីតាំងចាស់មិនបានលុបលេខសំគាល់អតិថិជន ឬផ្តាច់សេវាផ្សេងៗ នោះលោក-លោកស្រី ត្រូវទទួលខុសត្រូវចំពោះការខាតបង់ផ្សេងៗដូចជាការ ការពិន័យការប្រាក់ រួមទាំងផលប៉ះពាល់ផ្សេងៗទៀត។ល។ ប្រសិនបើម្ចាស់ទីតាំងថ្មីមានឯកសារ ឬលិខិតបញ្ជាក់គ្រប់គ្រាន់ សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញសូមរក្សាសិទ្ធិក្នុងការផ្តាច់គ្រប់ព័ត៌មាន ពីលេខសំគាល់ អតិថិជន(ID) ចាស់មកអ្នកថ្មី។</h3></li>
        </ul>

        <h3 class="sub-title"><b>2.2. ការជូនដំណឹង</b></h3>
        <ul>
            <li><h3>សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញជូនដំណឹងតាមសារ SMS តាមរយៈការចុះ ឈ្មោះប្រើកម្មវិធី EWP APP និង Push Notification សម្រាប់ប្រតិបត្តិការមួយចំនួន ឬការទំនាក់ទំនងផ្សព្វផ្សាយ ឬការប្រកាសព័ត៌មានពីសហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ។</h3></li>
            <li><h3>សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ មិនទទួលខុសត្រូវចំពោះការបាត់បង់ ឬខូចខាតដែលបណ្តាលមកពីការធ្វេសប្រហែសរបស់អ្នក បណ្ដាលឱ្យអ្នកដទៃណាម្នាក់ ប្រើប្រាស់ SMS និងមាតិ ភាសារទាំងនោះ។</h3></li>
        </ul>
    </section>


    <section>
        <h2 class="section-title"><b>3. ថ្លៃសេវា និងបន្ទុក</b></h2>
        <h3>កម្មវិធី EWP APP អាចប្រើប្រាស់បានដោយឥតគិតថ្លៃ។ ថ្លៃសេវា និងបន្ទុកអាចអនុវត្តនៅពេលលោក-លោកស្រីធ្វើប្រតិបត្តិការមួយចំនួន ដូចជាស្នើសុំសេវាកម្មផ្សេងៗទៀតដែលមាននៅក្នុងកម្មវិធី EWP APP ដោយអនុលោមតាមលក្ខខណ្ឌជាក់លាក់របស់សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ។ ព័ត៌មានលម្អិតអំពីថ្លៃសេវានិងបន្ទុកនាពេលបច្ចុប្បន្នរបស់សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញគឺមាននៅលើ FaceBook Page របស់សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ <a href="https://www.facebook.com/emmswpp" target="_blank">https://www.facebook.com/emmswpp</a>។</h3>
    </section>


    <section>
        <h2 class="section-title"><b>4. ថ្លៃសេវា និងបន្ទុក</b></h2>
        <h3>ដើម្បីបង្ការហានិភ័យដែលអាចកើតមានពេលណាមួយ លោក- លោកស្រីនឹងមិនត្រូវបានអនុញ្ញាតឲ្យប្រើប្រាស់កម្មវិធី EWP APP ក្នុងស្ថានភាពប្រព័ន្ធប្រតិបត្តិការរបស់ឧបករណ៍លោក-លោកស្រីមិនស្ថិតក្នុងសភាពដើម ឬត្រូវបានកែប្រែ(Root ឬ Jail-break)។</h3>
    </section>


    <section>
        <h2 class="section-title"><b>5. ការតភ្ជាប់</b></h2>
        <h3>សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញមិនទទួលខុសត្រូវចំពោះអតិថិជនសម្រាប់ភាពមិនពេញលេញ ការខកខាន ការព្យួរ ឬការពន្យារណាមួយនៅក្នុងកម្មវិធី EWP APP ឡើយ រួមមាន ការបញ្ជូន ការផ្តល់សញ្ញា ឬការផ្តល់ ដំណឹងណាមួយ ដោយសារកត្តាណាមួយមិនស្ថិតក្រោមការគ្រប់ គ្រងរបស់សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ ។</h3>
    </section>


    <section>
        <h2 class="section-title"><b>6. ការថែរក្សាប្រព័ន្ធ</b></h2>
        <h3>សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញអាចបន្ថែម ឬផ្អាកមុខងារណាមួយ ឬព្យួរប្រតិបត្តិការ កម្មវិធី EWP APP ឬសេវាកម្មណាមួយរបស់ខ្លួនគ្រប់ពេលវេលា ។</h3>
    </section>


    <section>
        <h2 class="section-title"><b>7. សុវត្ថិភាព</b></h2>
        <h3 class="sub-title"><b>7.1. ការប្រុងប្រយ័ត្នជាទូទៅ</b></h3>
        <h3>លោក-លោកស្រីត្រូវទទួលខុសត្រូវចំពោះ និងប្រើប្រាស់កិច្ចប្រឹង ប្រែងខ្ពស់បំផុតដើម្បីបង្ការការប្រើប្រាស់ និងការចូលប្រើប្រាស់ដោយគ្មានការអនុញ្ញាតណាមួយនូវកម្មវិធី EWP APP នៅលើ ទូរស័ព្ទ និង/ឬ ឧបករណ៍នានារបស់លោក-លោកស្រី និងដើម្បី ការពារព័ត៌មានផ្ទាល់ខ្លួនរបស់លោក-លោកស្រី ព្រមទាំងអត្ត សញ្ញាណសុវត្ថិភាពនានាគ្រប់ពេលវេលា ។ ឧទាហរណ៍ ៖ មិនត្រូវ អនុញ្ញាត ឬ បង្ហាញ អត្តសញ្ញាណសុវត្ថិភាពរបស់លោក-លោកស្រី ទៅអ្នកផ្សេងឡើយ និងប្រុងប្រយ័ត្នដើម្បីធានាថា មិនមានអ្នកណា ម្នាក់អាចមើលឃើញលោក-លោកស្រីបញ្ចូលអត្តសញ្ញាណ សុវត្ថិភាពរបស់លោក-លោកស្រីឡើយ ។ មួយវិញទៀតលោក- លោកស្រីត្រូវធ្វើបច្ចុប្បន្នភាព និងនៅរក្សាឲ្យទាន់បច្ចុប្បន្នភាពនូវ កម្មវិធី EWP APP ក៏ដូចជាប្រព័ន្ធប្រតិបត្តិការ (OS) សម្រាប់ ឧបករណ៍ចល័ត/ទូរស័ព្ទដៃរបស់លោក-លោកស្រី ។</h3>

        <h3 class="sub-title"><b>7.2. អត្តសញ្ញាណសុវត្ថិភាព</b></h3>
        <h3>កម្មវិធី EWP APP និងសេវាកម្មរបស់កម្មវិធីនេះត្រូវឲ្យប្រើប្រាស់ អត្តសញ្ញាណសុវត្ថិភាពដែលមាន លេខទូរស័ព្ទអ្នកប្រើប្រាស់ និង លេខកូដសម្ងាត់ PIN 4 ខ្ទង់ ។ លេខកូដសម្ងាត់ មិនត្រូវមាន លក្ខណៈដូចជា ថ្ងៃខែឆ្នាំកំណើត, លេខរៀងតគ្នា(Ex: 1234), លេខ ដែលងាយទស្សទាយ(Ex: 1111), ផ្នែកនៃលេខទូរស័ព្ទរបស់អ្នក..៘</h3>
    </section>


    <section>
        <h2 class="section-title"><b>8. កំរិតនៃការទទួលខុសត្រូវ</b></h2>
        <ul>
            <li>
                <h3>សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញមិនទទួលខុសត្រូវចំពោះការខាតបង់ ណាមួយបណ្តាលមកពីការប្រើប្រាស់កម្មវិធី EWP APP របស់លោក-លោកស្រីឡើយ វាជាជម្រើសរបស់លោក- លោកស្រីក្នុងការ Download និង តម្លើងកម្មវិធីនេះ ។ </h3>
            </li>
            <li>
                <h3>លោក/លោកស្រី ត្រូវទទួលខុសត្រូវទាំងស្រុងនូវ ការខូចខាតទ្រព្យសម្បត្តិ ឬបាត់បង់ទឹកប្រាក់ក្នុងគណនី ផ្ទាល់ខ្លួន ឬគណនីផ្សេងទៀតដែលដែលប្រើប្រាស់ក្នុងការ ទូទាត់ចេញក្នុងកម្មវិធី EWP APP ព្រមទាំងទទួលខុសត្រូវ ចំពោះមុខច្បាប់ជាធរមាន ប្រសិនបើលោក/លោកស្រីបាន ប្រព្រឹត្តដោយធ្វេសប្រហែល ឬសាកល្បងប្រតិបត្តិការ ផ្សេងៗ ការលុកលុយរុករក ការក្លែងបន្លំទិន្នន័យ ការលួច បន្លំដោយផ្ទាល់ ឬដោយប្រយោល តែម្នាក់ឯងឬមានការ ចូលរួមពីអ្នកដទៃ ទោះបីជាការប្រព្រឹត្តដោយចេតនា ឬអ ចេតនាក៏ដោយ។</h3>
            </li>
            <li>
                <h3>ដោយការព្រមព្រៀងជាមួយលក្ខខណ្ឌនៃការប្រើប្រាស់ លោក/លោកស្រីបានយល់ស្របថា សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញមាន សិទ្ធិក្នុងការពន្យារពេល បង្ខាំងឬបដិសេធធ្វើប្រតិបត្តិការ ដោយមិនទទួលខុសត្រូវប្រសិនបើសហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញរកឃើញ ឬសង្ស័យថា ប្រតិបត្តិការរបស់លោកអ្នកដំណើរការខុសប្រក្រតី ។</h3>
            </li>
        </ul>
    </section>

    
    <section>
        <h2 class="section-title"><b>9. ការព្យួរ ឬការបញ្ឈប់កម្មវិធី EWP APP</b></h2>
        <h3>លោក-លោកស្រីអាចលុបចោលការប្រើប្រាស់កម្មវិធី EWP APP គ្រប់ពេលវេលា ។ សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញអាចដកការចូលប្រើប្រាស់គ្រប់ ពេលវេលាដោយមិនបាច់ជូនដំណឹងជាមុន ព្យួរ និងបញ្ឈប់ ការ ចូលប្រើប្រាស់កម្មវិធី EWP APP ឬ សេវាកម្មណាមួយរបស់ កម្មវិធីនេះដោយមូលហេតុណាមួយ ។</h3>
    </section>


    <section>
        <h2 class="section-title"><b>10. លក្ខខណ្ឌឯកជនភាព</b></h2>
        <h3>អនុលោមតាមលក្ខខណ្ឌតម្រូវ និងគោលនយោបាយផ្ទៃក្នុងរបស់ សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ លោក-លោកស្រីផ្តល់ការយល់ព្រមថា ៖</h3>
        <h3>EWP APP អាចប្រមូលព័ត៌មានផ្ទាល់ខ្លួនពីលោក-លោកស្រីទៅតាមប្រភេទស្នើសុំនៃសេវាកម្មផ្សេងៗ និងព័ត៌មានណាមួយអំពី អាកប្បកិរិយាប្រើប្រាស់សម្រាប់គោលបំណងពង្រឹងសុវត្ថិភាព និង កែលម្អបទពិសោធន៍អ្នកប្រើប្រាស់ ។</h3>
    </section>


    <section>
        <h2 class="section-title"><b>11. កម្មសិទ្ធិបញ្ញា</b></h2>
        <h3>សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ មាន ឬបានទទួលអាជ្ញាប័ណ្ណមានសុពលភាពក្នុង ការប្រើប្រាស់កម្មសិទ្ធិបញ្ញាទាំងអស់ដែលប្រើប្រាស់ពាក់ព័ន្ធនឹងការ ផ្តល់កម្មវិធី EWP APP ។ ព័ត៌មានដែលបានផ្ដល់ដល់អ្នកប្រើ ប្រាស់ ជាផ្នែកនៃកម្មវិធី EWP APP អាចត្រូវបានប្រើប្រាស់សម្រាប់តែការប្រើប្រាស់ផ្ទាល់ខ្លួន និងជាឯកសារយោងប៉ុណ្ណោះ ហើយមិនអាចបង្កើតឡើងវិញ ចែកចាយ ឬបញ្ជូនទៅកាន់នរណា ម្នាក់ ឬបានដាក់បញ្ចូលក្នុងឯកសារផ្សេងៗណាមួយដោយគ្មានការ យល់ព្រមជាមុនពី សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ ។</h3>
    </section>


    <section>
        <h2 class="section-title"><b>12. វិសោធនកម្ម</b></h2>
        <h3>លក្ខខណ្ឌនេះ សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញអាចផ្លាស់ប្ដូរគ្រប់ពេលវេលា ។ សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ នឹងផ្តល់ដំណឹងតាមរយៈ FaceBook Page របស់សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ <a href="https://www.facebook.com/emmswpp" target="_blank">https://www.facebook.com/emmswpp</a> ឬ វិធីសាស្រ្តទំនាក់ទំនងផ្សេងៗទៀត ។</h3>
    </section>


    <section>
        <h2 class="section-title"><b>13. ដំណោះស្រាយវិវាទ និងច្បាប់គ្រប់គ្រង</b></h2>
        <h3>វិវាទណាមួយកើតឡើងពី ឬពាក់ព័ន្ធនឹងលក្ខខណ្ឌជាក់លាក់ទាំងនេះ ឬការប្រើប្រាស់សេវាកម្មណាមួយ សូមទំនាក់ទំនងមកកាន់ សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ ដើម្បីដោះស្រាយស្របតាមគោលការណ៍ និងនិតិវិធី របស់សហគ្រាសគ្រប់គ្រងសំរាម សំណល់រឹងទីប្រជុំជន រាជធានីភ្នំពេញ មុនពេលឈានដល់ការប្រឈមមុខតាមរយៈតុលាការនៃព្រះរាជាណាចក្រកម្ពុជា ។</h3>
    </section>
</div>
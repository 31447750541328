import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'custom-paginator',
  templateUrl: './custom-paginator.component.html',
  styleUrls: ['./custom-paginator.component.scss']
})
export class CustomPaginatorComponent implements OnDestroy {
  @Input() isInjected: boolean = false;
  @Input() totalItem: number = 0;
  @Input() pageSize: number;
  @Input() pageIndex: number = 0;
  @Input() pageSizeOptions: number[];
  @Input() isShowFirstPageLastPage: boolean = false;
  @Input() isShowPageRange: boolean = false;
  @Input() isUpload: boolean = false;
  @Input() isShowPaginator: boolean = true;
  @Input() isShowSearch: boolean = false;
  @Input() isSearch: boolean = false;

  @Output() page = new EventEmitter();

  loading: boolean;
  sub: Subscription;
  
  @ViewChild('paginator') paginator: MatPaginator;
  constructor(private loadingService: LoadingService) {
    this.loading = this.loadingService.isLoading;
    this.sub = this.loadingService.loadingEventEmitter.subscribe(res => {
      setTimeout(() => this.loading = res, 0);
    });
  }

  onMoveFirstPage() {
    this.pageIndex = 0;
    setTimeout(() => this.page.emit({pageIndex: this.pageIndex, pageSize: this.pageSize}), 0);
  }
  
  onPageChange(event: any) {
    this.pageIndex = event.pageIndex;
    this.page.emit(event);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

<mat-form-field appearance="outline" class="m-form-date" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <mat-label>{{ name | translate }}</mat-label>
    <mat-icon matPrefix class="m-icon">date_range</mat-icon>
    <input matInput value="--/--/----  --:--:-- --"  readonly />
    <p class="input-placeholder" #IP_PLACEHOLDER>{{dateTimeValue}}</p>
</mat-form-field>

<ng-template  
    cdkConnectedOverlay 
    cdkConnectedOverlayHasBackdrop 
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop" 
    [cdkConnectedOverlayOffsetY]="-20" 
    [cdkConnectedOverlayOrigin]="trigger" 
    [cdkConnectedOverlayOpen]="isShowPanel$ | async">

    <section class="drop-down-search-panel mat-elevation-z1" style="display: flex; justify-content: space-between;">
        <div class="left">
            <mat-form-field appearance="outline" class="m-form-input show-outline no-prefix-icon">
                <mat-label>{{'form-field.time' | translate}}</mat-label>
                <mat-icon matSuffix class="m-icon">schedule</mat-icon>
                <input matInput type="time" step="2" [formControl]="timeControl">
            </mat-form-field>

            <mat-calendar
                #calendar
                [startAt]="selectedDate" 
                [selected]="selectedDate" 
                [minDate]="minDate"
                [maxDate]="maxDate">
            </mat-calendar>

            <button mat-raised-button class="m-button-primary" (click)="onSubmit()">
                <mat-icon>done</mat-icon>
                {{'button.confirm' | translate}}
            </button>
        </div>
    </section>
</ng-template>
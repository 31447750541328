import { FocusMonitor } from '@angular/cdk/a11y';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { merge, Observable, of } from 'rxjs';
import { filter, mapTo } from 'rxjs/operators';
import * as moment from 'moment';
import { MatCalendar } from '@angular/material/datepicker';

@Component({
	selector: 'app-date-time-picker',
	templateUrl: './date-time-picker.component.html',
	styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent implements OnInit {
	selectedDate = new Date();
	timeControl = new FormControl('');

	@Input() name: string;
	@Input() isStartDateTime: boolean;
	@Input() startDateAt: Date;
	@Input() maxDate: Date;
	@Input() minDate: Date;

	@Input() startTimeAt: string;
	@Input() maxTime: Date;
	@Input() minTime: Date;
	@Output() dateTimeChange = new EventEmitter();

	private isPanelVisible$: Observable<boolean>;
	private isSelectedDate$: Observable<boolean> = of(false);
	private isPanelHidden$: Observable<boolean | KeyboardEvent>;
	private escapeKeyPressed$: Observable<boolean | KeyboardEvent>;
	private isClickSubmit$: Observable<boolean> = of(false);
	isShowPanel$: Observable<boolean | KeyboardEvent>;

	@ViewChild(MatInput, { read: ElementRef, static: true })
	private inputEl: ElementRef;

	@ViewChild(CdkConnectedOverlay, { static: true })
	private connectedOverlay: CdkConnectedOverlay;

	private calendar: MatCalendar<Date>;
	@ViewChild('calendar', { static: false })
	set calendarContent(content: MatCalendar<Date>) {
		if (content) {
			this.calendar = content;
			this.calendar?.selectedChange.subscribe(res => {
				this.selectedDate = res;
				// this.isShowPanel$ = merge(this.isPanelHidden$, this.isPanelVisible$);
			})

			// this.isSelectedDate$ = this.calendar.selectedChange.pipe(mapTo(false));
			// this.isSelectedDate$.subscribe(res => {
			// 	let snd = this.isStartDateTime ? ':01' : ':00';
			// 	if (!res) this.dateTimeChange.emit({
			// 		value: this.datePipe.transform(this.selectedDate, 'YYYY-MM-dd') + ' ' + this.timeControl.value + snd,
			// 		isStartDateTime: this.isStartDateTime
			// 	});
			// })
		}
	}

	constructor(private focusMonitor: FocusMonitor) { }

	ngOnInit(): void {

		// =====================  FIRST METHODE ====================== //
		// ========================================================== //

		// this.escapeKeyPressed$ = this.connectedOverlay.overlayKeydown.pipe(filter(({ key }) => key === 'Escape'), mapTo(false));
		// this.isPanelHidden$ = merge(this.isSubmit$, this.isSelectedDate$, this.escapeKeyPressed$, this.connectedOverlay.backdropClick.pipe(mapTo(false)));
		// this.isPanelVisible$ = this.focusMonitor.monitor(this.inputEl).pipe(filter((focused) => !!focused), mapTo(true));
		// this.isShowPanel$ = merge(this.isPanelHidden$, this.isPanelVisible$);

		// this.connectedOverlay.backdropClick.pipe(mapTo(false));
		// this.connectedOverlay.backdropClick.subscribe(res => {
		// 	let snd = this.isStartDateTime ? ':01' : ':00';
		// 	if (res) this.dateTimeChange.emit({
		// 		value: this.datePipe.transform(this.selectedDate, 'YYYY-MM-dd') + ' ' + this.timeControl.value + snd,
		// 		isStartDateTime: this.isStartDateTime
		// 	});
		// })

		// let timeVal = this.startTimeAt ? this.startTimeAt : this.datePipe.transform(new Date(), 'hh:mm:ss');
		// this.timeControl.setValue(timeVal);
		// this.selectedDate = this.startDateAt ? this.startDateAt : new Date();


		// ====================  SECOND METHODE ===================== //
		// ========================================================== //

		this.isPanelHidden$ = merge(this.isClickSubmit$);
		this.isPanelVisible$ = this.focusMonitor.monitor(this.inputEl).pipe(filter((focused) => !!focused), mapTo(true));
		this.isShowPanel$ = merge(this.isPanelHidden$, this.isPanelVisible$);


		let timeVal = this.startTimeAt ? this.startTimeAt : moment().format('HH:mm:ss');
		this.timeControl.setValue(timeVal);
		this.selectedDate = this.startDateAt ? this.startDateAt : new Date();
	}

	get dateTimeValue() {
		// let snd = this.isStartDateTime ? ':00' : ':01';
		return moment(this.selectedDate).format('DD/MM/YYYY') + ' ' + moment(this.timeControl.value, 'hh:mm:ss').format('hh:mm:ss A');
	}

	onSubmit() {
		this.isShowPanel$ = merge(this.isPanelHidden$, this.isPanelVisible$);

		// let snd = this.isStartDateTime ? ':00' : ':01';
		this.dateTimeChange.emit({
			value: moment(this.selectedDate).format('YYYY-MM-DD') + ' ' + moment(this.timeControl.value, 'HH:mm:ss').format('HH:mm:ss'),
			isStartDateTime: this.isStartDateTime
		});
	}
}

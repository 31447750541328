import { MatDialog } from '@angular/material/dialog';
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ImageViewerComponent } from 'src/app/modules/shared/image-viewer/image-viewer.component';

@Component({
	selector: 'carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss'],
	animations: [
		trigger('carouselAnimation', [
			transition('void => *', [
				style({ opacity: 0 }),
				animate('300ms', style({ opacity: 1 }))
			]),
			transition('* => void', [
				animate('300ms', style({ opacity: 0 }))
			])
		])
	]
})
export class CarouselComponent {
	baseUrl: string = environment.baseUrl;
	currentSlide = 0;

	@Input() slides: string[] = [];

	constructor(
		private dialog: MatDialog
	) { }

	onPreviousClick() {
		const previous = this.currentSlide - 1;
		this.currentSlide = previous < 0 ? this.slides.length - 1 : previous;
	}

	onNextClick() {
		const next = this.currentSlide + 1;
		this.currentSlide = next === this.slides.length ? 0 : next;
	}


	onPreview(url: string) {
		this.dialog.open(ImageViewerComponent, {
			panelClass: 'changeBackgroundColor',
			id: 'app-image-view',
			disableClose: false,
			width: '800px',
			data: { image: url }
		});
	}



	// in case preload image

	// ngOnInit() {
	// 	this.preloadImages(); // for the demo
	// }

	// preloadImages() {
	// 	for (const slide of this.slides) {
	// 		new Image().src = slide.src;
	// 	}
	// }
}

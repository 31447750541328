import { Injectable } from '@angular/core';
import { SESSION_ENUM } from '../models/enum/session-storage.enum';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  isInWorkLocation(u_loc_id: string | any): boolean {
    let loc = this.getArray(SESSION_ENUM.LIST_USER_LOCATION);
    return u_loc_id ? !!loc.includes(u_loc_id) : false;
  }

  getObject(key: SESSION_ENUM) {
    let value: any = sessionStorage.getItem(key);
    return JSON.parse(value);
  }

  saveObject(key: SESSION_ENUM, obj: any) {
    sessionStorage.setItem(key, JSON.stringify(obj));
  }

  get(key: string): string {
    const prefix = btoa(key).replace(/=/g, '');
    const item = sessionStorage.getItem(key);
    if (!item) {
      return null;
    }
    try {
      const base64 = atob(item).replace(prefix, '');
      return atob(base64);
    } catch (error) {
      return null;
    }
  }

  set(key: string, value: string) {
    const prefix = btoa(key).replace(/=/g, '');
    const base64 = btoa(value);
    sessionStorage.setItem(key, btoa(prefix + base64));
  }

  setArray(key: string, values: any[]) {
    const value = values.toString();
    this.set(key, value);
  }

  getArray(key: string): any[] {
    const value = this.get(key);
    return value ? value.split(',') : null;
  }

  delete(key: string) {
    sessionStorage.removeItem(key);
  }

  setSpecialCharacter(key: string, value: string) {
    const prefix = btoa(unescape(encodeURIComponent(key))).replace(/=/g, '');
    const base64 = btoa(unescape(encodeURIComponent(value)));
    sessionStorage.setItem(key, btoa(prefix + base64));
  }

  getSpecialCharacter(key: string) {
    const prefix = btoa(unescape(encodeURIComponent(key))).replace(/=/g, '');
    const item = sessionStorage.getItem(key);
    if (!item) {
      return null;
    }
    try {
      const base64 = decodeURIComponent(escape(window.atob(item))).replace(prefix, '');
      return decodeURIComponent(escape(window.atob(base64)));
    } catch (error) {
      return null;
    }
  }
}

import { IKhan } from '../../../models/interface/loc-khan';
import { Component, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KhanServiceService } from 'src/app/services/khan-service.service';
import { PostRecycleService } from 'src/app/services/post-recycle.service';
import { PostsService } from 'src/app/services/posts.service';
import { ILocLocation } from 'src/app/models/interface/loc-location';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LOCAL_STORAGE_ENUM } from 'src/app/models/enum/local-storage.enum';
import { Subject, takeUntil } from 'rxjs';
import { UtilService } from 'src/app/services/util.service';

@Component({
	selector: 'app-dialog-staff-assignment',
	templateUrl: './dialog-staff-assignment.component.html',
	styleUrls: ['./dialog-staff-assignment.component.scss'],
})
export class DialogStaffAssignmentComponent implements OnInit {
	lang: string;
	tabName: string;
	khans: IKhan[] = [];
	sangkats: ILocLocation[] = [];
	disable = true;

	public khanControl = new FormControl('');
	public sangkatControl = new FormControl('');

	private _destroyed = new Subject<void>();
	constructor(
		private postService: PostsService,
		private postRecycle: PostRecycleService,
		private khanService: KhanServiceService,
		private translate: TranslateService,
		private localStorageService: LocalStorageService,
		private util: UtilService,
		private dialogRef: MatDialogRef<DialogStaffAssignmentComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any
	) {
		this.translate.onLangChange.pipe(takeUntil(this._destroyed)).subscribe((event: LangChangeEvent) => {
			this.lang = event.lang;
		})

		this.tabName = data.tabName;
	}

	ngOnInit(): void {
		if (this.lang == '' || !this.lang) {
			this.lang = this.localStorageService.get(LOCAL_STORAGE_ENUM.lang);
		}

		this.getAllKhan();
	}

	getSangkatByKhanID(kid) {
		this.sangkatControl.reset();
		this.khanService.getSangKatByKhanID(kid).subscribe((res) => {
			this.sangkats = res.data.sangkats;
		});
	}

	changeLocation(lid: string) {
		if (this.tabName === 'solidwaste') {
			this.postService.assignPost2Khan(this.data.post_id, lid).subscribe(res => {
				if (res.status == 1) this.dialogRef.close(true);
			});

		} else if (this.tabName === 'recycle') {
			this.postRecycle.assignPostToLocation(this.data.post_id, lid).subscribe(res => {
				if (res) this.dialogRef.close(true);
			});

		} else window.alert('Invalid tab name.');
	}

	getAllKhan() {
		this.khanService.getAllKhan().subscribe(
			(res) => {
				this.khans = res.data;
			},
			(err) => console.error(err)
		);
	}

	submit() {
		this.changeLocation(
			this.sangkatControl.value
				? this.sangkatControl.value
				: this.khanControl.value
		);
	}
}

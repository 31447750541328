<div class="container-xl">
  <h1 class="text-center mt-3">
    <b> Privacy Policy </b>
  </h1>
  <div class="text-start lh-lg">
    <h3>
      EWP Admin website url: <a href="https://ewp-pp.com">https://ewp-pp.com</a>
    </h3>
    <h3>
      Privacy of user is one of our main priorities. This Privacy Policy
      document contains types of information that is collected and recorded by
      Enterprise for Managing Municiple Solid Waste in Phnom Penh (EWP) and how
      we use it.
    </h3>
    <h3>
      Please do not hesitate to contact us if you have any questions or other
      information related to our Privacy Policy.
    </h3>
    <h3>
      This Privacy Policy document is not relevant to collected offline
      information or via channels other than this website.
    </h3>
  </div>

  <h2>
    <b> Information we collect </b>
  </h2>
  <h3>
    User's personal information is required for using our EWP Admin website and
    mobile application, so you are asked to provide needed information such as
    name, gender, phone number, date of birth, and email when you register an
    Account.
  </h3>
  <h2>
    <b> How we use your information </b>
  </h2>
  <h3>We use your collected information in various ways as below:</h3>
  <ul>
    <li>
      <h3>
        Provide accessibility to you for using our website and mobile
        application
      </h3>
    </li>
    <li>
      <h3>Understand and analyze how to use our website</h3>
    </li>
    <li>
      <h3>Communicate directly with you</h3>
    </li>
    <li>
      <h3>Send you emails</h3>
    </li>
    <li>
      <h3>Find and prevent cheating</h3>
    </li>
  </ul>

  <h2>
    <b> Activity Log </b>
  </h2>

  <h3>
    Most of your activities are recorded in EWP system during using our website
    and mobile application. In each activity log contains the provided personal
    information as mentioned above and the action you did.
  </h3>

  <h2>
    <b> Session Storage and Web Beacons </b>
  </h2>

  <h3>
    Like any other website, EWP Admin uses "Session Storage". Your authenticated
    information and other general information such as language, table
    pagination, etc. record will be stored in Session Storage.
  </h3>

  <h3>
    The data stored in Session Storage is used for optimizing the users'
    experience and sampling our web page.
  </h3>

  <h2>
    <b> GDPR Data Protection Rights </b>
  </h2>

  <h3>
    We would like to make sure your collected information is protected. All of
    your personal information will not be sold or bought.
  </h3>
</div>

import { ICommonResponse } from '../models/response/common-response';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { RequestService } from './request.service';
import { ISummaryRecycle } from '../models/interface/summary-recycle';
import { IComment } from '../models/interface/int-comment';
import { IResponseWithPagination } from '../models/response/response-with-pagination';
import { IPostRecycleWaste } from '../models/interface/post-recycle-waste';
import { IRecycleItem } from '../models/interface/int-recycle-item';
import { SubmitFailService } from './submit-fail.service';

@Injectable({
	providedIn: 'root',
})
export class PostRecycleService extends SubmitFailService {
	constructor(
		private requestService: RequestService
	) { super(); }


	// ************* GET ALL ***************
	// getAll(searchQuery: string, fromDate: any, toDate: any, location: string, limit: number, page: number): Observable<PostRecycleResponse> {
	getAll(data: any): Observable<IResponseWithPagination<IPostRecycleWaste>> {
		const url = environment.baseUrl + '/back-office/invoices/recycles/';
		// const datePipe = new DatePipe('en-US');
		// const start = datePipe.transform(fromDate, 'yyyy-MM-dd');
		// const end = datePipe.transform(toDate, 'yyyy-MM-dd');
		return this.requestService.getJSON(url, data);
	}

	getRecyclePostByID(id: string): Observable<ICommonResponse<IPostRecycleWaste>> {
		const url = environment.baseUrl + '/back-office/invoices/recycles/' + id;
		return this.requestService.getJSON(url);
	}

	createPostRecycle(data: any, itemRecycles: any[], files: any[]): Observable<ICommonResponse<IPostRecycleWaste>> {
		const url = environment.baseUrl + '/back-office/invoices/recycles/create';
		const formData = new FormData();

		for (let i in data) {
			if (data[i]) formData.append(i, data[i]);
		}

		for (let [index, ref] of itemRecycles.entries()) {
			formData.append(`recycle_items[${index}][item]`, ref.item);
			formData.append(`recycle_items[${index}][quantity]`, ref.quantity);
		}

		for (let [index, file] of files.entries()) {
			formData.append(`images[${index}]`, file)
		}

		return this.requestService.postJSON(url, formData);
	}


	assignPostRecycle(data: any, _id: string, files: any): Observable<ICommonResponse<IPostRecycleWaste>> {
		const url = environment.baseUrl + '/back-office/invoices/recycles/' + _id + '/assign';
		const formData = new FormData();
		for (let item in data) {
			formData.append(item, data[item]);
		}

		if (files.length > 0) {
			for(let [index, file] of files.entries()) {
				formData.append(`files[${index}]`, file);
			}
		}
		return this.requestService.putJSON<any>(url, formData);
	}

	rejectPostRecycle(_id: string, description: string, files: any[]): Observable<ICommonResponse<IPostRecycleWaste>> {
		const url = environment.baseUrl + '/back-office/invoices/recycles/' + _id + '/reject';
		const formData = new FormData();
		formData.append('description', description);

		if (files.length > 0) {
		  for(let [index, file] of files.entries()) {
			formData.append(`files[${index}]`, file);
		  }
		}

		return this.requestService.putJSON<any>(url, formData);
	}


	sendComment(id: string, text: string): Observable<ICommonResponse<IComment>> {
		const url = environment.baseUrl + '/back-office/invoices/recycles/' + id + '/comments';
		return this.requestService.postJSON(url, { text });
	}

	getComment(id: string, page: number, limit: number): Observable<IResponseWithPagination<IComment>> {
		const url = environment.baseUrl + '/back-office/comments/' + id;
		return this.requestService.getJSON(url, { page, limit });
	}

	assignPostToLocation(pid: string, lid: string): Observable<ICommonResponse<IPostRecycleWaste>> {
		const url = environment.baseUrl + '/back-office/invoices/recycles/' + pid + '/set-to/' + lid;

		return this.requestService.putJSON(url);
	}

	assignPostToStaff(pId: string, sId: string): Observable<ICommonResponse<IPostRecycleWaste>> {
		const url = environment.baseUrl + '/back-office/invoices/recycles/' + pId + '/set-to/' + sId;
		return this.requestService.postJSON(url);
	}

	getSummaryPostRecycle(data: any): Observable<ICommonResponse<ISummaryRecycle>> {
		const url = environment.baseUrl + '/back-office/summary/recycles';
		return this.requestService.getJSON(url, data);
	}

	setPostToCompany(id: string, to_company: boolean): Observable<ICommonResponse<IPostRecycleWaste>> {
		const url = environment.baseUrl + '/back-office/invoices/recycles/' + id + '/set-to-company';
		return this.requestService.putJSON(url, { to_company });
	}

	markAsInprogress(id: string): Observable<ICommonResponse<IPostRecycleWaste>> {
		const url = environment.baseUrl + '/back-office/invoices/recycles/' + id + '/in-progress';
		return this.requestService.putJSON(url);
	}

	markAsApprove(id: string, files: any[]): Observable<ICommonResponse<IPostRecycleWaste>> {
		const url = environment.baseUrl + '/back-office/invoices/recycles/' + id + '/approve';
		const formData = new FormData();
		if (files.length > 0) {
		  for(let [index, file] of files.entries()) {
			formData.append(`files[${index}]`, file);
		  }
		}

		return this.requestService.putJSON(url, formData);
	}




	// ********************************** GET ITEM RECYCLE *********************************
	getAllRecycleItem(): Observable<ICommonResponse<IRecycleItem[]>> {
		const url = environment.baseUrl + '/items-recycles/';
		return this.requestService.getJSON(url);
	}
}

import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IKhan } from '../models/interface/loc-khan';
import { ILocLocation } from '../models/interface/loc-location';
import { ICommonResponse } from '../models/response/common-response';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class KhanServiceService {
  forceTriggerChangeEvent: EventEmitter<boolean> = new EventEmitter();
  baseUrl: string = environment.baseUrl;
  tempToken: string;

  constructor(
    private http: HttpClient,
    private requestService: RequestService
  ) { }

  getAllKhan(): Observable<ICommonResponse<IKhan[]>> {
    const url = environment.baseUrl + '/back-office/locations/khans';
    return this.requestService.getJSON<ICommonResponse<IKhan[]>>(url);
  }

  getAllKhanWithChild(): Observable<any> {
    const url = environment.baseUrl + '/phnom-penh';
    return this.requestService.getJSON<any>(url).pipe(map((res: any) => res.data));
  }

  getNonDesignatedKhan(): Observable<ICommonResponse<IKhan[]>> {
    const url = environment.baseUrl + '/phnom-penh/khans/no-operator';
    return this.requestService.getJSON(url);
  }

  getKhanByID(id: string) {
    const url = environment.baseUrl + '/phnom-penh/khans/' + id;
    return this.requestService.getJSON(url).pipe(map((res: any) => res.data));
  }

  // get all sangkat
  getAllSangKat() {
    return this.requestService.getJSON<Location[]>(`${this.baseUrl}/phnom-penh/sangkats`).pipe(map((res: any) => res.data.sangkats));
  }

  // get array sangkat by khan id
  getSangKatByKhanID(khanID: string): Observable<ICommonResponse<{ total: number, sangkats: ILocLocation[] }>> {
    const url = environment.baseUrl + '/back-office/locations/khans/' + khanID + '/sangkats';
    return this.requestService.getJSON(url);
  }

  getVillagesBySangkatID(sangkatID: string): Observable<ICommonResponse<{ total: number, villages: ILocLocation[] }>> {
    const url = environment.baseUrl + '/back-office/locations/sangkat/' + sangkatID + '/villages';
    return this.requestService.getJSON(url);
  }

  // get location without admin khan
  getLocationWithoutAdminKhan() {
    const url = environment.baseUrl + '/phnom-penh/khans/without-admin';
    return this.requestService.getJSON(url).pipe(map((res: any) => res.data));
  }

  // get location without admin sangkat
  getLocationWithoutAdminSangkat(id: string) {
    const url = environment.baseUrl + '/phnom-penh/without-admin-sangkat-in/' + id;
    return this.requestService.getJSON(url).pipe(map((res: any) => res.data));
  }



  // ============ new api =============
  getLocationAsTree(): Observable<ICommonResponse<IKhan[]>> {
    const url = environment.baseUrl + '/back-office/locations/tree';
    return this.requestService.getJSON(url);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {

  transform(value: any, arg?: string): string {
    // if (arg === 'time') {
    //   return moment(value).locale('km').format('LLL');
    // } else if (arg === 'time-only') {
    //   return moment(value).locale('km').format('LT');
    // } else {
    //   return moment(value).locale('km').format('LL');
    // }
    if (arg === 'time') {
      return moment(value).locale('en').format('LLL');
    } else if (arg === 'time-only') {
      return moment(value).locale('en').format('LT');
    } else {
      return moment(value).locale('en').format('LL');
    }
  }

  // transform(value: Date, arg?: string): string {
  //   if (arg === 'time') {
  //     return moment(value, 'YYYY-MM-DD HH:mm:ss Z').format('LLL');
  //   } else {
  //     return moment(value, 'YYYY-MM-DD HH:mm:ss Z').format('LL');
  //   }
  // }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { fadeAnimation } from 'src/app/animations/fade-animation';
import { ElectronService } from 'src/app/services/electron.service';
import { CONFIRM_TYPE, GlobalConfirmDialogComponent } from '../global-confirm-dialog/global-confirm-dialog.component';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-dialog-print-progress',
  templateUrl: './dialog-print-progress.component.html',
  styleUrls: ['./dialog-print-progress.component.scss'],
  animations: [fadeAnimation]
})
export class DialogPrintProgressComponent implements OnInit {
  progress = 0;
  pages: number;
  isSuccess: boolean = false;
  isShowAction = false;
  errorMessage: string;
  startPageControl = new FormControl(1, Validators.required);
  isFirstPrint = true;
  constructor(
    private dialogRef: MatDialogRef<DialogPrintProgressComponent>,
    private electronService: ElectronService,
    private translate: TranslateService,
		private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: {
      url: string,
      id_list?: string[],
      inv_num_list?: string[]
    }) {
      
  }

  print() {
    if(this.startPageControl.valid){
      this.isSuccess = null;
      this.errorMessage = "";
      this.electronService.print({ url: this.data.url, limit: 5, skip: this.startPageControl.value - 1, id_list: this.data.id_list, inv_num_list: this.data.inv_num_list }).subscribe(result => {
        if (typeof result == 'number') {
          this.pages = result;
          this.startPageControl.patchValue(result + 1)
        } else {
          if (result.errorMessage) {
            this.isSuccess = false;
            this.errorMessage = result.errorMessage;
          } else {
            this.pages = result.total_print;
            this.isSuccess = true;
          }
        }
      })
      this.isFirstPrint = false;
    }else{
      this.startPageControl.markAllAsTouched();
    }
    
  }

  ngOnInit(): void {
  }

  onForceClose() {
    this.dialog.open(GlobalConfirmDialogComponent, {
      disableClose: true,
      autoFocus: false,
      width: '500px',
      data: {
        is_reject: true,
        confirm_type: CONFIRM_TYPE.NORMAL,
        title: this.translate.instant('dialog.confirm.alert'),
        confirm_desc:this.translate.instant('message.confirm_abort_print_invoice')
      }

    }).afterClosed().subscribe(res => {
      if (res) {
        this.electronService.abortPrint();
        this.dialogRef.close();
      }
    })
  }
}

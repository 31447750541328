import { Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { IHomeType } from 'src/app/models/interface/int-home-type';
import { UtilService } from 'src/app/services/util.service';

@Pipe({
  name: 'updateHomeType'
})
export class UpdateHomeTypePipe implements PipeTransform {
	lang: string = '';

  constructor(private translate: TranslateService, private util: UtilService) {
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => this.lang = event.lang);
    if (this.lang == '' || !this.lang) this.lang = this.util.getLang();
  }

  transform(value: IUpdateHomeType, args: 'from_type' | 'to_type' = 'from_type'): string {
    if (value) {
      if (args == 'from_type' && value.from_type) return `${value?.from_type?.code} - ${this.getHouseTypeName(value?.from_type)} - ${value?.from_type?.amount}`;
      else if (args == 'from_type' && !value.from_type) return this.validEmptyString(value.from_type);
      else if (args == 'to_type' && value.to_type) return `${value?.to_type?.code} - ${this.getHouseTypeName(value?.to_type)} - ${value?.to_type?.amount}`;
      else if (args == 'to_type' && !value.to_type) return this.validEmptyString(value.to_type);
      else return '-';

    } else return '-';
  }

  getHouseTypeName(item: any) {
    if (item) return this.lang == 'en' ? item.name_en : item.name_kh;
    else return '-';
	}

  validEmptyString(value: any) {
    return !value || value == '' || value == ' ' ? (this.lang != 'en' ? 'ទទេ' : 'empty') : value;
  }
}

interface IUpdateHomeType {
  from_type: IHomeType,
  to_type: IHomeType
}
import { ICommonResponse } from '../models/response/common-response';
import { RequestService } from './request.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { ISummaryPost } from '../models/interface/summary-post';
import { ISummaryInvoiceSolidWaste } from '../models/interface/summary-invoice-solid-waste';
import { IPostSolidWaste } from '../models/interface/posts-solid-waste';
import { IResponseWithPagination } from '../models/response/response-with-pagination';
import { IComment } from '../models/interface/int-comment';
import { SubmitFailService } from './submit-fail.service';

@Injectable({
  providedIn: 'root',
})
export class PostsService extends SubmitFailService {

  constructor(
    private requestService: RequestService
  ) { super(); }

  getAll(data: any): Observable<IResponseWithPagination<IPostSolidWaste>> {
    const url = environment.baseUrl + '/back-office/posts/';
    return this.requestService.getJSON(url, data);
  }

  getPostByID(postID: string): Observable<ICommonResponse<IPostSolidWaste>> {
    const url = environment.baseUrl + '/back-office/posts/' + postID;
    return this.requestService.getJSON(url);
  }

  createPostSolidWaste(data: any, files: any[]): Observable<ICommonResponse<IPostSolidWaste>> {
    const url = environment.baseUrl + '/back-office/posts/create';
    const formData = new FormData();

    for (let i in data) {
      if (data[i]) formData.append(i, data[i]);
    }

    for (let [index, file] of files.entries()) {
      formData.append(`images[${index}]`, file);
    }

    return this.requestService.postJSON(url, formData);
  }

  // location: string, fromDate: string, toDate: string, isDateTime: boolean = false
  getSummaryPostSolidWaste(data: any): Observable<ICommonResponse<ISummaryPost>> {
    const url = environment.baseUrl + '/back-office/summary/posts';
    return this.requestService.getJSON(url, data);
  }

  getSummaryInvoiceSolidWaste(data: any): Observable<ICommonResponse<ISummaryInvoiceSolidWaste>> {
    const url = environment.baseUrl + '/back-office/summary/solid-wastes';
    return this.requestService.getJSON(url, data);
  }

  getComment(id: string, page: number, limit: number): Observable<IResponseWithPagination<IComment>> {
    const url = environment.baseUrl + '/back-office/comments/' + id;
    return this.requestService.getJSON(url, { page, limit });
  }

  sentComment(postID: any, text: any): Observable<ICommonResponse<IComment>> {
    const url = environment.baseUrl + '/back-office/posts/' + postID + '/comments';
    return this.requestService.postJSON(url, { text });
  }

  assignPost2Khan(pid: string, kid: string): Observable<ICommonResponse<IPostSolidWaste>> { // must modified later on
    const url = environment.baseUrl + '/back-office/posts/' + pid + '/set-to/' + kid;
    return this.requestService.putJSON(url);
  }

  setPostToCompany(id: string, to_company: boolean): Observable<ICommonResponse<IPostSolidWaste>> {
    const url = environment.baseUrl + '/back-office/posts/' + id + '/set-to-company';
    return this.requestService.putJSON(url, { to_company });
  }

  markAsInprogress(id: string): Observable<ICommonResponse<IPostSolidWaste>> {
    const url = environment.baseUrl + '/back-office/posts/' + id + '/in-progress';
    return this.requestService.putJSON(url);
  }

  markAsApprove(id: string, files: any[]): Observable<ICommonResponse<IPostSolidWaste>> {
    const url = environment.baseUrl + '/back-office/posts/' + id + '/approve';
    const formData = new FormData();

		if (files.length > 0) {
			for(let [index, file] of files.entries()) {
				formData.append(`files[${index}]`, file);
			}
		}
    return this.requestService.putJSON(url, formData);
  }

  markAsRejected(id: string): Observable<ICommonResponse<IPostSolidWaste>> {
    const url = environment.baseUrl + '/back-office/posts/' + id + '/reject';
    return this.requestService.putJSON(url);
  }

  assignPostSolidWaste(data: any, _id: string, files: any[]): Observable<ICommonResponse<IPostSolidWaste>> {
		const url = environment.baseUrl + '/back-office/posts/' + _id + '/assign';
    const formData = new FormData();
		for (let item in data) {
			formData.append(item, data[item]);
		}

		if (files.length > 0) {
			for(let [index, file] of files.entries()) {
				formData.append(`files[${index}]`, file);
			}
		}
		return this.requestService.putJSON<any>(url, formData);
	}

  rejectPostSolidWaste(_id: string, description: string, files: any[]): Observable<ICommonResponse<IPostSolidWaste>> {
		const url = environment.baseUrl + '/back-office/posts/' + _id + '/reject';
    const formData = new FormData();
    formData.append('description', description);

		if (files.length > 0) {
			for(let [index, file] of files.entries()) {
				formData.append(`files[${index}]`, file);
			}
		}
		return this.requestService.putJSON<any>(url, formData);
	}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayPhonesArray'
})
export class DisplayPhonesArrayPipe implements PipeTransform {

  transform(value: any[]): any {
    if (!value || value.length == 0) return '-';
    return value.toString().split(',').join(', ');
  }

}

import { RequestService } from './services/request.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, OnDestroy } from '@angular/core';
import { LoadingService } from './services/loading.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { StaffService } from './services/staff.service';
import { MatDialog } from '@angular/material/dialog';
import { RefreshTokenDialogComponent } from './modules/shared/refresh-token-dialog/refresh-token-dialog.component';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { GoogleMapService } from './services/google-map.service';
import { UtilService } from './services/util.service';
import { Subject, takeUntil } from 'rxjs';
import { delay } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
	timer: any;
	isLoading = false;
	private iconPath: string = "/assets/image/main/";
	private iconExt: string = '.svg';

	token: any;
	current_time: number;
	expired_time: number;
	load_expired_dialog_time: number;
	decode: any;

	private _destroyed = new Subject<void>();
	constructor(
		private loadingService: LoadingService,
		private translate: TranslateService,
		private requestService: RequestService,
		private staffService: StaffService,
		private icon: MatIconRegistry,
		private dialog: MatDialog,
		private util: UtilService,
		private sanitizer: DomSanitizer,
		private _googleMapService: GoogleMapService,
		private _ngxSpinnerService: NgxSpinnerService,
		@Inject(DOCUMENT) private _document: Document
	) {
		this.translate.addLangs(this.requestService.availableLang);
		this.translate.use(this.requestService.getDefaultLang());

		this.loadingService.loadingEventEmitter.pipe(delay(0)).subscribe((isLoading: boolean) => {
			if (isLoading) {
				this._ngxSpinnerService.show("pageLoading");
			} else {
				this._ngxSpinnerService.hide("pageLoading");
			}
		});

		this.addIcons();

		this.staffService.isLogout.pipe(takeUntil(this._destroyed)).subscribe(res => {
			if (res) {
				clearInterval(this.timer);
				this.dialog.closeAll();
			}
		})

		this.staffService.isLogin.pipe(takeUntil(this._destroyed)).subscribe(res => {
			if (res) {
				this.onDefault();
				this.autoSignOut();
			}
		})

		this.onDefault();
		this.autoSignOut();
		this._loadGoogleMapSdk();
	}

	private _loadGoogleMapSdk() {
		var script = this._document.createElement('script');
		script.src = 'https://maps.googleapis.com/maps/api/js?key=' + environment.GOOGLE_MAP_API_KEY + "&callback=initMap";
		// &callback=initMap
		script.async = true;

		// Attach your callback function to the `window` object
		// @ts-ignore
		window.initMap = () => {
			// this._ngZone.runOutsideAngular(() => {
			this._googleMapService.loaded = true;
			// });
		}

		// Append the 'script' element to 'head'
		this._document.head.appendChild(script);
	}


	addIcons() {
		this.addIcon('create_icon');
		this.addIcon('save_icon');
		this.addIcon('save_no_color_icon');
		this.addIcon('close_icon');
		this.addIcon('inprogress_icon');
		this.addIcon('done_icon');
		this.addIcon('spam_icon');
		this.addIcon('reject_icon');
		this.addIcon('cashin_icon');
		this.addIcon('cashout_icon');
		this.addIcon('disable_icon');
		this.addIcon('login_icon');
		this.addIcon('return_icon');
		this.addIcon('transfer_icon');
		this.addIcon('upload_icon');
		this.addIcon('upload_no_color');
		this.addIcon('date_color_icon');
		this.addIcon('street_icon');
		this.addIcon('house_already_occupied');
		this.addIcon('house_inprogress');
		this.addIcon('house_not_occupied_yet');
		this.addIcon('house_disabled');
		this.addIcon('all');
		this.addIcon('excel_icon');
		this.addIcon('add_more_icon');
		this.addIcon('add_more_no_color_icon');
		this.addIcon('verify_excel_icon');
		this.addIcon('refund_icon');
		this.addIcon('map_icon');
		this.addIcon('lock_icon');
		this.addIcon('money_transfer_icon');
		this.addIcon('paid_icon');
		this.addIcon('wallet_icon');
		this.addIcon('village_icon');
		this.addIcon('village_color_icon');
		this.addIcon('street_icon');
		this.addIcon('house_icon');

		this.addIcon('menu_dashboard');
		this.addIcon('menu_house_verify');
		this.addIcon('menu_checking_house');
		this.addIcon('menu_post_solid_waste');
		this.addIcon('menu_post_recycle');
		this.addIcon('menu_waste_collection_history');
		this.addIcon('menu_fees');
		this.addIcon('menu_receipt');
		this.addIcon('menu_report');
		this.addIcon('menu_operator');
		this.addIcon('menu_waste');
		this.addIcon('menu_road');
		this.addIcon('menu_video');
		this.addIcon('menu_post');
		this.addIcon('menu_manage_user');
		this.addIcon('menu_staff');
		this.addIcon('menu_user');

		this.addIcon('report_annual_income');
		this.addIcon('report_sales_debt');
		this.addIcon('report_request_update_house_type');
		this.addIcon('report_request_recycle');
		this.addIcon('report_bank_payment');
		this.addIcon('report_daily_settlement');
		this.addIcon('report_create_new_invoice');
		this.addIcon('report_adjustment_amount');
		this.addIcon('report_refund_transfer');
		this.addIcon('report_remove_invoice');
		this.addIcon('report_debt');
		this.addIcon('report_new_user');
		this.addIcon('report_house_payment');
		this.addIcon('report_total_sales');
		this.addIcon('report_payment_summary');
		this.addIcon('report_total_debt');
		this.addIcon('report_debt_by_age');
		this.addIcon('report_print_invoice');
		this.addIcon('report_cash_payment');
		this.addIcon('report_cash_in_detail');
		this.addIcon('report_cash_in_summary');
		this.addIcon('report_cash_transaction');

		this.addIlustrate('status_completed');
		this.addIlustrate('status_deleted');
		this.addIlustrate('status_inprogress');
		this.addIlustrate('status_new');
		this.addIlustrate('status_spam_warning');
		this.addIlustrate('status_total');
		this.addIlustrate('action_throw_trash');
		this.addIlustrate('wallet');
		this.addIlustrate('request_house');
	}

	private addIcon(name: string): void {
		this.icon.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(this.iconPath + name + this.iconExt));
	}

	private addIlustrate(name: string): void {
		this.icon.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl('/assets/image/illustrate/' + name + this.iconExt));
	}

	onDefault() {
		this.token = this.util.getToken();
		this.load_expired_dialog_time = parseInt(this.util.getExpiredAt()) - 15 * 60; // minus 15min before being expired
		this.expired_time = parseInt(this.util.getExpiredAt());
		this.onAlertExpiredTokenDialog();
	}

	onAlertExpiredTokenDialog() {
		this.current_time = new Date().getTime() / 1000;

		if (this.token && this.current_time < this.load_expired_dialog_time && this.current_time < this.expired_time) {
			this.timer = setTimeout(() => {
				this.onAlertExpiredTokenDialog();
			}, 1000);

		} else if (this.token && this.current_time > this.load_expired_dialog_time && this.current_time < this.expired_time) {
			clearTimeout(this.timer);
			const dialog = this.dialog.open(RefreshTokenDialogComponent, {
				width: "500px",
				disableClose: true,
				autoFocus: false,
				restoreFocus: false,
				data: {
					is_expired_token: true
				}
			})

			dialog.afterClosed().subscribe(res => {
				if (res) this.getNewToken();
				else this.requestService.onSignOut();
			})

		} else if (this.token) {
			this.requestService.onSignOut();
		}
	}

	getNewToken() {
		this.staffService.getNewToken().subscribe(
			res => this.onDefault(),
			err => console.error(err)
		)
	}

	autoSignOut() {
		if (this.token && this.current_time > this.expired_time) {
			this.dialog.closeAll();
			this.requestService.onSignOut();
		}
	}

	ngOnDestroy(): void {
		this._destroyed.next();
		this._destroyed.complete();
	}
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LOCAL_STORAGE_ENUM } from 'src/app/models/enum/local-storage.enum';
import { SESSION_ENUM } from 'src/app/models/enum/session-storage.enum';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { RequestService } from 'src/app/services/request.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-refresh-token-dialog',
  templateUrl: './refresh-token-dialog.component.html',
  styleUrls: ['./refresh-token-dialog.component.scss']
})
export class RefreshTokenDialogComponent {

  timer: any;
  lang: string = '';
  currentTime: number;
  expiredTime: number;
  countdownTimer: any;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rqs: RequestService,
    private dialog: MatDialog,
    private ss: SessionStorageService,
    private util: UtilService,
    // private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<RefreshTokenDialogComponent>
  ) {
    this.translate.onDefaultLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
    });

    // let token = ss.get(SESSION_ENUM.TOKEN);
    // this.expiredTime = parseInt(this.ss.get(SESSION_ENUM.EXPIRED_AT));

    let token = this.util.getToken();
    if (token && data.is_expired_token) {
      this.expiredTime = parseInt(this.util.getExpiredAt());
      this.countdown();
    }
  }

  countdown() {
    this.timer = setInterval(() => {

      // get current time in seconds
      this.currentTime = new Date().getTime() / 1000;

      // Find the distance between now and the expire date
      let distance = this.expiredTime - this.currentTime;

      // Time calculations for minutes and seconds
      let hours = Math.floor((distance % (60 * 60 * 60)) / 3600);
      let minutes = Math.floor((distance % (60 * 60)) / 60);
      let seconds = Math.floor(distance % 60);

      // Display format
      // format to 01:01 instead of 1:1
      let F_hour = hours.toString().length == 1 ? '0' + hours : hours;
      let F_min = minutes.toString().length == 1 ? '0' + minutes : minutes;
      let F_sec = seconds.toString().length == 1 ? '0' + seconds : seconds;

      this.countdownTimer = F_min + ':' + F_sec;


      if (minutes <= 0 && seconds <= 0) {
        this.offTimer();
        this.dialogRef.close();
        this.dialogRef.afterClosed().subscribe(res => {
          this.rqs.onSignOut();
        })
      }
    }, 1000);
  }

  onConfirmClick() {
    this.offTimer();
    this.dialogRef.close(true);
  }

  offTimer() {
    clearInterval(this.timer);
  }
}

import { AfterViewInit, ComponentRef, ContentChild, Directive, ElementRef, Input, OnDestroy, Renderer2, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MatErrorComponent } from '../helpers/class/mat-error-comp';
import { SubmitFailService } from '../services/submit-fail.service';

export const defaultErrors = {
	minlength: ({ requiredLength, actualLength }) => `${requiredLength}`,
	email: error => 'form-field.invalid',
	pattern: error => 'form-field.invalid',
	error: error => error,
	invalid: error => 'form-field.invalid',
	required: error => 'form-field.required',
	duplicated: error => 'table.already_exist',
	notMatch: error => 'message.psw_not_match',
	wrongNumber: error => 'form-field.invalid',
	min: ({min, actual}) => `${min}`,
  minMax: error => error,
};

@Directive({
	selector: '[showErrorMessage]'
})
export class ErrorMessageDirective implements AfterViewInit, OnDestroy {
	ref: ComponentRef<MatErrorComponent>;
	control: NgControl;
	@Input() isLogin: boolean = false;
	@Input() isRequired: boolean = true;

	private sub: Subscription;
	private unlistener: () => void;

	@ContentChild(MatInput, { read: ElementRef }) controlElementRef: ElementRef;
	@ContentChild(MatSelect, { read: ElementRef }) controlSelectRef: ElementRef;
	constructor(
		private vcr: ViewContainerRef,
		private formField: MatFormField,
		private renderer: Renderer2,
		private translate: TranslateService,
		private submitFailService: SubmitFailService
	) {
	}

	public ngAfterViewInit() {
		this.control = this.formField._control.ngControl as NgControl;
		this.sub = this.control.statusChanges.subscribe(res => this.onChange(res));
		this.sub = this.submitFailService.eventSubmitFormFail.subscribe(res => { if (res) this.onChange(res) });

		if (this.controlElementRef) {
			this.unlistener = this.renderer.listen(this.controlElementRef.nativeElement, "blur", () => this.onChange(null));
		}

		if (this.controlSelectRef) {
			this.unlistener = this.renderer.listen(this.controlSelectRef.nativeElement, "blur", () => {
				setTimeout(() => {
					this.control.control.markAsTouched();
					this.onChange(null);
				}, 200); // wait 200ms to show error
			});
		}
	}

	public onChange(res: any) {
		if ((this.control.invalid || (this.control.disabled && !this.control.value)) && this.control.touched && this.isRequired) {
			let format_name: string = this.formField._elementRef.nativeElement.outerText.split('*')[0];
			let name: string = format_name.includes('\n') ? format_name.split('\n')[1] : format_name;
			let error: string;



			Object.keys(defaultErrors).forEach(key => {
				if (this.control.hasError(key)) {
					if (key == 'minlength') {
						error = name
						+ ' ' +
						this.translate.instant('form-field.min_length​​​_start')
						+ ' ' +
						defaultErrors[key](this.control.errors[key])
						+ ' ' +
						this.translate.instant('form-field.min_length​​​_end');

					} else if (key == 'min') {
						error = name
						+ ' ' +
						this.translate.instant('form-field.min_start')
						+ ' ' +
						defaultErrors[key](this.control.errors[key]);

          } else if (key == 'minMax') {
            const {requiredMin, requiredMax} = defaultErrors[key](this.control.errors[key]);
            error = `${name} ${this.translate.instant('message.invalid_min_max', {min: requiredMin, max: requiredMax})}`;

					} else if (key == 'pattern') {
						if (this.control.name == 'password' || this.control.name == 'new_password' || this.control.name == 'confirm_new_password') {
							error = this.translate.instant('message.password_must_match_pattern')
						}

					} else if (key == 'error') {
						error = this.translate.instant('error_code.' + defaultErrors[key](this.control.errors[key]));

					} else {
						error = name + ' ' + this.translate.instant(defaultErrors[key](this.control.errors[key]));

					}

				} else if (this.control.disabled) {
					error = name + ' ' + this.translate.instant('form-field.required');
				}
			});
			this.setError(error);

		} else this.setError("");
	}

	setError(text: string) {
		if (!this.ref) {
			this.ref = this.vcr.createComponent(MatErrorComponent);
		}
		this.ref.instance.error = text;
		if (this.isLogin) this.ref.instance.isLogin = this.isLogin;
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe();
		this.unlistener();
	}
}

import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as fs from 'file-saver';
import { fadeAnimation } from 'src/app/animations/fade-animation';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-dialog-show-progress',
  templateUrl: './dialog-show-progress.component.html',
  styleUrls: ['./dialog-show-progress.component.scss'],
  animations: [fadeAnimation]
})
export class DialogShowProgressComponent {
  progress = 0;
  loaded: string;
  isInprogress = false;
  isSuccess = false;
  isFail = false;
  isShowAction = false;
  timeout: any;
  loadingType: string;

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
		private util: UtilService,
    private dialogRef: MatDialogRef<DialogShowProgressComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      url: string,
      reportName: string,
      query: any,
      export_type: EXPORT_TYPE,
      type: 'bar' | 'spinner'
    }
  ) {
    if (data.url) this.getData();
    this.loadingType = this.data?.type || 'spinner';
  }

  async getData() {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.util.getToken(),
      "Content-Type": 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    await this.clean(this.data.query);

    const url = environment.baseUrl + this.data.url;
    const responseType: string = 'blob';

    return this.http.get(url, {
      params: this.data.query,
      headers: headers,
      responseType: responseType as any,
      reportProgress: true,
      observe: 'events'

    }).pipe(
        map((event: HttpEvent<any>) => {
          this.isInprogress = !!(event.type == HttpEventType.DownloadProgress);

          if (event.type == HttpEventType.DownloadProgress) {

            // Temporary disable. Now using spinner instead of progress bar.
            // --------------
            // this.progress = Math.floor(100 * event.loaded / event.total);


            this.loaded = ((event.loaded / 1024) / 1024).toFixed(2);
          }
          return event;
        }),
        catchError((err) => this.handleHttpError(err))

      ).subscribe(event => {
        if (event.type === HttpEventType.Response) {
          this.isSuccess = true;
          fs.saveAs(event.body, `${this.data.reportName}.${this.data.export_type || EXPORT_TYPE.XLSX}`);
          setTimeout(() => this.dialogRef.close(true), 3000);
        }
      })
  }

  private handleHttpError(error: any) {
    this.isFail = true;
    this.dialogRef.close(false);

    if (error.error.code) this.snackBar.open(this.translate.instant(`error_code.${error.error.code}`), '', { duration: 3000, panelClass: 'panelClass-error' });
    else this.snackBar.open(this.translate.instant('error_code.0000'), '', { duration: 3000, panelClass: 'panelClass-error' });

    return throwError(error);
  }

  private async clean(obj: any) {
		for (const propName in obj) {
			if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
				delete obj[propName];
			}
		}
	}

  onCloseDialog() {
    clearTimeout(this.timeout);
    this.dialogRef.close(true);
  }
}

export enum EXPORT_TYPE {
  CSV = "csv",
  XLSX = "xlsx"
}

import { Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE_ENUM } from 'src/app/models/enum/local-storage.enum';
import { IHomeType } from 'src/app/models/interface/int-home-type';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Pipe({
  name: 'formatHouseType'
})
export class FormatHouseTypePipe implements PipeTransform {
  lang: string;

  constructor(private translate: TranslateService, private localStorageService: LocalStorageService) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
    });

    if (this.lang == '' || !this.lang) {
      this.lang = this.localStorageService.get(LOCAL_STORAGE_ENUM.lang);
    }

  }

  /**
   * l : long
   * 
   * @param value 
   * @param args 
   * @returns 
   */
  transform(value: IHomeType, args: 'l' | 'll' | 'lll' | 'name-only' | 'code-only' | 'amount-only' = 'l'): string {
    if (value) {
      switch (args) {
        case 'l':
        case 'code-only':
          return value.code;

        case 'll':
          if (value.name_en || value.name_kh) return value.code + ' - ' + (value.name_kh || value.name_en);
          else return value.code;

        case 'lll':
          if (value.name_en || value.name_kh) return value.code + ' - ' + (value.name_kh || value.name_en) + ' - ' + value.amount;
          else return value.code + ' - ' + value.amount;

        case 'amount-only':
          if (value.amount || value.amount == 0) return value.amount + ' ' + value.currency == 'USD' ? this.translate.instant('currency.dollar') : this.translate.instant('currency.riel');
          else return '-';
          
        case 'name-only':
          if (!value.name_en && !value.name_kh) return '-';
          else return (this.lang == 'en' ? value.name_en : value.name_kh);

      }

    // } else return '-';
    } else return this.translate.instant('table.empty');

  }

}

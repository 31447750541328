import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICustomer } from 'src/app/models/interface/int-customer';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'form-select-user',
  templateUrl: './form-select-user.component.html',
  styleUrls: ['./form-select-user.component.scss']
})
export class FormSelectUserComponent implements AfterViewInit, OnDestroy {

  userInputControl = new FormControl('');
	selectedUser: ICustomer;

  isScrollTopUser = false;
  isScrollDownUser = true;
  isUserInputFocus = true;
  currentPageIndexUser: number = 1;
  scrollUserEnable = true;
  lastScrollTopUser: number = 0;
	searchUser: string;


  @Output() searchChange = new EventEmitter();
  @Output() clickUser = new EventEmitter();

  @Input() users: ICustomer[] = [];
  @Input() name: any;

  constructor(
		private userService: CustomerService
  ) { }

  ngAfterViewInit(): void {

  }

  onUserSearchChange(txtSearch: string) {
    this.searchChange.emit(txtSearch);
    this.searchUser = txtSearch;
  }

  onScroll(event: any, type: string) {
    let offsetHeight = event.target.offsetHeight;  // visible div height.
    let scrollTop = event.target.scrollTop; // the length that count from bottom of the visible div height to the top.
    let scrollHeight = event.target.scrollHeight; // overall height of the div.

    this.isScrollTopUser = scrollTop < this.lastScrollTopUser;
    this.isScrollDownUser = scrollTop > this.lastScrollTopUser;
    this.lastScrollTopUser = scrollTop;

    if (this.scrollUserEnable && this.isScrollDownUser) {

      // this is where scroll reach the bottom.
      // in this case, I set it to if the scroll reach 70% of the overall height, get new data.
      if (scrollTop > 0 && (offsetHeight + scrollTop >= scrollHeight * 0.7)) {
        this.scrollUserEnable = false;

        this.currentPageIndexUser++;
        this.getUsers();
      }
    }
  }

  getUsers() {
		this.userService.getCustomerList(this.searchUser, this.currentPageIndexUser, 10).subscribe(res => {
			this.users.push(...res.data.data);
			this.scrollUserEnable = res.data.data.length > 0;
		})
	}

  onClickUser(user: ICustomer) {
    if (!user) this.selectedUser = null;
		else this.selectedUser = user;
  }

  ngOnDestroy(): void {
    this.isScrollTopUser = false;
    this.isScrollDownUser = true;
    this.isUserInputFocus = true;
    this.currentPageIndexUser = 1;
    this.scrollUserEnable = true;
    this.lastScrollTopUser = 0;
  }

}

import { CONFIRM_TYPE } from '../global-confirm-dialog/global-confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageViewerComponent } from 'src/app/modules/shared/image-viewer/image-viewer.component';
import { PdfViewerComponent } from 'src/app/modules/shared/pdf-viewer/pdf-viewer.component';
import { GlobalConfirmDialogComponent } from 'src/app/modules/shared/global-confirm-dialog/global-confirm-dialog.component';
import { trigger, transition, style, animate } from '@angular/animations';
import { fadeAnimation } from 'src/app/animations/fade-animation';
import { DocumentService } from 'src/app/services/document.service';
import { LoadingService } from 'src/app/services/loading.service';
import { FileViewerDragDropComponent } from '../file-viewer-drag-drop/file-viewer-drag-drop.component';

@Component({
	selector: 'app-upload-multiple-files',
	templateUrl: './upload-multiple-files.component.html',
	styleUrls: ['./upload-multiple-files.component.scss'],
	animations: [fadeAnimation,
		trigger('flyInOut', [
			transition(":enter", [
				style({ transform: 'translateY(-20px)', opacity: 0 }),
				animate("250ms ease-in", style({ transform: 'translateX(0px)', opacity: 1 }))
			]),
			transition(":leave", [
				animate("150ms ease-out", style({ transform: 'translateY(-20px)', opacity: 0 }))
			])
		])
	]
})
export class UploadMultipleFilesComponent implements OnInit {
	validImageTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/svg'];
	validPdfTypes = ['application/pdf'];
	allowedFileTypes = 'image/*, application/pdf';
	images_for_preview: string[] = [];
	files_for_submit: any[] = [];

	@Input() isChildComp: boolean = false;
	@Input() canViewFile: boolean = true;
	@Input() allowTypes: string[];
	@Input() multiple: boolean = true;
	@Input() validTypes: string;
	@Input() files: any[];
	@Output() onUpload = new EventEmitter();
	constructor(
		private dialog: MatDialog,
		private docService: DocumentService,
		private loadingService: LoadingService,
		private translate: TranslateService,
		@Inject(MAT_DIALOG_DATA) public data: {
			title: string,
			isMultiple: boolean,
			allowTypes: string
		}
	) {
		if (data) {
			this.multiple = !!data.isMultiple;
			this.allowedFileTypes = data.allowTypes;
		}

	}
	
	ngOnInit(): void {
		if (this.files) this.files_for_submit = this.files;
	}

	onOpenFileWindow() {
		if (this.isChildComp) this.allowedFileTypes = this.validTypes;
	}

	onChange(event) {
		if ((event.target.files && event.target.files[0])) {
			let filesAmount = event.target.files.length;

			for (let i = 0; i < filesAmount; i++) {
				if (!this.files_for_submit.map(item => item.name ? item.name.toLowerCase() : item.filename.toLowerCase()).includes(event.target.files[i].name.toLowerCase())) {
					if (this.isChildComp) {
						if (this.allowTypes.includes(event.target.files[i].type)) {
							if (this.multiple) this.files_for_submit.push(event.target.files[i]);
							else if (!this.multiple) this.files_for_submit = [event.target.files[i]];

						} else this.onAlertWrongFileType();

						if (this.files_for_submit.length > 0) this.onUpload.emit(this.files_for_submit);

					} else {
						if (this.multiple) this.files_for_submit.push(event.target.files[i]);
						else if (!this.multiple) this.files_for_submit = [event.target.files[i]];
					}

					if (this.validImageTypes.includes(event.target.files[i].type)) {
						const reader = new FileReader();
						reader.onload = (event: any) => { this.images_for_preview.push(event.target.result) }
						reader.readAsDataURL(event.target.files[i]);
					}
				}
			}
		}
	}

	onAlertWrongFileType() {
		this.dialog.open(GlobalConfirmDialogComponent, {
			width: '500px',
			disableClose: true,
			autoFocus: false,
			data: {
				title: 'dialog.confirm.alert',
				confirm_desc: 'message.wrong_file_type'
			}
		})
	}

	onRemoveFile(file: any) {
		this.dialog.open(GlobalConfirmDialogComponent, {
			disableClose: true,
			autoFocus: false,
			width: '500px',
			data: {
				is_reject: true,
				confirm_type: CONFIRM_TYPE.NORMAL,
				title: this.translate.instant('dialog.confirm.alert'),
				confirm_desc: this.translate.instant('message.remove_data')
			},

		}).afterClosed().subscribe(d_res => {
			if (d_res) {
				const removed_file = this.files_for_submit.map(item => item.name).indexOf(file.name);
				this.files_for_submit.splice(removed_file, 1);
				if (this.isChildComp) this.onUpload.emit(this.files_for_submit);
			}
		})
	}

	onViewFiles(file: any) {		
		if (file?.metadata) this.onViewInjectedFile(this.files);
		else if (!file?.metadata) this.onviewNonInjectedFile(file);
	}

	onviewNonInjectedFile(file: any) {
		if (this.validImageTypes.includes(file.type)) {
			let url = '';
			const reader = new FileReader();
			reader.onload = (event: any) => {
				url = event.target.result;
				this.dialog.open(ImageViewerComponent, {
					role: 'dialog',
					width: '750px',
					data: {
						isUpload: true,
						image: url
					}
				});
			}
			reader.readAsDataURL(file);


		} else if (this.validPdfTypes.includes(file.type)) {
			let blob = new Blob([file], { type: 'application/pdf' });
			let url = window.URL.createObjectURL(blob);

			this.dialog.open(PdfViewerComponent, {
				width: '800px',
				data: {
					isDataUpload: true,
					url: url
				}
			})


		} else {
			this.dialog.open(GlobalConfirmDialogComponent, {
				width: '500px',
				disableClose: true,
				autoFocus: false,
				data: {
					title: 'dialog.confirm.alert',
					confirm_desc: 'message.wrong_file_type'
				}
			})

		}
	}

	onViewInjectedFile(files: any) {
		let file = files[0];
		this.loadingService.setLoading(true);
		this.docService.getDocument(file.request).subscribe((res: any) => {
			this.loadingService.setLoading(false);

			this.onViewFile(res, this.getFileName(file), file.contentType);
		});
	}

	getFileName(file: any) {
		return file.filename;
	}

	onViewFile(data: any, name: string, type: string) {
		let blob = new Blob([data], { type: type });
		let url = window.URL.createObjectURL(blob);
		let isImage = !!this.validImageTypes.includes(type);

		this.dialog.open(FileViewerDragDropComponent, {
			height: "90vh",
			maxWidth: "90vw",
			backdropClass: "file-viewer",
			panelClass: "custom-file-container",
			data: {
				name: name,
				isImage: isImage,
				url: url
			}
		})
	}

	get isDisable() {
		return this.files_for_submit.length == 0;
	}

}

<h2 mat-dialog-title class="dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
	{{data.name}}
</h2>
<mat-dialog-content class="file-content">
	<img *ngIf="isImage" [src]="url">
	<iframe *ngIf="!isImage" id="viewer" class="main" width="800" height="400" [src]="url" frameborder="0"></iframe>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-raised-button class="m-button-normal" tabindex="-1" matDialogClose>
		<mat-icon class="svg-icon" svgIcon="close_icon"></mat-icon> {{'button.cancel' | translate}}
	</button>
</mat-dialog-actions>